import { Entrega } from './api/entrega';

export class CoverData {
  id: number;
  authorName: string;
  authorImage: string;
  coverImage: string;
  title: string;

  constructor(entrega?: Entrega) {
    this.id = entrega?.id ?? 0;
    this.title = entrega?.titulo ?? '';
    this.coverImage = entrega?.capa ?? '';
    this.authorName = (entrega?.perfil?.apelido || entrega?.usuario?.nome) ?? '';
    this.authorImage = entrega?.perfil?.foto_perfil ?? '';
  }
}
