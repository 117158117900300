
import { mapGetters, mapActions } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Course, RouteName } from '@/models';

@Component({
  name: 'CourseCard',
  computed: {
    ...mapGetters('user', {
      hasPublishedAssignments: 'hasPublishedAssignments',
    }),
  },
  methods: {
    ...mapActions('user', {
      setCourseId: 'setCourseId',
    }),
  },
})
export default class CourseCard extends Vue {
  @Prop() curso!: Course;
  private hasPublishedAssignments: boolean;
  private setCourseId: (id: number) => void;

  switchCourse(id: number) {
    this.setCourseId(id);
    const cursoId = id.toString();

    if (this.hasPublishedAssignments) {
      this.$router.push({ name: RouteName.INICIO, params: { cursoId } });
    } else {
      this.$router.push({ name: RouteName.RESCTRICTED_ACCESS });
    }
  }
}
