import { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { Curso } from '@/models/api';
import { Course } from '@/models';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const CourseService = {
  searchCourse(search: string): Promise<Course[]> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.getCourses(search));

      httpClient
        .get(endpoint)
        .then((response: AxiosResponse<Curso[]>) => {
          if (!response.data) reject(new Error('Ooops'));

          const courses: Course[] = response.data
            .filter((curso: Curso) => !curso.data_remocao)
            .map((curso: Curso) => new Course(curso));
          resolve(courses);
        })
        .catch((err: AxiosError) => reject(err));
    });
  },
};
