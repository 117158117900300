export class Questao {
  id: number;
  trabalho_id?: number;
  questao: string;
  obrigatorio?: boolean;
  resposta?: string;

  constructor(
    id?: number,
    questao?: string,
    obrigatoriedade?: boolean,
    resposta?: string,
    trabalho_id?: number,
  ) {
    this.id = id ?? 0;
    this.questao = questao ?? '';
    this.obrigatorio = obrigatoriedade ?? false;
    this.resposta = resposta ?? '';
    if (trabalho_id) this.trabalho_id = trabalho_id;
  }
}
