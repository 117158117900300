import { FloatingMenuItem } from '@/models/floating-menu-item';
import { FilterToField } from '@/models/filter-to-field';
import { DeliveryStatusText } from '@/models/delivery-status-text';
import { FilterData } from '@/models/filter-data';
import { FilterEnum } from '@/models/filter-enum';
import { ReportFilterData } from '@/models/report-filter-data';

import {
  FiltroParams,
  Filtro,
  EntregaStatus,
  QueryParamsModel,
} from '@/models/api';

import { DateUtils } from './DateUtils';
import { GradeUtils } from './GradeUtils';
import { EnumUtils } from './EnumUtils';

const {
  DELIVERY_DATE,
  PUBLICATION_DATE,
  GRADE,
  STATUS,
} = FilterEnum;

export const FilterUtils = {
  toBase64: (params: FiltroParams | FilterData) => btoa(JSON.stringify(params)),
  toObject: (data: string): FilterData => JSON.parse(atob(data)),
  floatingMenuToFilterParams: (
    keyString: string,
    items: FloatingMenuItem[],
    previousFilter?: string,
  ) => {
    const params: FiltroParams = {
      filtro: FilterToField[keyString as keyof typeof FilterToField],
    };

    const filtros = items?.reduce((acc: Filtro, item: FloatingMenuItem) => {
      const accumulator = acc;
      if (item?.checked) {
        accumulator[item.value as string] = [
          ...accumulator[item.value as string] ?? [],
          item.value as string,
        ];
      }
      return accumulator;
    }, {});

    if (Object.keys(filtros).length) params.filtros = filtros;

    if (previousFilter) {
      params.filtros = {
        ...params.filtros as Filtro,
        ...FilterUtils.toObject(
          FilterUtils.networkingToRequest(previousFilter),
        ).filtros as Filtro,
      };
    }

    return params;
  },
  formatFilters: (keyString: string, data: string | number | null) => {
    let formatted: string | number | null = null;

    switch (keyString) {
      case PUBLICATION_DATE:
      case DELIVERY_DATE:
        formatted = DateUtils.toString(data as string);
        break;
      case GRADE:
        formatted = GradeUtils.toGradeString(data as number);
        break;
      case STATUS:
        formatted = EnumUtils
          .fromFirstEnumToSecondEnum(data as string, EntregaStatus, DeliveryStatusText) as string;
        break;
      default:
        formatted = String(data);
        break;
    }
    return formatted;
  },
  networkingToRequest: (filter: string): string => {
    const requestFilter: FiltroParams = {};
    const networkingFilter: FilterData = FilterUtils.toObject(filter);

    Object.keys(networkingFilter).forEach((key) => {
      if (networkingFilter[key].filters) {
        requestFilter.filtros = ({
          ...requestFilter.filtros as Filtro,
          [key]: key === STATUS
            ? networkingFilter[key].filters?.map((frontFilter) => EnumUtils
              .fromFirstEnumToSecondEnum<typeof DeliveryStatusText, typeof EntregaStatus>(
                Object.keys(
                  DeliveryStatusText,
                )[Object.values(DeliveryStatusText).indexOf(frontFilter as DeliveryStatusText)],
                DeliveryStatusText,
                EntregaStatus,
              ))
            : networkingFilter[key].filters,
        }) as Filtro;
      }
      if (typeof networkingFilter[key].desc === 'boolean') {
        (requestFilter.ordenacao as {
          [key: string]: boolean;
        }[]
        ) = [
            ...requestFilter.ordenacao ?? [],
            { [key]: !(networkingFilter[key].desc as boolean) },
          ];
      }
    });

    return FilterUtils.toBase64(requestFilter);
  },
  requestToNetworking: (filter: string): string => {
    const networkFilter: FilterData = {};
    const requestFilter: FiltroParams = FilterUtils.toObject(filter);

    const { filtros, ordenacao } = requestFilter;

    if (filtros) {
      Object.keys(filtros as Filtro).forEach((key) => {
        networkFilter[key] = {
          ...networkFilter[key],
          filters: key === STATUS
            ? (filtros as Filtro)[key].map((backFilter) => EnumUtils
              .fromFirstEnumToSecondEnum<typeof EntregaStatus, typeof DeliveryStatusText>(
                Object.keys(
                  EntregaStatus,
                )[Object.values(EntregaStatus).indexOf(backFilter as EntregaStatus)],
                EntregaStatus,
                DeliveryStatusText,
              ) as string)
            : (filtros as Filtro)[key] as string[],
        };
      });
    }

    if (ordenacao) {
      (ordenacao as {
        [key: string]: boolean;
      }[]).forEach((ordem) => {
        Object.entries(ordem).forEach(([key, value]) => {
          networkFilter[key] = {
            ...networkFilter[key],
            desc: !value,
          };
        });
      });
    }

    return FilterUtils.toBase64(networkFilter);
  },
  reportFilterToFilterParams({ parameter, month, year }: ReportFilterData) {
    const params: FiltroParams | QueryParamsModel = {
      filtros: FilterUtils.toBase64({ [parameter as string]: { month, year } }),
    };

    return params;
  },
};
