import { Module } from 'vuex';
import { AxiosError } from 'axios';
import { RootState, PrivacyTermsState, PrivacyTermsCommits } from '@/models/store';
import { PrivacyTermsService } from '@/services';
import { BuscaTermosPrivacidade } from '@/models/api';

const { SET_TERMS } = PrivacyTermsCommits;

const privacyTerms: Module<PrivacyTermsState, RootState> = {
  namespaced: true,
  state: {
    terms: null,
  },
  getters: {
    terms: (state: PrivacyTermsState) => state.terms,
  },
  mutations: {
    SET_TERMS(state: PrivacyTermsState, { terms }: PrivacyTermsState) {
      state.terms = terms;
    },
  },
  actions: {
    getPrivacyTerms({ dispatch }): Promise<BuscaTermosPrivacidade> {
      return new Promise((resolve, reject) => {
        PrivacyTermsService.getPrivacyTerms()
          .then((termo: BuscaTermosPrivacidade) => {
            dispatch('setPrivacyTerms', termo);
            resolve(termo);
          })
          .catch((error: AxiosError) => reject(error));
      });
    },
    setPrivacyTerms({ commit }, terms: BuscaTermosPrivacidade): void {
      commit(SET_TERMS, { terms });
    },
  },
};

export default privacyTerms;
