import { RouteConfig } from 'vue-router';
import {
  AdminRoutes,
  StudentRoutes,
  GeneralRoutes,
  HiddenRoutes,
} from './NetworkingRoutes';

export const routes: Array<RouteConfig> = [
  ...AdminRoutes,
  ...StudentRoutes,
  ...GeneralRoutes,
  ...HiddenRoutes,
];
