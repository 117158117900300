
import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import Sidebar from '@/components/Sidebar/Sidebar.vue';

import { SidebarDataTransfer, SidebarEvents } from '@/models';

import { EventBus } from '@/utils/EventBus';

import SidebarFactory from './SidebarFactory.vue';

@Component({
  name: 'SidebarContainer',
  components: {
    Sidebar,
  },
})
export default class SidebarContainer extends Vue {
  public component: VueConstructor | null = null;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public componentProps: any = null;
  public fechavel = true;

  mounted() {
    this.subscribeToEvents();
  }

  subscribeToEvents() {
    EventBus.$on(SidebarEvents.OPEN, this.abrir);
    EventBus.$on(SidebarEvents.CLOSE, this.fechar);
  }

  public abrir({ component, fechavel = true, props }: SidebarDataTransfer) {
    this.fechavel = fechavel;
    this.component = SidebarFactory.construirSidebar(component);
    this.componentProps = props;
  }

  public fechar(): void {
    this.component = null;
    this.componentProps = null;
  }
}
