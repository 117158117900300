import { BreadcrumbMeta, RouteName } from '@/models';

const { INICIO, ASSIGNMENT_DISCIPLINE_LIST } = RouteName;

export const publishedAssignmentMeta = {
  breadcrumb: [
    new BreadcrumbMeta('course.name', INICIO, { cursoId: 'course.id' }),
    new BreadcrumbMeta(
      'discipline.name',
      ASSIGNMENT_DISCIPLINE_LIST,
      { disciplinaId: 'discipline.id' },
    ),
    new BreadcrumbMeta('class'),
    new BreadcrumbMeta('title'),
  ],
};
