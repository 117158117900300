import { Aluno } from './aluno';
import { EntregaPublicadaAnexo } from './entrega-publicada-anexo';
import { CaminhoEntrega } from './caminho-entrega';
import { QuestaoResposta } from './questao-resposta';
import { Reacao } from './reacao';

export class EntregaPublicada {
  id: number;
  usuario: Aluno;
  capa: string;
  titulo: string;
  questoes: QuestaoResposta[];
  video: string;
  uploads?: EntregaPublicadaAnexo[];
  dataPublicacao: string;
  nota: string;
  caminhoEntrega: CaminhoEntrega;
  comentario?: string;
  reacoes?: Reacao;

  constructor() {
    this.id = 0;
    this.usuario = new Aluno();
    this.capa = '';
    this.titulo = '';
    this.questoes = new Array(new QuestaoResposta());
    this.video = '';
    this.nota = '';
    this.dataPublicacao = '';
    this.caminhoEntrega = new CaminhoEntrega();
    this.uploads = [];
    this.comentario = '';
  }
}
