import { EventBus } from '@/utils/EventBus';
import { DTableEvents } from '@/models/dynamic-table-events';

const { REQUEST_STARTED, REQUEST_ENDED } = DTableEvents;

export const DynamicTableService = {
  requestStarted() {
    EventBus.$emit(REQUEST_STARTED);
  },

  requestEnded() {
    EventBus.$emit(REQUEST_ENDED);
  },
};
