import { Module } from 'vuex';
import { AxiosError } from 'axios';

import { AssignmentObjectListState, RootState, AssignmentObjectListCommits } from '@/models/store';
import { AssignmentObjectModel } from '@/models';
import { AssignmentObjectService } from '@/services';
import { Pagination, QueryParamsModel } from '@/models/api';

const {
  RESET_ASSIGNMENT_OBJECT_LIST,
  SET_PAGINATION,
  SET_ASSIGNMENT_OBJECT_LIST,
  DELETE_ASSIGNMENT_OBJECT_FROM_LIST,
} = AssignmentObjectListCommits;

const initial = {
  assignmentObjectList: [],
  pagination: {
    data: [],
  },
};

const assignmentObjectList: Module<AssignmentObjectListState, RootState> = {
  namespaced: true,
  state: initial,
  getters: {
    assignmentObjectList: (state: AssignmentObjectListState): AssignmentObjectModel[] => (
      state.assignmentObjectList
    ),
    pagination: (state: AssignmentObjectListState): Pagination<AssignmentObjectModel> => (
      state.pagination
    ),
  },
  mutations: {
    RESET_ASSIGNMENT_OBJECT_LIST(state: AssignmentObjectListState) {
      state.assignmentObjectList = [];
    },
    SET_ASSIGNMENT_OBJECT_LIST(
      state: AssignmentObjectListState,
      list: Pagination<AssignmentObjectModel>,
    ) {
      state.assignmentObjectList = list.data as AssignmentObjectModel[];
    },
    SET_PAGINATION(
      state: AssignmentObjectListState,
      pagination: Pagination<AssignmentObjectModel>,
    ) {
      state.pagination = pagination;
    },
    DELETE_ASSIGNMENT_OBJECT_FROM_LIST(state: AssignmentObjectListState, id: number) {
      state.assignmentObjectList = state.assignmentObjectList
        .filter((assignmentObject: AssignmentObjectModel) => assignmentObject.id !== id);
    },
  },
  actions: {
    getList({ commit }, params?: QueryParamsModel): Promise<void> {
      return new Promise((resolve, reject) => {
        commit(RESET_ASSIGNMENT_OBJECT_LIST);

        AssignmentObjectService.getObjectsList(params)
          .then((list: Pagination<AssignmentObjectModel>) => {
            commit(SET_ASSIGNMENT_OBJECT_LIST, list);
            commit(SET_PAGINATION, list);
            resolve();
          })
          .catch((err: AxiosError) => { reject(err); });
      });
    },
    deleteFromList(
      { commit, dispatch },
      id,
      searchKey = '',
      page = 0,
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        commit(DELETE_ASSIGNMENT_OBJECT_FROM_LIST, id);
        dispatch('getList', { searchKey, page })
          .catch((err: AxiosError) => { console.error(err); reject(err); })
          .finally(() => resolve());
      });
    },
  },
};

export default assignmentObjectList;
