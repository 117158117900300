import { Module } from 'vuex';
import { AxiosError } from 'axios';
import { RootState, UserCommits, UserState } from '@/models/store';
import { TipoDeAcesso, Permissoes } from '@/models/api';
import { UserService } from '@/services';
import env from '@/configs/env';
import {
  Course,
  ProfileFormModel,
  UserModel,
  UserPermissions,
} from '@/models';

const { STUDENT } = TipoDeAcesso;
const {
  SET_USER,
  SET_ACCESS_TYPE,
  SET_FIRST_ACCESS,
  SET_COURSE,
  SET_CLASS,
  SET_DISCIPLINE,
  SET_UPDATE,
  RESET_USER_DATA,
} = UserCommits;

const { VIEW_NETWORKING, EDIT_NETWORKING, EDIT_SCORE_NETWORKING } = Permissoes;
const {
  ASSIGNMENT_PUBLISHED,
  DEVELOPMENT,
  COURSE_SET,
  DISCIPLINE_SET,
  MULTIPLE_COURSES,
  ASSIGNMENT_AVAILABLE,
} = UserPermissions;

const initialState = {
  id: 0,
  name: '',
  firstName: '',
  nickname: '',
  company: '',
  linkedIn: '',
  biography: '',
  profilePhoto: '',
  firstAccess: true,
  accessType: STUDENT,
  permissions: [],
  courses: [],
  courseId: 0,
  classId: 0,
  disciplineId: 0,
  updating: false,
  termsAccepted: false,
};

const user: Module<UserState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    userInfos: (state: UserState) => state,
    updating: (state: UserState) => state.updating,
    getUserName: (state: UserState) => state.name,
    isFirstAccess: (state: UserState) => state.firstAccess,
    isStudent: (state: UserState) => state.accessType === STUDENT,
    hasCourseSet: (state: UserState): boolean => Boolean(state.courseId),
    hasPendingAssignments: (state: UserState) => Boolean(state.courses
      .find((course: Course) => course.assignments?.length)),
    hasPublishedAssignments: (state: UserState) => Boolean(state.courses
      .find((course: Course) => course.publishedAssignment)),
    userId: (state: UserState) => state.id,
    getUserNickname: (state: UserState) => state.nickname,
    getUserPhoto: (state: UserState) => state.profilePhoto,
    userPhoto: (state: UserState) => state.profilePhoto,
    getUserAccessType: (state: UserState) => state.accessType,
    getUserPermissions: (state: UserState) => state.permissions,
    getUserCourses: (state: UserState) => state.courses,
    getUserPublishedCourses: (state: UserState) => state.courses
      .filter((course: Course) => course.publishedAssignment),
    getUserCourseId: (state: UserState) => state.courseId,
    getUserClassId: (state: UserState) => state.classId,
    getUserDisciplineId: (state: UserState) => state.disciplineId,
    canViewNetwork: (state: UserState) => state.permissions.includes(VIEW_NETWORKING),
    canEditNetwork: (state: UserState) => state.permissions.includes(EDIT_NETWORKING),
    canSetScore: (state: UserState) => state.permissions.includes(EDIT_SCORE_NETWORKING),
  },
  mutations: {
    SET_USER(state: UserState, usr: UserModel) {
      state.id = usr.id;
      state.name = usr.name ?? '';
      state.firstName = usr.firstName ?? '';
      state.nickname = usr.nickname || usr.name || '';
      state.company = usr.company;
      state.linkedIn = usr.linkedIn;
      state.biography = usr.biography;
      state.profilePhoto = usr.profilePhoto;
      state.firstAccess = usr.firstAccess as boolean;
      state.courses = usr.courses ?? [];
      state.termsAccepted = usr.termsAccepted ?? false;

      const extraPermissions = [];
      const publishedCourses = Boolean(usr.courses
        ?.filter((course: Course) => course.publishedAssignment).length);
      const hasAssignmentAvailable = Boolean(usr.courses
        ?.find((course: Course) => (course.assignments as number[]).length > 0));

      if (publishedCourses) extraPermissions.push(ASSIGNMENT_PUBLISHED);
      if (hasAssignmentAvailable) extraPermissions.push(ASSIGNMENT_AVAILABLE);
      if (usr.courses && usr.courses.length > 1) extraPermissions.push(MULTIPLE_COURSES);

      if (usr.courses?.length === 1) {
        state.courseId = usr.courses[0].id;
        state.classId = usr.courses[0].classId;
        extraPermissions.push(COURSE_SET);
      }

      if (!env.production) extraPermissions.push(DEVELOPMENT);

      state.permissions = [
        ...usr.permissions as (UserPermissions | Permissoes)[],
        ...extraPermissions,
      ];
    },
    SET_ACCESS_TYPE(state: UserState, type: TipoDeAcesso) { state.accessType = type; },
    SET_FIRST_ACCESS(state: UserState, first: boolean) { state.firstAccess = first; },
    SET_COURSE(state: UserState, courseId: number) {
      state.courseId = courseId;
      state.permissions = [...state.permissions, COURSE_SET];
    },
    SET_CLASS(state: UserState, classId: number) {
      state.classId = classId;
    },
    SET_DISCIPLINE(state: UserState, disciplineId: number) {
      state.disciplineId = disciplineId;
      state.permissions = [...state.permissions, DISCIPLINE_SET];
    },
    SET_UPDATE(state: UserState, isLoading: boolean) {
      state.updating = isLoading;
    },
    RESET_USER_DATA(state: UserState) {
      state.id = initialState.id;
      state.name = initialState.name;
      state.firstName = initialState.firstName;
      state.nickname = initialState.nickname;
      state.company = initialState.company;
      state.linkedIn = initialState.linkedIn;
      state.biography = initialState.biography;
      state.profilePhoto = initialState.profilePhoto;
      state.firstAccess = initialState.firstAccess;
      state.accessType = initialState.accessType;
      state.permissions = initialState.permissions;
      state.courses = initialState.courses;
      state.courseId = initialState.courseId;
      state.classId = initialState.classId;
      state.disciplineId = initialState.disciplineId;
      state.updating = initialState.updating;
      state.termsAccepted = initialState.termsAccepted;
    },
  },
  actions: {
    getUserInfo({ commit, dispatch }): Promise<UserModel> {
      return new Promise((resolve, reject) => {
        dispatch('resetUserData');
        UserService.getUser()
          .then(async (profile: UserModel) => {
            await commit(SET_ACCESS_TYPE, profile.accessType);
            await commit(SET_USER, profile);
            setTimeout(() => resolve(profile));
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      });
    },
    updateUserInfo({ commit }): Promise<UserModel> {
      return new Promise((resolve, reject) => {
        UserService.getUser()
          .then(async (profile: UserModel) => {
            await commit(SET_ACCESS_TYPE, profile.accessType);
            await commit(SET_USER, profile);
            setTimeout(() => resolve(profile));
          })
          .catch((error: AxiosError) => reject(error));
      });
    },
    postUserInfo({ dispatch, commit }, [profile, photo]: [ProfileFormModel, File | null]) {
      commit(SET_UPDATE, true);

      return new Promise((resolve, reject) => {
        // TODO: atualizar o user e devolver usando o retorno do post quando a api for atualizado
        UserService.postUser(profile, photo).then(() => {
          dispatch('updateUserInfo')
            .then((userProfile) => resolve(userProfile))
            .catch((error: AxiosError) => reject(error))
            .finally(() => commit(SET_UPDATE, false));
        });
      });
    },
    setFirstAccess({ commit }, status: boolean) {
      commit(SET_FIRST_ACCESS, status);
    },
    setCourseId({ commit }, courseId: number) {
      commit(SET_COURSE, courseId);
    },
    setClassId({ commit }, classId: number) {
      commit(SET_CLASS, classId);
    },
    setDisciplineId({ commit }, disciplineId: number) {
      commit(SET_DISCIPLINE, disciplineId);
    },
    resetUserData({ commit }) {
      commit(RESET_USER_DATA);
    },
  },
};

export default user;
