import { Perfil } from './api/perfil';
import { Curso } from './api/curso';
import { Permissoes } from './api/permissoes';
import { Course } from './course';
import { UserPermissions } from './user-permissions';
import { Usuario, TipoDeAcesso } from './api';

export class UserModel {
  id: number;
  name?: string;
  firstName?: string;
  nickname: string;
  company: string;
  linkedIn: string;
  biography: string;
  profilePhoto: string;
  firstAccess?: boolean;
  accessType?: TipoDeAcesso;
  permissions?: (UserPermissions | Permissoes)[];
  courses?: Course[];
  termsAccepted?: boolean;
  profile_id: number;

  constructor(perfil?: Perfil, usuario?: Usuario) {
    this.id = perfil?.usuario_id || usuario?.id || 0;
    this.profile_id = perfil?.id ?? 0;
    this.name = perfil?.usuario?.nome || usuario?.nome || '';
    this.firstName = perfil?.usuario?.primeiro_nome || usuario?.primeiro_nome || '';
    this.nickname = perfil?.apelido || '';
    this.company = perfil?.empresa || '';
    this.linkedIn = perfil?.linkedin || '';
    this.biography = perfil?.biografia || '';
    this.profilePhoto = perfil?.foto_perfil || '';
    this.permissions = perfil?.permissoes || [];
    this.termsAccepted = perfil?.termo_aceito || false;
    this.courses = perfil?.produtos
      ?.map((produto: Curso) => new Course(produto)) || [];
    if (perfil?.tipoAcesso) this.accessType = perfil?.tipoAcesso || TipoDeAcesso.STUDENT;
    if (perfil) this.firstAccess = perfil?.primeiro_acesso;
  }
}
