import QuestionCircle from '@/assets/icons/QuestionCircle.svg';
import SortUp from '@/assets/icons/SortUp.svg';
import SortDown from '@/assets/icons/SortDown.svg';
import FilterIcon from '@/assets/icons/FilterIcon.svg';

export enum IconsList {
  QUESTION = QuestionCircle,
  SORT_UP = SortUp,
  SORT_DOWN = SortDown,
  FILTER = FilterIcon,
}
