import { Module } from 'vuex';
import {
  CourseCommits,
  CourseState,
  RootState,
} from '@/models/store';
import { Course, SuggestorType } from '@/models';
import { CourseService } from '@/services';

const {
  CLEAR_COURSES_LIST,
  SEARCHING_COURSES,
  SET_COURSES_LIST,
} = CourseCommits;

const initialState = {
  courseList: [],
  searchingCourses: false,
};

const course: Module<CourseState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    courseListAsSuggestorTypeList: ({ courseList }: CourseState): SuggestorType[] => courseList
      .map(({ name, id }: Course) => new SuggestorType(name, id)),
  },
  mutations: {
    CLEAR_COURSES_LIST(state: CourseState) {
      state.courseList = initialState.courseList;
      state.searchingCourses = initialState.searchingCourses;
    },
    SEARCHING_COURSES(state: CourseState, searching: boolean) {
      state.searchingCourses = searching;
    },
    SET_COURSES_LIST(state: CourseState, list: Course[]) {
      state.courseList = list;
    },
  },
  actions: {
    clearCourseList: ({ commit }) => commit(CLEAR_COURSES_LIST),
    searchCourseList: ({ commit }, searchKey: string): Promise<Course[]> => {
      commit(CLEAR_COURSES_LIST);
      return new Promise((resolve, reject) => {
        commit(SEARCHING_COURSES, true);
        CourseService.searchCourse(searchKey)
          .then(async (courseList: Course[]) => {
            await commit(SET_COURSES_LIST, courseList);
            setTimeout(() => resolve(courseList));
          })
          .catch(reject)
          .finally(() => commit(SEARCHING_COURSES, false));
      });
    },
  },
};

export default course;
