export * from './aluno';
export * from './anexo-tipo';
export * from './busca-termos-privacidade';
export * from './caminho-entrega';
export * from './comentario';
export * from './curso';
export * from './disciplina';
export * from './entrega-comentarios';
export * from './entrega-item';
export * from './entrega-por-disciplina-lista';
export * from './entrega-por-disciplina';
export * from './entrega-publicada-anexo';
export * from './trabalho-entrega-publicada';
export * from './entrega-publicada';
export * from './entrega-status';
export * from './entrega';
export * from './filtro-params';
export * from './filtro';
export * from './lista-de-capas';
export * from './login-response';
export * from './material';
export * from './matricula';
export * from './nota-response';
export * from './notificacao-visualizada';
export * from './notification-api';
export * from './objeto-de-assignment-post';
export * from './objeto-de-assignment-status';
export * from './objeto-de-assignment';
export * from './palavra-chave-paginada';
export * from './palavra-chave';
export * from './meus-assignments';
export * from './pagination';
export * from './perfil-request';
export * from './perfil';
export * from './permissoes';
export * from './query-params';
export * from './questao-resposta';
export * from './questao';
export * from './reacao-post';
export * from './reacao';
export * from './resposta-generica';
export * from './tipo-de-acesso';
export * from './turma';
export * from './upload-de-arquivo';
export * from './usuario';
export * from './vimeo-video-details';
export * from './youtube-video-details';
