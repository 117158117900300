import { NotificationApi } from '../api/notification-api';

export interface NotificationModeState {
  mode: NotificationMode;
  opened: boolean;
  notificationApi: NotificationApi;
}

export enum NotificationMode {
  PAGE = 'PAGE',
  BOX = 'BOX',
}

export enum NotificationCommits {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_NOTIFICATION_MODE = 'SET_NOTIFICATION_MODE',
  SET_NOTIFICATION_VISIBILITY = 'SET_NOTIFICATION_VISIBILITY',
}
