import { AxiosError, AxiosResponse } from 'axios';
import { buildApiUrl, ApiUrls } from '@/utils/endpoints';
import { ProfileFormModel, UserModel } from '@/models';
import { Perfil } from '@/models/api';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const UserService = {
  getUser(): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      const userEndpoint = buildApiUrl(ApiUrls.userProfile());

      httpClient
        .get(userEndpoint)
        .then((perfil: AxiosResponse<Perfil>) => resolve(new UserModel(perfil.data)))
        .catch((err: AxiosError) => reject(err));
    });
  },

  postUser(profile: ProfileFormModel, photo?: File | null) {
    const url = buildApiUrl(ApiUrls.userProfile());
    const payload = this.buildUserPayload(profile, photo);

    return httpClient.post(url, payload);
  },

  buildUserPayload(profile: ProfileFormModel, photo?: File | null) {
    const payload = new FormData();

    payload.append('apelido', profile.nick.value.toString());
    payload.append('empresa', profile.work.value.toString());
    payload.append('linkedin', profile.linkedin.value.toString());
    payload.append('biografia', profile.biography.value.toString());
    payload.append('aceiteTermo', profile.terms.value.toString());

    if (photo) payload.append('foto_perfil', photo);

    return payload;
  },
};
