import { Module } from 'vuex';
import { AxiosError } from 'axios';
import {
  DisciplineCommits,
  DisciplineState,
  RootState,
} from '@/models/store';
import { Discipline, SearchDisciplineParams, SuggestorType } from '@/models';
import { DisciplineService } from '@/services';

const {
  CLEAR_DISCIPLINES_LIST,
  SEARCHING_DISCIPLINES,
  SET_DISCIPLINES_LIST,
} = DisciplineCommits;

const initialState = {
  disciplineList: [],
  searchingDisciplines: false,
};

const disciplines: Module<DisciplineState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    disciplineListAsSuggestorTypeList: (
      { disciplineList }: DisciplineState,
    ): SuggestorType[] => disciplineList
      .map(({ name, id }: Discipline) => new SuggestorType(name, id)),
  },
  mutations: {
    CLEAR_DISCIPLINES_LIST(state: DisciplineState) {
      state.disciplineList = initialState.disciplineList;
      state.searchingDisciplines = initialState.searchingDisciplines;
    },
    SEARCHING_DISCIPLINES(state: DisciplineState, searching: boolean) {
      state.searchingDisciplines = searching;
    },
    SET_DISCIPLINES_LIST(state: DisciplineState, list: Discipline[]) {
      state.disciplineList = list.map((discipline) => {
        const d = discipline;
        d.name = `${discipline.name} — ${discipline.id}`;
        return d;
      });
    },
  },
  actions: {
    clearDisciplineList: ({ commit }) => commit(CLEAR_DISCIPLINES_LIST),
    searchDisciplineList: (
      { commit },
      params: SearchDisciplineParams,
    ): Promise<Discipline[]> => {
      commit(CLEAR_DISCIPLINES_LIST);
      return new Promise((resolve, reject) => {
        commit(SEARCHING_DISCIPLINES, true);
        DisciplineService.searchDiscipline(params)
          .then(async (disciplineList: Discipline[]) => {
            await commit(SET_DISCIPLINES_LIST, disciplineList);
            setTimeout(() => resolve(disciplineList));
          })
          .catch((err: AxiosError) => reject(err))
          .finally(() => commit(SEARCHING_DISCIPLINES, false));
      });
    },
  },
};

export default disciplines;
