import { Module } from 'vuex';
import { AxiosError } from 'axios';

import { AssignmentObjectCommits, AssignmentObjectState, RootState } from '@/models/store';
import {
  AssignmentObjectModel,
  AssignmentObjectStatus,
  ChipType,
  Class,
  Course,
  Discipline,
  FileUpload,
  KeywordModel,
  QuestionModel,
  SuggestorType,
} from '@/models';

import { AssignmentObjectService } from '@/services';

import { DateUtils } from '@/utils';

const {
  RESET_ASSIGNMENT_OBJECT,
  SET_ASSIGNMENT_OBJECT,
  SET_COURSE,
  SET_CLASSES,
  SET_DISCIPLINE,
  SET_FILES_REQUIRED,
  SET_FILES,
  SET_INITIAL_STATE,
  SET_KEYWORDS,
  SET_NEW_FILES,
  SET_PUBLISH,
  SET_QUESTIONS,
  SET_REMOVED_QUESTIONS,
  SET_STATEMENT,
  SET_VIDEO_REQUIRED,
  SET_VIDEO,
} = AssignmentObjectCommits;

const { DRAFT, PUBLISHED } = AssignmentObjectStatus;

const initial = {
  id: 0,
  attachment: false,
  classes: [],
  courseId: 0,
  course: new Course(),
  deletable: false,
  disciplineId: 0,
  discipline: new Discipline(),
  keywords: [],
  initialState: new AssignmentObjectModel(),
  publish: false,
  questions: [new QuestionModel()],
  statement: '',
  status: DRAFT,
  uploadVideo: false,
  video: '',
  files: [],
  newFiles: [],
  removedItems: [],
  createdAt: undefined,
};

let timeout: number;

const assignmentObject: Module<AssignmentObjectState, RootState> = {
  namespaced: true,
  state: initial,
  getters: {
    canBePublished: (state: AssignmentObjectState): boolean => Boolean(
      state.statement
      && state.questions?.length
      && state.questions
        .find((question: QuestionModel) => question.question !== '')
      && state.courseId
      && state.disciplineId
      && state.classes?.length
      && state.classes.find((c: Class) => Boolean(c?.deliveryDate))
      && state.keywords?.length,
    ),
    editMode: ({ status, id }: AssignmentObjectState): boolean => (
      (status === PUBLISHED || status === DRAFT) && Boolean(id)
    ),
    getAssignmentObjectId: ({ id }: AssignmentObjectState): number => id,
    getClasses: ({ classes }: AssignmentObjectState): Class[] => classes,
    getCourseAsSuggestorType: ({
      courseId,
      course,
    }: AssignmentObjectState): SuggestorType => new SuggestorType(course.name, courseId),
    getCourseId: ({ courseId }: AssignmentObjectState) => courseId,
    getDisciplineAsSuggestorType: ({
      disciplineId,
      discipline,
    }: AssignmentObjectState): SuggestorType => new SuggestorType(
      discipline.name,
      disciplineId,
    ),
    getDisciplineId: ({ disciplineId }: AssignmentObjectState) => disciplineId,
    getFilesRequired: ({ attachment }: AssignmentObjectState): boolean => attachment,
    getFiles: ({ files }: AssignmentObjectState): FileUpload[] | undefined => files,
    getKeywordsAsChipType: ({ keywords }: AssignmentObjectState): ChipType[] => keywords
      .map(({ keyword, id }: KeywordModel) => new ChipType(keyword, id)),
    getNewFiles: ({ newFiles }: AssignmentObjectState): File[] | undefined => newFiles,
    getObject: (state: AssignmentObjectState): AssignmentObjectState => state,
    getQuestions: ({ questions }: AssignmentObjectState): QuestionModel[] => questions,
    getStatement: ({ statement }: AssignmentObjectState): string => statement,
    getVideoRequired: ({ uploadVideo }: AssignmentObjectState): boolean => uploadVideo,
    getVideo: ({ video }: AssignmentObjectState): string => video,
    hasChange: ({ initialState, ...currentState }: AssignmentObjectState): boolean => {
      const classCheck = (classes: Class[]) => classes
        .map(({
          checked,
          composingGrade,
          deliveryDate,
          recoveryDeliveryDate,
        }: Class) => ({
          checked,
          composingGrade,
          deliveryDate: DateUtils.toString(deliveryDate as Date),
          recoveryDeliveryDate: DateUtils.toString(recoveryDeliveryDate as Date),
        }));

      const dirty = initialState?.id !== currentState.id
        || initialState?.attachment !== currentState.attachment
        || initialState?.courseId !== currentState.courseId
        || initialState?.disciplineId !== currentState.disciplineId
        || initialState?.statement !== currentState.statement
        || initialState?.uploadVideo !== currentState.uploadVideo
        || initialState?.video !== currentState.video
        || JSON.stringify(classCheck(initialState?.classes))
        !== JSON.stringify(classCheck(currentState.classes))
        || JSON.stringify(initialState?.keywords) !== JSON.stringify(currentState.keywords)
        || JSON.stringify(initialState?.questions) !== JSON.stringify(currentState.questions)
        || JSON.stringify(initialState?.files) !== JSON.stringify(currentState.files)
        || JSON.stringify(initialState?.newFiles) !== JSON.stringify(currentState.newFiles);

      return dirty;
    },
    isCourseSet: ({ courseId }: AssignmentObjectState): boolean => Boolean(courseId),
    isDisciplineSet: ({ disciplineId }: AssignmentObjectState): boolean => Boolean(disciplineId),
    isClassSet: ({ classes }: AssignmentObjectState): boolean => Boolean(classes
      .find((singleClass: Class) => singleClass.checked)),
    isDraft: ({ status }: AssignmentObjectState): boolean => status === DRAFT,
    isEditing: ({ status }: AssignmentObjectState): boolean => status === PUBLISHED,
  },
  mutations: {
    RESET_ASSIGNMENT_OBJECT(state: AssignmentObjectState) {
      state.id = initial.id;
      state.attachment = initial.attachment;
      state.classes = initial.classes;
      state.courseId = initial.courseId;
      state.course = initial.course;
      state.deletable = initial.deletable;
      state.disciplineId = initial.disciplineId;
      state.discipline = initial.discipline;
      state.keywords = initial.keywords;
      state.initialState = initial.initialState;
      state.publish = initial.publish;
      state.questions = initial.questions;
      state.statement = initial.statement;
      state.status = initial.status;
      state.uploadVideo = initial.uploadVideo;
      state.video = initial.video;
      state.files = initial.files;
      state.newFiles = initial.newFiles;
      state.createdAt = initial.createdAt;
    },
    SET_ASSIGNMENT_OBJECT(state: AssignmentObjectState, data: AssignmentObjectModel): void {
      const classes = data.classes.map((singleClass: Class) => {
        const sClass = singleClass;
        sClass.checked = true;
        return sClass;
      });

      state.id = data.id;
      state.attachment = data.attachment ?? false;
      state.classes = classes;
      state.courseId = data.courseId ?? 0;
      state.course = data.course;
      state.deletable = data.deletable;
      state.disciplineId = data.disciplineId ?? 0;
      state.discipline = data.discipline;
      state.keywords = data.keywords ?? [];
      state.publish = data.publish ?? false;
      state.questions = data.questions ?? [];
      state.statement = data.statement ?? '';
      state.status = data.status;
      state.uploadVideo = data.uploadVideo ?? false;
      state.video = data.video ?? '';
      state.files = data.files;
      state.newFiles = data.newFiles;
      state.createdAt = data.createdAt;
    },
    SET_CLASSES(state: AssignmentObjectState, classes: Class[]) {
      state.classes = classes;
    },
    SET_COURSE(state: AssignmentObjectState, course: Course) {
      state.courseId = course.id;
      state.course = course;
    },
    SET_DISCIPLINE(state: AssignmentObjectState, discipline: Discipline) {
      state.disciplineId = discipline.id;
      state.discipline = discipline;
    },
    SET_FILES_REQUIRED(state: AssignmentObjectState, required: boolean) {
      state.attachment = required;
    },
    SET_FILES(state: AssignmentObjectState, files: FileUpload[]) {
      state.files = files;
    },
    SET_INITIAL_STATE(state: AssignmentObjectState, data: AssignmentObjectModel): void {
      const classes = data.classes.map((singleClass: Class) => {
        const sClass = singleClass;
        sClass.checked = true;
        return sClass;
      });
      state.initialState = {
        ...data,
        classes,
      };
    },
    SET_KEYWORDS(state: AssignmentObjectState, chips): void {
      state.keywords = chips;
    },
    SET_NEW_FILES(state: AssignmentObjectState, newFiles: File[]) {
      state.newFiles = newFiles;
    },
    SET_PUBLISH(state: AssignmentObjectState, publish: boolean): void {
      state.publish = publish;
    },
    SET_QUESTIONS(state: AssignmentObjectState, questions: QuestionModel[]): void {
      state.questions = questions;
    },
    SET_REMOVED_QUESTIONS(state: AssignmentObjectState, removedQuestions: number[]): void {
      state.removedQuestions = removedQuestions;
    },
    SET_STATEMENT(state: AssignmentObjectState, statement: string) {
      state.statement = statement;
    },
    SET_VIDEO_REQUIRED(state: AssignmentObjectState, required: boolean) {
      state.uploadVideo = required;
    },
    SET_VIDEO(state: AssignmentObjectState, video: string) {
      state.video = video;
    },
  },
  actions: {
    getAssignmentObject({ commit }, id: number): Promise<AssignmentObjectModel> {
      return new Promise((resolve, reject) => {
        clearTimeout(timeout);
        commit(RESET_ASSIGNMENT_OBJECT);
        AssignmentObjectService.getObject(id)
          .then(async (object: AssignmentObjectModel) => {
            await commit(SET_ASSIGNMENT_OBJECT, object);
            await commit(SET_INITIAL_STATE, object);
            timeout = setTimeout(() => resolve(object));
          })
          .catch((error: AxiosError) => reject(error.response));
      });
    },
    resetAssignmentObject({ commit }) {
      commit(RESET_ASSIGNMENT_OBJECT);
    },
    saveAssignmentObject(
      { commit },
      payload: AssignmentObjectState,
    ): Promise<AssignmentObjectModel> {
      return new Promise((resolve, reject) => {
        clearTimeout(timeout);
        commit(RESET_ASSIGNMENT_OBJECT);
        AssignmentObjectService.saveObject(payload)
          .then((assignObject: AssignmentObjectModel) => {
            commit(SET_ASSIGNMENT_OBJECT, assignObject);
            commit(SET_INITIAL_STATE, assignObject);
            resolve(assignObject);
          })
          .catch((error: AxiosError) => reject(error));
      });
    },
    setAssignmentObjectData(
      { commit },
      payload: AssignmentObjectModel,
    ): Promise<AssignmentObjectModel> {
      return new Promise((resolve, reject) => {
        clearTimeout(timeout);
        AssignmentObjectService.saveObject(payload)
          .then(async (savedData: AssignmentObjectModel) => {
            await commit(SET_ASSIGNMENT_OBJECT, savedData);
            await commit(SET_INITIAL_STATE, savedData);
            timeout = setTimeout(() => resolve(savedData));
          })
          .catch((error: AxiosError) => reject(error));
      });
    },
    setCourseFromSuggestorType({ commit, dispatch }, { id = 0, name = '' }: SuggestorType) {
      const course = { ...new Course(), id, name };
      commit(SET_COURSE, course);
      if (!id) dispatch('setDisciplineFromSuggestorType', { id, name });
    },
    setClasses({ commit }, classes: Class[]) {
      const checkedClasses = classes.filter((c: Class) => c.checked);
      commit(SET_CLASSES, checkedClasses);
    },
    setDisciplineFromSuggestorType({ commit, dispatch }, { id = 0, name = '' }: SuggestorType) {
      const discipline = { id, name };
      commit(SET_DISCIPLINE, discipline);

      if (!id) dispatch('setClasses', []);
    },
    setFilesRequired({ commit }, required: boolean) {
      commit(SET_FILES_REQUIRED, required);
    },
    setFiles({ commit }, files: FileUpload[]) {
      commit(SET_FILES, files);
    },
    setKeywordsFromChipType({ commit }, chips: ChipType[]) {
      const keywords = chips.map((chip: ChipType) => new KeywordModel(chip));
      commit(SET_KEYWORDS, keywords);
    },
    setNewFiles({ commit }, newFiles: File[]) {
      commit(SET_NEW_FILES, newFiles);
    },
    setPublish({ commit }, publish: boolean) {
      commit(SET_PUBLISH, publish);
    },
    setQuestions({ commit }, questions: QuestionModel[]) {
      commit(SET_QUESTIONS, questions);
    },
    setRemovedQuestions({ commit }, removedQuestions: number[]) {
      commit(SET_REMOVED_QUESTIONS, removedQuestions);
    },
    setStatement({ commit }, statement: string | InputEvent) {
      const value = typeof statement === 'string'
        ? statement
        : (statement.target as HTMLInputElement)?.value;

      commit(SET_STATEMENT, value);
    },
    setVideoRequired({ commit }, required: boolean) {
      commit(SET_VIDEO_REQUIRED, required);
    },
    setVideo({ commit }, video: string) {
      commit(SET_VIDEO, video);
    },
  },
};

export default assignmentObject;
