
import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import Modal from '@/components/Modal/Modal.vue';

import { ModalDataTransfer, ModalEventos } from '@/models';

import { EventBus } from '@/utils/EventBus';

import ModalFactory from './ModalFactory.vue';

@Component({
  name: 'ModalContainer',
  components: {
    Modal,
  },
})
export default class ModalContainer extends Vue {
  public component: VueConstructor | null = null;
  public componentProps: object | string | null = null;
  public fechavel = true;
  public dark = false;
  public overlayDark = false;

  mounted() {
    this.subscribeToEvents();
  }

  subscribeToEvents() {
    EventBus.$on(ModalEventos.ABRIR, this.abrir);
    EventBus.$on(ModalEventos.FECHAR, this.fechar);
  }

  public abrir({
    component,
    fechavel = true,
    props,
    dark = false,
    overlayDark = false,
  }: ModalDataTransfer) {
    this.fechavel = fechavel;
    this.component = ModalFactory.construirModal(component);
    this.componentProps = props;
    this.dark = dark;
    this.overlayDark = overlayDark;

    if (props?.fechavel) this.fechavel = props.fechavel;
  }

  public fechar(): void {
    this.component = null;
    this.componentProps = null;
  }
}
