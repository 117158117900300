export class Curso {
  id: number;
  produto?: string;
  nome?: string;
  logo?: string;
  termo?: string;
  slug?: string;
  title?: string;
  titulo?: string;
  description?: string;
  data_remocao?: number;
  assignmentPublicado?: boolean;
  assignments?: number[];
  tipo?: string;
  cursoId?: number;

  constructor() {
    this.id = 0;
    this.nome = '';
  }
}
