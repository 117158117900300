
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import Unsplash from '@/components/Sidebar/SidebarTypes/Unsplash.vue';
import CourseSidebar from '@/components/Sidebar/SidebarTypes/CourseSidebar.vue';

import { SidebarTypes } from '@/models';

const sidebars: {
  [index: string]: VueConstructor;
} = {
  [SidebarTypes.UNSPLASH]: Unsplash,
  [SidebarTypes.COURSESIDEBAR]: CourseSidebar,
};

@Component({
  name: 'SidebarFactory',
})
export default class SidebarFactory extends Vue {
  public static construirSidebar(tipo: SidebarTypes): VueConstructor {
    return sidebars[tipo];
  }
}
