export const EnumUtils = {
  fromFirstEnumToSecondEnum: <EnumFrom, EnumTo>(
    position: string,
    from: EnumFrom,
    to: EnumTo,
  ): unknown => {
    let index = Object.keys(from).indexOf(position);
    if (index < 0) index = Object.values(from).indexOf(position);
    return Object.values(to)[index];
  },
};
