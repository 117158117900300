import { EntregaPorDisciplina } from './api';
import { Entrega } from './api/entrega';
import { EntregaPorDisciplinaLista } from './api/entrega-por-disciplina-lista';
import { CoverData } from './cover-data';

export class CoverDataList {
  id: number;
  name: string;
  curso?: string;
  deliveries: CoverData[];

  constructor(data?: EntregaPorDisciplinaLista | EntregaPorDisciplina) {
    const deliveryList = (data as EntregaPorDisciplinaLista);
    const delivery = (data as EntregaPorDisciplina);

    this.id = deliveryList?.id ?? 0;
    this.name = delivery?.disciplina.disciplina || deliveryList?.disciplina || '';
    this.curso = deliveryList?.curso ?? '';

    const deliveries = deliveryList?.entregas.entregas || delivery?.entregas || [];
    this.deliveries = deliveries
      .map((entrega: Entrega) => new CoverData(entrega));
  }
}
