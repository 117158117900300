import { DateUtils, GradeUtils } from '@/utils';

import { Delivery } from './delivery';
import { DeliveryGradesFormHeader } from './delivery-grades-form-headers';
import { DeliveryStatus } from './delivery-status';

const { ADITIONAL, RECOVERY, REGULAR } = DeliveryGradesFormHeader;
const {
  DRAFT,
  PENDING_STUDENT_CORRECTION,
  EXPIRED,
  IN_RECOVERY,
  NOT_STARTED,
  AWAITING_EVALUATION,
} = DeliveryStatus;

export class GradeFormModel {
  header: DeliveryGradesFormHeader;
  dueDate: Date;
  disableDueDate?: boolean;
  grade: string;
  disableGrade?: boolean;
  requiredError: boolean;
  conceptGrade: string;
  disableConceptGrade?: boolean;
  minGrade?: number;
  maxGrade?: number;

  constructor(
    header: DeliveryGradesFormHeader,
    delivery: Delivery,
    grade?: number,
    aditional?: Date,
  ) {
    this.header = header;
    this.dueDate = this.setDueDate(header, delivery);
    this.disableDueDate = header !== ADITIONAL;
    this.grade = this.setGrade(header, delivery);
    this.disableGrade = this.setDisableGrade(header, delivery, grade, aditional);
    this.requiredError = false;
    this.conceptGrade = this.setConceptGrade(header, delivery);
    this.disableConceptGrade = true;
    this.minGrade = delivery.minimumGrade;
    if (header !== REGULAR) this.maxGrade = 8;
  }

  private setDueDate(header: DeliveryGradesFormHeader, delivery: Delivery): Date {
    switch (header) {
      case REGULAR:
        return delivery.dueDate as Date;
      case RECOVERY:
        return delivery.recoveryDueDate as Date;
      case ADITIONAL:
        return delivery.aditionalDueDate as Date;
      default:
        return new Date();
    }
  }

  private setGrade(header: DeliveryGradesFormHeader, delivery: Delivery): string {
    switch (header) {
      case REGULAR:
        return GradeUtils.toGradeString(delivery.grade as number);
      case RECOVERY:
        return GradeUtils.toGradeString(delivery.recoveryGrade as number);
      case ADITIONAL:
        return GradeUtils.toGradeString(delivery.aditionalGrade as number);
      default:
        return '';
    }
  }

  private setDisableGrade(
    header: DeliveryGradesFormHeader,
    delivery: Delivery,
    grade?: number,
    aditionalDueDate?: Date,
  ): boolean {
    const enableAdicional = DateUtils
      .checkExpiredDate(delivery.recoveryDueDate);
    const draft = delivery.status === DRAFT;
    const notStarted = delivery.status === undefined || delivery.status === NOT_STARTED;
    const pending = delivery.status === PENDING_STUDENT_CORRECTION;
    const recovery = delivery.status === IN_RECOVERY
      || (delivery.status !== AWAITING_EVALUATION
        && DateUtils.checkExpiredDate(delivery.dueDate)
        && !DateUtils.checkExpiredDate(delivery.recoveryDueDate));
    const expired = delivery.status === EXPIRED || (enableAdicional && !delivery.status);
    const aditional = Boolean(delivery.aditionalDueDate
      && (!DateUtils.checkExpiredDate(delivery.aditionalDueDate)
        || (delivery.aditionalDeliveryDate
          && (delivery.status === AWAITING_EVALUATION
            || delivery.status === PENDING_STUDENT_CORRECTION))));

    switch (header) {
      case REGULAR:
        return draft || notStarted || pending || expired || recovery || aditional
          || (delivery.deliveryDate === undefined
            || (delivery.deliveryDate !== undefined && grade === null))
          || (delivery.recoveryDeliveryDate !== undefined);
      case RECOVERY:
        return draft || notStarted || pending || expired || aditional;
      case ADITIONAL:
        return draft || notStarted || pending || expired
          || !(delivery.aditionalDueDate && aditionalDueDate);
      default:
        return true;
    }
  }

  private setConceptGrade(header: DeliveryGradesFormHeader, delivery: Delivery): string {
    switch (header) {
      case REGULAR:
        return delivery.conceptGrade as string;
      case RECOVERY:
        return delivery.conceptRecoveryGrade as string;
      case ADITIONAL:
        return delivery.conceptAdictionalGrade as string;
      default:
        return '';
    }
  }
}
