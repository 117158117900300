export class MaterialData {
  id: number;
  name: string;
  icon: SVGElement;
  scale: number;

  constructor(id: number, name: string, icon: SVGElement, scale: number) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.scale = scale;
  }
}
