import { ListaDeCapas } from './api/lista-de-capas';
import { Author } from './author';

export class CoverList {
  author: Author;
  blur: string;
  description: string;
  id: string;
  urls: Urls;
  download: string;

  constructor(lista: ListaDeCapas) {
    this.author = new Author(lista.autor);
    this.blur = lista.blur;
    this.description = lista.descricao;
    this.id = lista.id;
    this.urls = lista.urls;
    this.download = lista.download;
  }
}

interface Urls {
  full: string;
  raw: string;
  regular: string;
  small: string;
  thumb: string;
}
