import { RouteConfig } from 'vue-router';
import { AssignmentObjectRouterAction, DeliveriesRouterAction } from '@/router/RouterActions';
import { RouteName } from '../../models/route-name';
import { AccessPermissions } from '../Metas/AccessPermissions';

const {
  ASSIGNMENT_OBJECT_MANAGE,
  ASSIGNMENT_OBJECT_ASSIGNMENT,
  ASSIGNMENT_OBJECT_CREATE,
  ASSIGNMENT_OBJECT_EDIT,
} = RouteName;

export const AdminRoutes: RouteConfig[] = [
  {
    path: '/assignment-object/list',
    name: ASSIGNMENT_OBJECT_MANAGE,
    component: () => import('@/views/AssignmentObjectManager.vue'),
    meta: AccessPermissions.Admin,
  },
  {
    path: '/assignment-object/:trabalhoId/turma/:turmaId/assignments',
    name: ASSIGNMENT_OBJECT_ASSIGNMENT,
    component: () => import('@/views/DeliveriesOfAssignmentsObjects.vue'),
    meta: AccessPermissions.Admin,
    beforeEnter: DeliveriesRouterAction,
  },

  {
    path: '/assignment-object/create',
    name: ASSIGNMENT_OBJECT_CREATE,
    component: () => import('@/views/AssignmentObject.vue'),
    meta: AccessPermissions.AdminViewAndEdit,
    beforeEnter: AssignmentObjectRouterAction,
  },

  {
    path: '/assignment-object/:trabalhoId/edit',
    name: ASSIGNMENT_OBJECT_EDIT,
    component: () => import('@/views/AssignmentObject.vue'),
    meta: AccessPermissions.AdminViewAndEdit,
    beforeEnter: AssignmentObjectRouterAction,
  },
];
