export class Aluno {
  id: number;
  nome: string;
  apelido?: string;
  empresa: string;
  linkedin: string;
  biografia: string;
  fotoPerfil: string;

  constructor(
    id?: number,
    nome?: string,
    apelido?: string,
    empresa?: string,
    linkedin?: string,
    biografia?: string,
    fotoPerfil?: string,
  ) {
    this.id = id || 0;
    this.nome = nome || '';
    this.empresa = empresa || '';
    this.linkedin = linkedin || '';
    this.biografia = biografia || '';
    this.fotoPerfil = fotoPerfil || '';
    this.apelido = apelido || '';
  }
}
