import { Area } from '../../models/area';

const {
  ADMIN_OBJECT_AREA,
  AUTHENTICATION,
  STUDENT_DELIVERY_AREA,
  STUDENT_NOTIFICATION_AREA,
  STUDENT_OBJECT_AREA,
} = Area;

export const context = {
  [ADMIN_OBJECT_AREA]: 'administrador/trabalhos',
  [STUDENT_DELIVERY_AREA]: 'aluno/entregas',
  [STUDENT_NOTIFICATION_AREA]: 'aluno/notificacoes',
  [STUDENT_OBJECT_AREA]: 'aluno/trabalho',
  [AUTHENTICATION]: 'autenticacao',
};
