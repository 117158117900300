import { AxiosError } from 'axios';
import { Module } from 'vuex';
import {
  AssignmentMaterial,
  AssignmentObjectModel,
  Delivery,
  DraftDelivery,
  FileUpload,
  KeywordModel,
  QuestionModel,
  RenderOptionalsEvents,
  StatementData,
  CommentModel,
} from '@/models';
import { Answer } from '@/models/answer';
import { EntregaPublicadaAnexo, QuestaoResposta } from '@/models/api';
import {
  AssignmentCommits,
  AssignmentState,
  RootState,
} from '@/models/store';
import { AssignmentService, ModalService } from '@/services';
import { EventBus, ArrayUtils } from '@/utils';

interface GenericComparison {
  id: number;
  answer?: string;
}

const {
  SET_DELIVERY_ID,
  SET_AGREE_TERMS,
  SET_ANSWERS,
  SET_ASSIGNMENT_MATERIALS,
  SET_COVER,
  SET_DOWNLOAD_COVER,
  SET_DRAFT,
  SET_KEYWORDS,
  SET_SAVING,
  SET_TITLE,
  SET_WORK_ID,
  SET_WORK_TO_FILL,
  SET_STATEMENT,
  SET_ADD_MATERIALS_OPEN,
  SET_ATTACHMENT_REQUIREMENT,
  SET_VIDEO_REQUIREMENT,
  SET_PROFESSOR_COMMENTS,
  SET_COMPOSING_GRADE,
  RESET_DELIVERY_ID,
  RESET_AGREED_TERMS,
  RESET_ATTACHMENT_REQUIREMENT,
  RESET_VIDEO_REQUIREMENT,
  RESET_WORK_TO_FILL,
  RESET_COVER,
  RESET_TITLE,
  RESET_DOWNLOAD_COVER,
  RESET_DRAFT,
  RESET_ANSWERS,
  RESET_ASSIGNMENT_MATERIALS,
  RESET_KEYWORDS,
  RESET_STATEMENT,
  RESET_PROFESSOR_COMMENTS,
  RESET_COMPOSING_GRADE,
} = AssignmentCommits;

const assignment: Module<AssignmentState, RootState> = {
  namespaced: true,
  state: {
    coverDownload: '',
    workId: 0,
    deliveryId: 0,
    statement: new StatementData(),
    workToFill: new AssignmentObjectModel(),
    cover: '',
    title: '',
    answers: [],
    assignmentMaterials: new AssignmentMaterial([]),
    agreeTerms: false,
    keywords: [],
    draft: new DraftDelivery(),
    addMaterialsOpen: false,
    saving: false,
    videoIsRequired: false,
    attachmentIsRequired: false,
    professorComments: [],
    composingGrade: false,
  },
  getters: {
    agreeTerms: (state: AssignmentState) => state.agreeTerms,
    answers: (state: AssignmentState) => state.answers,
    videoIsRequired: (state: AssignmentState) => state.videoIsRequired,
    attachmentIsRequired: (state: AssignmentState) => state.attachmentIsRequired,
    assignmentMaterials: (state: AssignmentState) => state.assignmentMaterials,
    cover: (state: AssignmentState) => state.cover,
    keywords: (state: AssignmentState) => state.keywords,
    saving: (state: AssignmentState) => state.saving,
    enableToPublish: (state: AssignmentState) => {
      const required = state.workToFill?.questions?.filter((question) => question.required) || [];
      const requiredAndAnswered = state.answers?.filter(
        (question) => Boolean(required.find((req) => req.id === question.id)),
      );

      return Boolean(state.agreeTerms
        && state.title?.length
        && (state.cover?.length || state.coverDownload?.length)
        && (required?.length === requiredAndAnswered?.length)
        && state.keywords?.length)
        && (state.attachmentIsRequired
          ? state.assignmentMaterials.files?.length || state.assignmentMaterials.newFiles?.length
          : true)
        && (state.videoIsRequired ? state.assignmentMaterials.video?.length : true);
    },
    title: (state: AssignmentState) => state.title,
    unsavedChanges: (state: AssignmentState) => {
      const compareAnswers = (questions: GenericComparison[]) => JSON.stringify(
        questions?.map(({ answer, id }: GenericComparison) => ({ answer, id }))
        || [],
      );

      const {
        cover,
        answers,
        title,
        uploads,
        video,
        keywords,
      } = state.draft;

      const files: FileUpload[] = uploads?.map(
        (file: EntregaPublicadaAnexo) => ({
          id: file.id, name: file.titulo, src: file.src,
        })) || [];

      const oldMaterials = new AssignmentMaterial(files, video);
      const newMaterials = new AssignmentMaterial(
        state.assignmentMaterials?.files || [], state.assignmentMaterials?.video,
      );

      const comparison = !((state.cover || state.coverDownload) === cover)
        || state.title !== title
        || compareAnswers(state.answers) !== compareAnswers(answers)
        || JSON.stringify(newMaterials) !== JSON.stringify(oldMaterials)
        || JSON.stringify(keywords) !== JSON.stringify(state.keywords)
        || Boolean(state.assignmentMaterials?.newFiles?.length);

      return comparison;
    },
    workId: (state: AssignmentState) => state.workId,
    workToFill: (state: AssignmentState) => state.workToFill,
    statement: (state: AssignmentState) => state.statement,
    addMaterialsOpen: (state: AssignmentState) => state.addMaterialsOpen,
    professorComments: (state: AssignmentState) => state.professorComments,
    composingGrade: (state: AssignmentState) => state.composingGrade,
  },
  mutations: {
    SET_DELIVERY_ID(state: AssignmentState, id: number) {
      state.deliveryId = id;
    },
    SET_AGREE_TERMS(state: AssignmentState, agree: boolean) {
      state.agreeTerms = agree;
    },
    SET_ANSWERS(state: AssignmentState, answers: QuestionModel[]) {
      state.answers = answers;
    },
    SET_ASSIGNMENT_MATERIALS(state: AssignmentState, materials: AssignmentMaterial) {
      state.assignmentMaterials = materials;
    },
    SET_VIDEO_REQUIREMENT(state: AssignmentState, videoIsRequired: boolean) {
      state.videoIsRequired = videoIsRequired;
    },
    SET_ATTACHMENT_REQUIREMENT(state: AssignmentState, attachmentIsRequired: boolean) {
      state.attachmentIsRequired = attachmentIsRequired;
    },
    SET_COVER(state: AssignmentState, cover: string) {
      state.cover = cover;
    },
    SET_DOWNLOAD_COVER(state: AssignmentState, cover: string) {
      state.coverDownload = cover;
    },
    SET_DRAFT(state: AssignmentState, draft: DraftDelivery) {
      state.draft = { ...draft };
    },
    SET_ADD_MATERIALS_OPEN(state: AssignmentState, addMaterialsOpen: boolean) {
      state.addMaterialsOpen = addMaterialsOpen;
    },
    SET_KEYWORDS(state: AssignmentState, keywords: KeywordModel[]) {
      state.keywords = keywords;
    },
    SET_SAVING(state: AssignmentState, saving: boolean) {
      state.saving = saving;
    },
    SET_TITLE(state: AssignmentState, title: string) {
      state.title = title;
    },
    SET_WORK_ID(state: AssignmentState, workId: number) {
      state.workId = workId;
    },
    SET_WORK_TO_FILL(state: AssignmentState, workToFill: AssignmentObjectModel) {
      state.workToFill = workToFill;
    },
    SET_STATEMENT(state: AssignmentState, statement: StatementData) {
      state.statement = statement;
    },
    SET_PROFESSOR_COMMENTS(state: AssignmentState, professorComments: CommentModel[]) {
      state.professorComments = [...professorComments];
    },
    SET_COMPOSING_GRADE(state: AssignmentState, composingGrade: boolean) {
      state.composingGrade = composingGrade;
    },
    RESET_DELIVERY_ID(state: AssignmentState) {
      state.deliveryId = 0;
    },
    RESET_AGREED_TERMS(state: AssignmentState) {
      state.agreeTerms = false;
    },
    RESET_WORK_TO_FILL(state: AssignmentState) {
      state.workToFill = new AssignmentObjectModel();
    },
    RESET_DRAFT(state: AssignmentState) {
      state.draft = new DraftDelivery();
    },
    RESET_ANSWERS(state: AssignmentState) {
      state.answers = [];
    },
    RESET_ASSIGNMENT_MATERIALS(state: AssignmentState) {
      state.assignmentMaterials = new AssignmentMaterial([]);
    },
    RESET_KEYWORDS(state: AssignmentState) {
      state.keywords = [];
    },
    RESET_STATEMENT(state: AssignmentState) {
      state.statement = new StatementData();
    },
    RESET_DOWNLOAD_COVER(state: AssignmentState) {
      state.coverDownload = '';
    },
    RESET_VIDEO_REQUIREMENT(state: AssignmentState) {
      state.videoIsRequired = false;
    },
    RESET_ATTACHMENT_REQUIREMENT(state: AssignmentState) {
      state.attachmentIsRequired = false;
    },
    RESET_TITLE(state: AssignmentState) {
      state.title = '';
    },
    RESET_COVER(state: AssignmentState) {
      state.cover = '';
    },
    RESET_PROFESSOR_COMMENTS(state: AssignmentState) {
      state.professorComments = [];
    },
    RESET_COMPOSING_GRADE(state: AssignmentState) {
      state.composingGrade = false;
    },
  },
  actions: {
    getWorkToFill({
      commit,
      dispatch,
    }, id: number) {
      dispatch('resetFill');
      dispatch('privacyTerms/getPrivacyTerms', null, { root: true });

      AssignmentService.getAssignmentToFill(id)
        .then((response: AssignmentObjectModel) => {
          const { draft } = response;

          if (draft) {
            commit(SET_DRAFT, draft);
            commit(SET_DELIVERY_ID, draft.id);
            commit(SET_ANSWERS, draft.answers);
            commit(SET_COVER, draft.cover);
            commit(SET_DOWNLOAD_COVER, draft.cover);
            commit(SET_TITLE, draft.title);
            commit(SET_KEYWORDS, [...draft.keywords]);
            const files: FileUpload[] = draft.uploads.map(
              (file: EntregaPublicadaAnexo) => ({
                id: file.id, name: file.titulo, src: file.src,
              }),
            );

            const materials = new AssignmentMaterial(files, draft.video);
            commit(SET_ASSIGNMENT_MATERIALS, materials);
            commit(SET_PROFESSOR_COMMENTS, response.draft?.professorComments);
          }

          commit(SET_WORK_ID, response.id);
          commit(SET_WORK_TO_FILL, response);

          const statement = new StatementData(
            response.statement,
            response.discipline.name,
            response.video,
            response.files,
          );

          commit(SET_STATEMENT, statement);
          commit(SET_ATTACHMENT_REQUIREMENT, Boolean(response.attachment));
          commit(SET_VIDEO_REQUIREMENT, Boolean(response.uploadVideo));
          const composingGrade = response.class?.composingGrade
            ?? ArrayUtils.lastItem(response.classes).composingGrade;
          commit(SET_COMPOSING_GRADE, composingGrade);

          EventBus.$emit(RenderOptionalsEvents.SET_OPTIONALS);
        });
    },
    resetFill({ commit }) {
      commit(RESET_DELIVERY_ID);
      commit(RESET_AGREED_TERMS);
      commit(RESET_WORK_TO_FILL);
      commit(RESET_DRAFT);
      commit(RESET_ANSWERS);
      commit(RESET_ASSIGNMENT_MATERIALS);
      commit(RESET_KEYWORDS);
      commit(RESET_STATEMENT);
      commit(RESET_DOWNLOAD_COVER);
      commit(RESET_COVER);
      commit(RESET_TITLE);
      commit(RESET_ATTACHMENT_REQUIREMENT);
      commit(RESET_VIDEO_REQUIREMENT);
      commit(RESET_PROFESSOR_COMMENTS);
      commit(RESET_COMPOSING_GRADE);
    },
    setAgreeTerms({ commit }, agreeTerms: boolean) {
      commit(SET_AGREE_TERMS, agreeTerms);
    },
    setCoverDownload({ commit }, cover) {
      commit(SET_DOWNLOAD_COVER, cover);
    },
    setAnswers({ commit }, answers: QuestionModel[]) {
      commit(SET_ANSWERS, answers);
    },
    setAssignmentMaterials({ commit }, assignmentMaterials: AssignmentMaterial) {
      commit(SET_ASSIGNMENT_MATERIALS, assignmentMaterials);
    },
    setCover({ commit }, cover: string) {
      commit(SET_COVER, cover);
    },
    setKeywords({ commit }, keywords: KeywordModel[]) {
      commit(SET_KEYWORDS, keywords);
    },
    setSaving({ commit }, saving: boolean) {
      commit(SET_SAVING, saving);
    },
    setTitle({ commit }, title: string) {
      commit(SET_TITLE, title);
    },
    setWorkId({ commit }, workId: number) {
      commit(SET_WORK_ID, workId);
    },
    setWorkToFill({ commit }, workToFill: AssignmentObjectModel) {
      commit(SET_WORK_TO_FILL, workToFill);
    },
    setStatement({ commit }, statement: StatementData) {
      commit(SET_STATEMENT, statement);
    },
    setAddMaterialsOpen({ commit }, addMaterialsOpen: boolean) {
      commit(SET_ADD_MATERIALS_OPEN, addMaterialsOpen);
    },
    publishAssignment({ commit, state }, publishDraft = true) {
      return new Promise((resolve, reject) => {
        if (!publishDraft) commit(SET_SAVING, true);

        const delivery: Delivery = {
          id: state.deliveryId,
          answers: state.answers,
          assignmentObjectId: state.workId,
          cover: state.coverDownload,
          keywords: state.keywords,
          title: state.title,
          publish: publishDraft,
          ...(state.assignmentMaterials && { materials: state.assignmentMaterials }),
        };

        AssignmentService.publish(delivery)
          .then((savedDelivery: AssignmentObjectModel) => {
            const makeAnswers = (q: QuestaoResposta[]): Answer[] => q
              .map(({ trabalhoQuestaoId, resposta }) => ({
                id: trabalhoQuestaoId, answer: resposta,
              }));

            const draft: DraftDelivery = {
              ...savedDelivery?.draft,
              answers: makeAnswers(savedDelivery?.draft?.questions || []),
            } as DraftDelivery;

            const files: FileUpload[] = draft.uploads?.map(
              (file: EntregaPublicadaAnexo) => ({
                id: file.id, name: file.titulo, src: file.src,
              })) || [];

            const materialsToCommit = new AssignmentMaterial(files, draft.video);

            commit(SET_DRAFT, draft);
            commit(SET_DELIVERY_ID, draft.id);
            commit(SET_COVER, draft.cover);
            commit(SET_ASSIGNMENT_MATERIALS, materialsToCommit);
            commit(SET_ANSWERS, draft.answers);
            commit(SET_KEYWORDS, [...draft.keywords]);

            resolve(draft);
          })
          .catch(({ response }: AxiosError) => {
            const { error } = response?.data;
            reject(error);
          })
          .finally(() => {
            ModalService.fecharModal();
            commit(SET_SAVING, false);
          });
      });
    },
  },
};

export default assignment;
