import { Curso } from './api/curso';

export class Course {
  id: number;
  name: string;
  publishedAssignment?: boolean;
  // TODO: talvez usar um enum
  graduationType?: string;
  type?: string;
  assignments?: number[];
  slug?: string;
  classId?: number;

  constructor(produto?: Curso) {
    this.id = produto?.id || 0;
    this.name = produto?.nome || produto?.produto || '';
    this.slug = produto?.slug || '';
    this.publishedAssignment = produto?.assignmentPublicado || false;
    this.type = produto?.tipo || '';
    this.assignments = produto?.assignments || [];
    this.graduationType = produto?.tipo || '';
    this.classId = produto?.cursoId || 0;
  }
}
