import { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { NotificacaoVisualizada, NotificationApi } from '@/models/api';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const NotificationService = {
  markNotificationAsRead(id: number): Promise<NotificacaoVisualizada> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.markAsRead(id));

      httpClient
        .get(url)
        .then((response: AxiosResponse<NotificacaoVisualizada>) => resolve(response.data))
        .catch((err: AxiosError) => reject(new Error(err.message)));
    });
  },

  getNotifications(page = 1): Promise<NotificationApi> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.notifications(), [{ page }]);

      httpClient
        .get(url)
        .then((response: AxiosResponse<NotificationApi>) => {
          if (!response?.data) throw new Error('Ooops');

          resolve(response?.data);
        })
        .catch((err: AxiosError) => reject(new Error(err.message)));
    });
  },
};
