
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import CourseCard from '@/components/Course/CourseCard.vue';

import { SidebarTypes, UserModel, CoverList } from '@/models';

import { SidebarService } from '@/services';

@Component({
  name: 'CourseSidebar',
  components: {
    CourseCard,
  },
  computed: {
    ...mapGetters('user', {
      getUserCourses: 'getUserCourses',
    }),
  },
  methods: {
    ...mapActions('user', {
      getUserInfo: 'getUserInfo',
    }),
  },
})
export default class Unsplash extends Vue {
  private getUserInfo: () => Promise<UserModel>;

  mounted() {
    this.getUserInfo();
  }

  public selectCover(imageDetails: CoverList) {
    SidebarService.respostaSidebar(SidebarTypes.COURSESIDEBAR, imageDetails, false);
  }

  public respostaSidebar(resposta: boolean) {
    SidebarService.respostaSidebar(SidebarTypes.COURSESIDEBAR, resposta, !resposta);
  }
}

