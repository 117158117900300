import { UploadDeArquivo } from './api/upload-de-arquivo';
import { FileUpload } from './file-upload';

export class AssignmentMaterial {
  files: FileUpload[];
  newFiles: UploadDeArquivo[];
  video: string;

  constructor(files: FileUpload[], video?: string) {
    this.files = files;
    if (video) this.video = video;
  }
}
