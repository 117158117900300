var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g({class:{
    preenchido: _vm.preenchido,
    'preenchido--BLACK': _vm.preenchido && _vm.color === _vm.BLACK,
    delineado: !_vm.preenchido,
    'delineado--dark': _vm.light && !_vm.preenchido,
    'delineado--like': _vm.isLikeButton,
    'delineado--BLUE': !_vm.preenchido && _vm.color === _vm.BLUE,
    'delineado--WHITE': !_vm.preenchido && _vm.color === _vm.WHITE,
  },style:({ padding: _vm.padding }),attrs:{"disabled":_vm.disabled}},_vm.$listeners),[_vm._t("default"),(!_vm.loading)?[_vm._v(" "+_vm._s(_vm.msg)+" ")]:[_c('span',{staticClass:"spinner"}),_c('span',{staticClass:"loading-state"},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }