import { CaminhoEntrega } from './api';
import { Course } from './course';
import { Discipline } from './discipline';

export class BreadcrumbModel {
  course: Course;
  discipline: Discipline;
  class: string;
  title: string;

  constructor(caminhoEntrega?: CaminhoEntrega) {
    this.course = new Course(caminhoEntrega?.curso) || new Course();
    this.discipline = new Discipline(caminhoEntrega?.disciplina) || new Discipline();
    this.class = caminhoEntrega?.turma || '';
    this.title = caminhoEntrega?.titulo || '';
  }
}
