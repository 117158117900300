import axios, { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import env from '@/configs/env';
import { TokenService } from './TokenService';

export const ApiService = axios.create({
  baseURL: env.apiURL,
  timeout: 600000,
});

const goToLogin = () => {
  window.location.href = '/logout';
};

const pending = new Map();

const addPending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url].join('&');
  // eslint-disable-next-line
  config.cancelToken = config.cancelToken || new axios.CancelToken((cancel: Canceler) => {
    if (!pending.has(url)) pending.set(url, cancel);
  });
};

const cancelPending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url].join('&');
  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel('Request cancelled');
    pending.delete(url);
  }
};

ApiService.interceptors.request.use((request: AxiosRequestConfig):
  AxiosRequestConfig | Promise<AxiosRequestConfig> | void => {
  cancelPending(request);
  addPending(request);

  const { headers } = request;

  if (headers.ignoreToken) {
    delete headers.ignoreToken;
    return request;
  }

  const headersWithToken = {
    ...headers,
    ...TokenService.getAuthorizationHeader(),
  };

  return { ...request, headers: headersWithToken };
});

ApiService.interceptors.response.use((response: AxiosResponse) => {
  const url = [response.config.method, response.config.url].join('&');
  pending.delete(url);
  return response;
}, (error) => {
  if (error && (!error.response || axios.isCancel(error))) throw error.message;

  const { status } = error.response;

  switch (status) {
    // 401 = Unauthorized
    case 401:
      goToLogin();
      return;
    default:
  }
  throw error;
});
