import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist, { AsyncStorage } from 'vuex-persist';
import localforage from 'localforage';
import { StorageKeys } from '@/models';
import { RootState } from '@/models/store';
import comments from './comments';
import headerDisplay from './header-display';
import login from './login';
import notification from './notification';
import user from './user';
import myAssignmentsPage from './my-assignments-page';
import assignment from './assignment';
import privacyTerms from './privacyTerms';
import assignmentObject from './assignment-object';
import publishedAssignment from './published-assignment';
import course from './course';
import deliveries from './deliveries';
import discipline from './discipline';
import classStore from './class';
import assignmentObjectList from './assignment-object-list';

const { INDEXEDDB, WEBSQL, LOCALSTORAGE } = localforage;

const localForage = localforage.createInstance({
  driver: [
    INDEXEDDB,
    WEBSQL,
    LOCALSTORAGE,
  ],
  name: StorageKeys.ROOT,
  storeName: StorageKeys.TABLE_NAME,
});

export const vuexPersist = new VuexPersist<RootState>({
  storage: localStorage,
  key: StorageKeys.ROOT,
  reducer: (state: RootState) => ({
    headerDisplay: state.headerDisplay,
    login: state.login,
    notification: state.notification,
    myAssignmentsPage: state.myAssignmentsPage,
    privacyTerms: state.privacyTerms,
    user: state.user,
  }),
});

export const localForagePersistence = new VuexPersist<RootState>({
  storage: localForage as AsyncStorage,
  asyncStorage: true,
  key: StorageKeys.USER,
  reducer: (state: RootState) => ({
    ...state.user,
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    assignmentObject,
    assignment,
    classStore,
    comments,
    course,
    deliveries,
    discipline,
    headerDisplay,
    login,
    notification,
    myAssignmentsPage,
    privacyTerms,
    publishedAssignment,
    user,
    assignmentObjectList,
  },
});
