import { Delivery } from '../delivery';
import { GradeFormModel } from '../grade-form';
import { Entrega, Pagination } from '../api';

export interface DeliveriesState {
  initialDeliveriesList: DeliveryState[];
  deliveriesList: DeliveryState[];
  requesting: boolean;
  pagination?: Pagination<DeliveryState>;
}

export enum DeliveriesCommits {
  CLEAR_DATA = 'CLEAR_DATA',
  DELETE_DELIVERY = 'DELETE_DELIVERY',
  REQUESTING_DELIVERIES = 'REQUESTING_DELIVERIES',
  RESET_DELIVERY = 'RESET_DELIVERY',
  SET_DELIVERIES_LIST = 'SET_DELIVERIES_LIST',
  SET_DELIVERY_GRADE = 'SET_DELIVERY_GRADE',
  SET_INITIAL_LIST = 'SET_INITIAL_LIST',
  SET_PAGINATION_DATA = 'SET_PAGINATION_DATA',
  UPDATE_DELIVERIES_LIST = 'UPDATE_DELIVERIES_LIST',
  UPDATE_INITIAL_DATA = 'UPDATE_INITIAL_DATA',
}

export class DeliveryState extends Delivery {
  hasChanges?: boolean;
  grades?: GradeFormModel[];
  commentIdToEdit?: number;
  enableAditionalDate?: boolean;

  constructor(entrega?: Entrega) {
    super(entrega);

    this.hasChanges = false;
    this.grades = [];
    this.enableAditionalDate = false;
  }
}
