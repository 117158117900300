export const FormDataUtils = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFormData: (data: any): FormData => {
    const formData = new FormData();
    const keys = Object.keys(data);

    keys.forEach((key: string) => {
      const dataValue = data[key];
      const value = typeof dataValue === 'string' ? dataValue : JSON.stringify(dataValue);
      formData.append(key, value);
    });

    return formData;
  },
};
