import { Module } from 'vuex';
import { AxiosError } from 'axios';
import { Breakpoint } from '@/models';
import {
  NotificationMode,
  NotificationModeState,
  NotificationCommits,
  RootState,
} from '@/models/store';
import { NotificationService } from '@/services/NotificationService';
import { NotificationApi } from '@/models/api';

const {
  SET_NOTIFICATION_MODE,
  SET_NOTIFICATION_VISIBILITY,
  SET_NOTIFICATIONS,
} = NotificationCommits;

const notification: Module<NotificationModeState, RootState> = {
  namespaced: true,
  state: {
    mode: NotificationMode.BOX,
    opened: false,
    notificationApi: {
      data: [],
      notificacoesPendentes: null,
      current_page: 0,
      first_page_url: '',
      from: 0,
      last_page: 0,
      last_page_url: '',
      next_page_url: '',
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
  },
  getters: {
    isBoxMode: (state: NotificationModeState) => state.mode === NotificationMode.BOX,
    isPageMode: (state: NotificationModeState) => state.mode === NotificationMode.PAGE,
    isOpened: (state: NotificationModeState) => state.opened,
    notificationApi: (state: NotificationModeState) => state.notificationApi,
  },
  mutations: {
    SET_NOTIFICATION_MODE(state: NotificationModeState, { mode }: NotificationModeState) {
      state.mode = mode;
    },
    SET_NOTIFICATION_VISIBILITY(state: NotificationModeState, { opened }: NotificationModeState) {
      state.opened = opened;
    },
    SET_NOTIFICATIONS(state: NotificationModeState, { notificationApi }: NotificationModeState) {
      state.notificationApi = notificationApi;
    },
  },
  actions: {
    checkMode({ commit }) {
      commit(SET_NOTIFICATION_MODE, {
        mode: window.innerWidth <= Breakpoint.MD ? NotificationMode.PAGE : NotificationMode.BOX,
      });
    },
    toggleVisibility({ commit, state }) {
      commit(SET_NOTIFICATION_VISIBILITY, {
        opened: !state.opened,
      });
    },
    markAsRead({ commit, state }, id: number) {
      return new Promise((resolve, reject) => {
        NotificationService.markNotificationAsRead(id)
          .then((n) => {
            const notfi = {
              ...state.notificationApi,
              ...(
                state.notificationApi?.notificacoesPendentes
                && { notificacoesPendentes: state.notificationApi.notificacoesPendentes - 1 }
              ),
            };

            const newState: NotificationModeState = { ...state, notificationApi: notfi };

            commit(SET_NOTIFICATIONS, newState);
            resolve(n.status);
          })
          .catch((err: AxiosError) => reject(new Error(err.message)));
      });
    },
    getNotifications({ commit }, page = 1): Promise<NotificationApi> {
      return new Promise((resolve, reject) => {
        NotificationService.getNotifications(page)
          .then(
            (n: NotificationApi) => {
              commit(SET_NOTIFICATIONS, { notificationApi: n });
              resolve(n);
            },
          )
          .catch((err: AxiosError) => reject(new Error(err.message)));
      });
    },
  },
};

export default notification;
