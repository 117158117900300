import { AssignmentObjectModel } from '../assignment-object';
import { Pagination } from '../api';

export interface AssignmentObjectListState {
  assignmentObjectList: AssignmentObjectModel[];
  pagination: Pagination<AssignmentObjectModel>;
}

export enum AssignmentObjectListCommits {
  RESET_ASSIGNMENT_OBJECT_LIST = 'RESET_ASSIGNMENT_OBJECT_LIST',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_ASSIGNMENT_OBJECT_LIST = 'SET_ASSIGNMENT_OBJECT_LIST',
  DELETE_ASSIGNMENT_OBJECT_FROM_LIST = 'DELETE_ASSIGNMENT_OBJECT_FROM_LIST',
}
