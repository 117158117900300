import { AxiosError, AxiosResponse } from 'axios';

import { FilterUtils } from '@/utils';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';

import { FiltroParams, Filtro } from '@/models/api';

import { ApiService } from './ApiService';

const httpClient = ApiService;

export const FilterService = {
  getFilters(
    objectId: number,
    courseId: number,
    filterParams: FiltroParams,
  ): Promise<Filtro> {
    return new Promise((resolve, reject) => {
      const filter = filterParams;
      if (filter.filtros) delete (filter.filtros as Filtro)[filter.filtro as keyof FiltroParams];

      const endpoint = buildApiUrl(
        ApiUrls.getFilters(
          objectId,
          courseId,
          encodeURIComponent(FilterUtils.toBase64(filter)),
        ),
      );

      httpClient
        .get(endpoint)
        .then((response: AxiosResponse<Filtro>) => {
          if (response && !response.data) reject(new Error('Ooops'));

          const responseData = response.data;

          Object.keys(response.data).forEach((key) => {
            responseData[key] = response.data[key].filter((data) => data !== null);
          });

          resolve(responseData);
        })
        .catch((err: AxiosError) => reject(err));
    });
  },
};
