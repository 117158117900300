import { MyAssignments } from '../my-assignments';

export interface MyAssignmentsPageState {
  my: MyAssignments[];
  loading: boolean;
}

export enum MyAssignmentsPageCommits {
  SET_MY_ASSIGNMENTS = 'SET_MY_ASSIGNMENTS',
  SET_LOADING = 'SET_LOADING',
}
