import { Dictionary } from 'vue-router/types/router.d';
import { RouteName } from './route-name';

export class BreadcrumbMeta {
  key: string;
  name?: RouteName;
  paramsKeyPath?: Dictionary<string>;

  constructor(key: string, name?: RouteName, paramsKeyPath?: Dictionary<string>) {
    this.key = key;

    if (name) this.name = name;
    if (paramsKeyPath) this.paramsKeyPath = paramsKeyPath;
  }
}
