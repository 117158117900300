import { PalavraChave, PalavraChavePaginada } from './api';
import { KeywordModel } from './keyword';

export class KeywordPaginated {
  current_page: number;
  data: KeywordModel[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;

  constructor(palavrasChaves: PalavraChavePaginada) {
    this.current_page = palavrasChaves.current_page || 0;
    this.first_page_url = palavrasChaves.first_page_url || '';
    this.from = palavrasChaves.from || 0;
    this.last_page = palavrasChaves.last_page || 0;
    this.last_page_url = palavrasChaves.last_page_url || '';
    this.next_page_url = palavrasChaves.next_page_url || '';
    this.path = palavrasChaves.path || '';
    this.per_page = palavrasChaves.per_page || 0;
    this.prev_page_url = palavrasChaves.prev_page_url || '';
    this.to = palavrasChaves.to || 0;
    this.total = palavrasChaves.total || 0;

    this.data = palavrasChaves.data
      .map((palavraChave: PalavraChave) => new KeywordModel(palavraChave)) || [];
  }
}
