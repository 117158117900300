import { EntregaPublicada, Questao } from '../api';
import { BreadcrumbModel } from '../breadcrumb-model';
import { CoverDataList } from '../cover-data-list';
import { Reaction } from '../reaction';
import { Student } from '../student';

export interface PublishedAssignmentState {
  breadcrumbs: BreadcrumbModel;
  delivery: EntregaPublicada;
  deliveryId: number;
  workQuestions: Questao[];
  likeRequesting: boolean;
  profileData: Student;
  reaction: Reaction;
  coversList: CoverDataList;
}

export enum PublishedAssignmentCommits {
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  SET_DELIVERY_ID = 'SET_DELIVERY_ID',
  SET_DELIVERY = 'SET_DELIVERY',
  SET_WORK_QUESTIONS = 'SET_WORK_QUESTIONS',
  SET_CAROUSEL = 'SET_CAROUSEL',
  SET_PROFILE_DATA = 'SET_PROFILE_DATA',
  SET_REACTION = 'SET_REACTION',
  SET_LIKE = 'SET_LIKE',
  SET_LIKE_REQUEST = 'SET_LIKE_REQUEST',
  CLEAR_DEBOUNCE_ACCESS = 'CLEAR_DEBOUNCE_ACCESS',
  RESET_ASSIGNMENT_TO_VIEW = 'RESET_ASSIGNMENT_TO_VIEW',
}
