import { Module } from 'vuex';
import { AxiosError } from 'axios';
import {
  ClassCommits,
  ClassState,
  RootState,
} from '@/models/store';
import { Class } from '@/models';
import { ClassService } from '@/services';
import { ClassesGetParams } from '../models/classes-get-params';

let timeout: number;

const {
  CLEAR_CLASSES_LIST,
  SEARCHING_CLASSES,
  SET_CLASSES_LIST,
} = ClassCommits;

const initialState = {
  classList: [],
  searchingClasses: false,
};

const classStore: Module<ClassState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    classList: ({ classList }: ClassState): Class[] => classList,
  },
  mutations: {
    CLEAR_CLASSES_LIST(state: ClassState) {
      state.classList = initialState.classList;
      state.searchingClasses = initialState.searchingClasses;
    },
    SEARCHING_CLASSES(state: ClassState, searching: boolean) {
      state.searchingClasses = searching;
    },
    SET_CLASSES_LIST(state: ClassState, list: Class[]) {
      state.classList = list;
    },
  },
  actions: {
    clearClassList: ({ commit }) => commit(CLEAR_CLASSES_LIST),
    getClassList: ({ commit }, params: ClassesGetParams): Promise<Class[]> => {
      clearTimeout(timeout);
      commit(CLEAR_CLASSES_LIST);
      return new Promise((resolve, reject) => {
        commit(SEARCHING_CLASSES, true);
        ClassService.getClasses(params)
          .then(async (classList: Class[]) => {
            await commit(SET_CLASSES_LIST, classList);
            timeout = window.setTimeout(() => { resolve(classList); });
          })
          .catch((err: AxiosError) => reject(err))
          .finally(() => commit(SEARCHING_CLASSES, false));
      });
    },
  },
};

export default classStore;
