import { Class } from '../class';

export interface ClassState {
  classList: Class[];
  searchingClasses: boolean;
}

export enum ClassCommits {
  CLEAR_CLASSES_LIST = 'CLEAR_CLASSES_LIST',
  SEARCHING_CLASSES = 'SEARCHING_CLASSES',
  SET_CLASSES_LIST = 'SET_CLASSES_LIST',
}
