import { PermissionMeta, UserPermissions } from '@/models';
import { Permissoes, TipoDeAcesso } from '@/models/api';

const { ADMIN, STUDENT } = TipoDeAcesso;
const { VIEW_NETWORKING, EDIT_NETWORKING, EDIT_SCORE_NETWORKING } = Permissoes;
const {
  ASSIGNMENT_PUBLISHED,
  DEVELOPMENT,
  COURSE_SET,
  DISCIPLINE_SET,
  MULTIPLE_COURSES,
  ASSIGNMENT_AVAILABLE,
} = UserPermissions;

export const AccessPermissions = {
  OnlyDev: new PermissionMeta(ADMIN, [DEVELOPMENT]),
  Student: new PermissionMeta(STUDENT, [ASSIGNMENT_PUBLISHED]),
  StudentWithAssignment: new PermissionMeta(STUDENT, [ASSIGNMENT_AVAILABLE]),
  StudentNoPublished: new PermissionMeta(STUDENT, []),
  StudentNoPublishedCourseSet: new PermissionMeta(STUDENT, [COURSE_SET]),
  StudentCourseSet: new PermissionMeta(STUDENT, [ASSIGNMENT_PUBLISHED, COURSE_SET]),
  StudentDisciplineSet: new PermissionMeta(STUDENT, [ASSIGNMENT_PUBLISHED, DISCIPLINE_SET]),
  StudentDisciplineAndCourseSet: new PermissionMeta(
    STUDENT,
    [
      ASSIGNMENT_PUBLISHED,
      DISCIPLINE_SET,
      COURSE_SET,
    ],
  ),
  StudentMultipleCourses: new PermissionMeta(STUDENT, [ASSIGNMENT_PUBLISHED, MULTIPLE_COURSES]),
  StudentMultipleCoursesNoPublished: new PermissionMeta(STUDENT, [MULTIPLE_COURSES]),
  Admin: new PermissionMeta(ADMIN, []),
  AdminView: new PermissionMeta(ADMIN, [VIEW_NETWORKING]),
  AdminEdit: new PermissionMeta(ADMIN, [EDIT_NETWORKING]),
  AdminScore: new PermissionMeta(ADMIN, [EDIT_SCORE_NETWORKING]),
  AdminViewAndEdit: new PermissionMeta(ADMIN, [VIEW_NETWORKING, EDIT_NETWORKING]),
  AdminViewAndScore: new PermissionMeta(ADMIN, [VIEW_NETWORKING, EDIT_SCORE_NETWORKING]),
  AdminEditAndScore: new PermissionMeta(ADMIN, [EDIT_NETWORKING, EDIT_SCORE_NETWORKING]),
  AdminAll: new PermissionMeta(ADMIN, [VIEW_NETWORKING, EDIT_NETWORKING, EDIT_SCORE_NETWORKING]),
};
