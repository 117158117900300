import { AxiosResponse } from 'axios';

import {
  AssignmentObjectModel,
  FileUpload,
  Class,
  KeywordModel,
  QuestionModel,
  ReportFilterData,
} from '@/models';
import {
  ObjetoDeAssignment,
  RespostaGenericaId,
  Pagination,
  QueryParamsModel,
} from '@/models/api';

import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { FormDataUtils, DownloadUtils } from '@/utils';
import { FilterUtils } from '@/utils/FilterUtils';

import { ApiService } from './ApiService';

const httpClient = ApiService;

const buildPayload = ({
  id,
  video,
  ...object
}: AssignmentObjectModel): FormData => {
  const arquivos = object.files?.map((file: FileUpload) => ({
    id: file.id,
    nome_arquivo: file.name,
    src: file.src,
    ...new File([], (file.name as string)),
  }));

  const turmas = object.classes?.map((singleClass: Class) => ({
    id: singleClass.id,
    curso: singleClass.name,
    descricao: singleClass.name,
    turma: singleClass.name,
    nome: singleClass.name,
    data_publicacao: singleClass.publicationDate,
    data_limite_entrega: singleClass.deliveryDate,
    data_limite_recuperacao: singleClass.recoveryDeliveryDate,
    composicao_nota: singleClass.composingGrade,
  }));

  const palavras_chave = object.keywords?.map((keyword: KeywordModel) => ({
    id: keyword.id,
    trabalhoId: keyword.assignmentObjectId,
    entregaId: keyword.deliveryId,
    palavra_chave: keyword.keyword,
  })) ?? [];

  const questoes = object.questions?.map((question: QuestionModel) => ({
    id: question.id,
    questao: question.question,
    obrigatorio: question.required,
    resposta: question.answer,
  })) ?? [];

  const questoes_removidas = object.removedQuestions?.filter((idDasQuestoesRemovidas: number) => (
    Boolean(idDasQuestoesRemovidas)
  )) || [];

  const objeto = {
    arquivos,
    turmas,
    palavras_chave,
    questoes,
    video,
    id,
    questoes_removidas,
    anexos: object.attachment,
    arquivos_novos: object.newFiles,
    disciplina_id: object.disciplineId,
    enunciado: object.statement,
    produto_id: object.courseId,
    publicar: object.publish,
    upload_video: object.uploadVideo,
  };

  const payload: FormData = FormDataUtils.toFormData(objeto);
  payload.delete('arquivos_novos');

  if (object.newFiles) {
    object.newFiles.forEach((file: File) => payload.append('arquivos_novos[]', file));
  }

  return payload;
};

export const AssignmentObjectService = {
  getObject(assignmentObjectId: number): Promise<AssignmentObjectModel> {
    return new Promise((resolve, reject) => {
      const assignmentObjectEndpoint = buildApiUrl(ApiUrls
        .getAssignmentObjectById(assignmentObjectId));

      httpClient
        .get(assignmentObjectEndpoint)
        .then((response: AxiosResponse<ObjetoDeAssignment>) => {
          if (!response.data) reject(new Error('Ooops!'));

          const assignmentObject = new AssignmentObjectModel(response.data);

          resolve(assignmentObject);
        })
        .catch(reject);
    });
  },

  getObjectsList(params?: QueryParamsModel): Promise<Pagination<AssignmentObjectModel>> {
    return new Promise((resolve, reject) => {
      const objectListEndpoint = buildApiUrl(ApiUrls.getAssignmentObjects(params));

      httpClient
        .get(objectListEndpoint)
        .then((resp: AxiosResponse<Pagination<ObjetoDeAssignment>>) => resolve({
          ...resp.data,
          data: (resp.data.data as ObjetoDeAssignment[])
            .map((tarefa: ObjetoDeAssignment) => new AssignmentObjectModel(tarefa)),
        }))
        .catch(reject);
    });
  },

  saveObject(object: AssignmentObjectModel): Promise<AssignmentObjectModel> {
    return new Promise((resolve, reject) => {
      const saveDraftEndpoint = buildApiUrl(ApiUrls.saveAssignmentObject());
      const payload: FormData = buildPayload(object);

      httpClient
        .post(saveDraftEndpoint, payload)
        .then((response: AxiosResponse<ObjetoDeAssignment>) => {
          resolve(new AssignmentObjectModel(response.data));
        })
        .catch(reject);
    });
  },

  deleteObject(objectId: number): Promise<number> {
    return new Promise((resolve, reject) => {
      const deleteUrl = buildApiUrl(ApiUrls.removeAssignmentObject(objectId));

      httpClient
        .delete(deleteUrl)
        .then((response: AxiosResponse<RespostaGenericaId>) => resolve(response.data.id))
        .catch(reject);
    });
  },

  reportDownload(data: ReportFilterData): Promise<[ArrayBuffer, string]> {
    return new Promise((resolve, reject) => {
      const reportFilter = FilterUtils.reportFilterToFilterParams(data);
      const url = buildApiUrl(ApiUrls.reportDownload(reportFilter as QueryParamsModel));

      httpClient
        .get(url, { responseType: 'arraybuffer' })
        .then((response: AxiosResponse<ArrayBuffer>) => {
          const filename = DownloadUtils.filename(response);
          resolve([response.data, filename]);
        })
        .catch(reject);
    });
  },
};
