import { AssignmentObjectModel } from '../assignment-object';
import { Course } from '../course';
import { Discipline } from '../discipline';
import { QuestionModel } from '../question';
import { Class } from '../class';
import { AssignmentObjectStatus } from '../assignment-object-status';
import { FileUpload } from '../file-upload';
import { KeywordModel } from '../keyword';

export interface AssignmentObjectState {
  id: number;
  attachment: boolean;
  classes: Class[];
  courseId: number;
  course: Course;
  disciplineId: number;
  discipline: Discipline;
  keywords: KeywordModel[];
  publish: boolean;
  questions: QuestionModel[];
  statement: string;
  status: AssignmentObjectStatus;
  uploadVideo: boolean;
  video: string;
  class?: Class;
  deletable?: boolean;
  files?: FileUpload[];
  initialState?: AssignmentObjectModel;
  newFiles?: File[];
  professor?: string;
  removedQuestions?: number[];
  createdAt?: Date;
}

export enum AssignmentObjectCommits {
  RESET_ASSIGNMENT_OBJECT = 'RESET_ASSIGNMENT_OBJECT',
  SET_ASSIGNMENT_OBJECT = 'SET_ASSIGNMENT_OBJECT',
  SET_CLASSES = 'SET_CLASSES',
  SET_COURSE = 'SET_COURSE',
  SET_DISCIPLINE = 'SET_DISCIPLINE',
  SET_FILES_REQUIRED = 'SET_FILES_REQUIRED',
  SET_FILES = 'SET_FILES',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_NEW_FILES = 'SET_NEW_FILES',
  SET_PUBLISH = 'SET_PUBLISH',
  SET_QUESTIONS = 'SET_QUESTIONS',
  SET_REMOVED_QUESTIONS = 'SET_REMOVED_QUESTIONS',
  SET_STATEMENT = 'SET_STATEMENT',
  SET_VIDEO_REQUIRED = 'SET_VIDEO_REQUIRED',
  SET_VIDEO = 'SET_VIDEO',
}
