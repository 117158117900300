import { ApiUrls, buildApiUrl } from '../utils/endpoints';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const AssignmentListService = {
  getCourseDisciplines(courseId: number) {
    const url = buildApiUrl(ApiUrls.getDisciplinesByCourse(courseId));

    return httpClient.get(url);
  },
};
