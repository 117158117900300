export interface LoginState {
  requestToken: string;
  refreshToken: string;
  tokenValido: boolean;
  requesting: boolean;
}

export enum LoginCommits {
  LOGOUT = 'LOGOUT',
  SET_LOGIN_INFOS = 'SET_LOGIN_INFOS',
  SET_LOGIN_VALIDITY = 'SET_LOGIN_VALIDITY',
  SET_REQUESTING_STATE = 'SET_REQUESTING_STATE',
}
