import { Course } from '../course';

export interface CourseState {
  courseList: Course[];
  searchingCourses: boolean;
}

export enum CourseCommits {
  CLEAR_COURSES_LIST = 'CLEAR_COURSES_LIST',
  SEARCHING_COURSES = 'SEARCHING_COURSES',
  SET_COURSES_LIST = 'SET_COURSES_LIST',
}
