import { DateUtils } from '@/utils';
import { Turma } from './api/turma';

export class Class {
  id: number;
  name: string;
  deliveryDate?: Date;
  recoveryDeliveryDate?: Date;
  publicationDate: Date | null;
  checked: boolean;
  assignmentObjectId: number;
  hasDeliveryPublished?: boolean;
  composingGrade: boolean;

  constructor(turmaApi: Turma, trabalhoId = 0, checked = false) {
    this.id = turmaApi.id;
    this.name = (
      turmaApi.descricao
      || turmaApi.turma
      || turmaApi.nome
      || turmaApi.curso
      || ''
    ).replace(/^Turma /g, '');
    this.publicationDate = turmaApi.data_publicacao
      ? new Date(turmaApi.data_publicacao.length > 10
        ? turmaApi.data_publicacao
        : `${turmaApi.data_publicacao} 00:00:00`)
      : null;
    this.assignmentObjectId = trabalhoId;
    this.checked = checked;

    if (turmaApi.data_limite_entrega
      && turmaApi.data_limite_entrega !== '0000-00-00 00:00:00') {
      this.deliveryDate = new Date(
        DateUtils.toDateWithHour(turmaApi.data_limite_entrega, '23:59:59'),
      );
    }
    if (turmaApi.data_limite_recuperacao
      && turmaApi.data_limite_recuperacao !== '0000-00-00 00:00:00') {
      this.recoveryDeliveryDate = new Date(
        DateUtils.toDateWithHour(turmaApi.data_limite_recuperacao, '23:59:59'),
      );
    }
    this.hasDeliveryPublished = turmaApi.tem_entrega_publicada ?? false;
    this.composingGrade = turmaApi.composicao_nota ?? false;
  }
}
