export const CssUtils = {
  buildVars: (data: {
    [key: string]: string | number | number[];
  }): { [key: string]: string } => {
    let vars = {};

    if (data.bgColor) vars = { ...vars, '--bgColor': data.bgColor };
    if (data.bdRadius) vars = { ...vars, '--bdRadius': CssUtils.returnPx(data.bdRadius as number) };
    if (data.fontSize) vars = { ...vars, '--fontSize': CssUtils.returnPx(data.fontSize as number) };
    if (data.fontFamily) vars = { ...vars, '--fontFamily': data.fontFamily };
    if (data.fontWeight) vars = { ...vars, '--fontWeight': data.fontWeight };
    if (data.lineHeight) {
      vars = {
        ...vars,
        '--lineHeight': CssUtils
          .returnPx(data.lineHeight as number),
      };
    }
    if (data.padding) vars = { ...vars, '--padding': CssUtils.returnPx(data.padding as number) };

    return vars;
  },
  returnPx: (pixels: number | number[]) => (
    (pixels as number[]).length
      ? (pixels as number[]).map((pixel: number) => `${pixel}px`).toString().replaceAll(',', ' ')
      : `${pixels}px`
  ),
};
