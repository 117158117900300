import { NavigationGuardNext, Route } from 'vue-router';
import { DynamicTableService } from '@/services';
import store from '@/store';

export const AssignmentObjectRouterAction = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
) => {
  next();
  if (to.params && to.params.trabalhoId) {
    const id = to.params.trabalhoId;

    DynamicTableService.requestStarted();
    store.dispatch('assignmentObject/getAssignmentObject', id)
      .finally(() => DynamicTableService.requestEnded());
  } else {
    store.dispatch('assignmentObject/resetAssignmentObject');
  }
};
