import { AxiosError, AxiosResponse } from 'axios';
import { LoginResponse } from '@/models/api';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { ignoreToken } from '@/utils';
import { ApiService } from './ApiService';
import { TokenService } from './TokenService';

const httpClient = ApiService;

export const LoginService = {
  login(email: string, senha: string): Promise<AxiosResponse<LoginResponse>> {
    const url = buildApiUrl(ApiUrls.login());
    const data = { email, senha };
    return httpClient.post(url, data, ignoreToken);
  },

  renewToken() {
    const { Authorization } = TokenService.getRefreshToken();
    const renewEndpoint = buildApiUrl(ApiUrls.renovaToken(Authorization));
    return httpClient.get(renewEndpoint, ignoreToken);
  },

  loginWithToken(token: string): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      const loginWithTokenEndpoint = buildApiUrl(ApiUrls.loginToken(token));
      httpClient
        .get(loginWithTokenEndpoint, ignoreToken)
        .then((resp: AxiosResponse<LoginResponse>) => resolve(resp.data))
        .catch((err: AxiosError) => reject(err));
    });
  },
  logout() {
    return new Promise((resolve) => {
      const logoutEndpoint = buildApiUrl(ApiUrls.logout());
      httpClient
        .head(logoutEndpoint)
        .catch((err) => console.error(err))
        .finally(() => resolve(true));
    });
  },
};
