import { FileUpload } from './file-upload';

export class StatementData {
  discipline: string;
  title: string;
  videoUrl?: string;
  files?: FileUpload[];

  constructor(
    title?: string,
    discipline?: string,
    videoUrl?: string,
    files?: FileUpload[],
  ) {
    this.title = title ?? '';
    this.discipline = discipline ?? '';
    this.videoUrl = videoUrl ?? '';
    this.files = files ?? [];
  }
}
