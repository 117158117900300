import { NavigationGuardNext, Route, RouteMeta } from 'vue-router';
import env from '@/configs/env';
import { RouteName } from '@/models';
import { Permissoes, TipoDeAcesso } from '@/models/api';
import store from '@/store';

const { STUDENT, ADMIN } = TipoDeAcesso;
const {
  INICIO,
  ASSIGNMENT_OBJECT_MANAGE,
  ASSIGNMENT_OBJECT_CREATE,
  ASSIGNMENT_OBJECT_EDIT,
  ASSIGNMENT_OBJECT_ASSIGNMENT,
  PROFILE,
  NOT_FOUND,
  RESCTRICTED_ACCESS,
  COURSES,
  PUBLISHED_ASSIGNMENT,
} = RouteName;

const adminVerification = (to: Route, next: NavigationGuardNext<Vue>) => {
  const canEdit = store.getters['user/canEditNetwork'];
  const canSetScore = store.getters['user/canSetScore'];
  switch (to.name) {
    case INICIO:
      next({ name: ASSIGNMENT_OBJECT_MANAGE });
      break;
    case ASSIGNMENT_OBJECT_CREATE:
    case ASSIGNMENT_OBJECT_EDIT:
      if (!canEdit) {
        next({ name: ASSIGNMENT_OBJECT_MANAGE });
        break;
      }
      next();
      break;
    case ASSIGNMENT_OBJECT_ASSIGNMENT:
      if (!canSetScore) {
        next({ name: ASSIGNMENT_OBJECT_MANAGE });
        break;
      }
      next();
      break;
    case NOT_FOUND:
      if (to.fullPath === '/') {
        next({ name: ASSIGNMENT_OBJECT_MANAGE });
        break;
      }
      next();
      break;
    default:
      next();
  }
};

const studentVerification = (to: Route, next: NavigationGuardNext<Vue>) => {
  const userPublishedCourses = store.getters['user/getUserPublishedCourses'];
  const hasPublishedAssignments = store.getters['user/hasPublishedAssignments'];
  const userCourseId = store.getters['user/getUserCourseId'];
  const hasCourseSet = store.getters['user/hasCourseSet'];
  const myDeliveriesIds = store.getters['myAssignmentsPage/myDeliveries'];
  switch (to.name) {
    case INICIO:
      if (!hasPublishedAssignments) {
        next({ name: RESCTRICTED_ACCESS });
        break;
      }

      if (hasCourseSet && !to.params.cursoId) {
        next({ name: INICIO, params: { cursoId: userCourseId.toString() } });
        break;
      }

      if (userPublishedCourses.length > 1 && !hasCourseSet) {
        next({ name: COURSES });
        break;
      }
      next();
      break;
    case NOT_FOUND:
      if (to.fullPath === '/') {
        next({ name: INICIO });
        break;
      }
      next();
      break;
    case PUBLISHED_ASSIGNMENT:
      if (!(hasPublishedAssignments || myDeliveriesIds.includes(Number(to.params.entregaId)))) {
        next({ name: RESCTRICTED_ACCESS });
        break;
      }
      next();
      break;
    default:
      next({ name: INICIO });
  }
};

const checkPermission = (
  pagePermissions: Permissoes[],
  userPermission: Permissoes[],
): boolean => pagePermissions
  .reduce((acc: boolean, cur: Permissoes) => {
    let accumulator = acc;
    if (!userPermission?.includes(cur)) accumulator = false;
    return accumulator;
  }, true);

export const PermissionGuard = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (!env.production) {
    next();
    return;
  }

  const { accessType, permissions } = to.meta as RouteMeta;
  const userAccessType = store.getters['user/getUserAccessType'];
  const userPermission = store.getters['user/getUserPermissions'];
  const userFirstAccess = store.getters['user/isFirstAccess'];

  if ((!accessType && to.fullPath !== '/')
    || (userFirstAccess && (to.name === PROFILE || userAccessType === ADMIN))
    || ((to.name !== NOT_FOUND && to.name !== INICIO)
      && (userAccessType === ADMIN || userAccessType === accessType)
      && (!permissions?.length || checkPermission(permissions, userPermission))
      && !userFirstAccess)) {
    next();
    return;
  }

  switch (userAccessType) {
    case ADMIN:
      adminVerification(to, next);
      break;
    case STUDENT:
      if (userFirstAccess && to.name !== PROFILE) {
        next({ name: PROFILE });
        break;
      }

      studentVerification(to, next);
      break;
    default:
      next();
  }
};
