import { Autor } from './api/lista-de-capas';

export class Author {
  name: string;
  portfolio: string;

  constructor(autor: Autor) {
    this.name = autor.nome;
    this.portfolio = autor.portfolio;
  }
}
