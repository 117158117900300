import { EventBus } from '@/utils/EventBus';
import { SidebarEvents, SidebarDataTransfer, SidebarTypes } from '@/models';

export const SidebarService = {
  abrirSidebar({ component, fechavel = true, props }: SidebarDataTransfer) {
    EventBus.$emit(SidebarEvents.OPEN, { component, fechavel, props });
  },

  fecharSidebar() {
    EventBus.$emit(SidebarEvents.CLOSE);
  },

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  respostaSidebar(tipo: SidebarTypes, resposta: any, podeFechar = true) {
    EventBus.$emit(tipo, resposta);
    if (podeFechar) SidebarService.fecharSidebar();
  },
};
