import { AxiosError } from 'axios';
import { Module } from 'vuex';
import { LoginService } from '@/services';
import { LoginInfos } from '@/models';
import { LoginState, LoginCommits, RootState } from '@/models/store';
import { LoginResponse } from '@/models/api';

const {
  SET_LOGIN_INFOS,
  SET_LOGIN_VALIDITY,
  LOGOUT,
  SET_REQUESTING_STATE,
} = LoginCommits;

const login: Module<LoginState, RootState> = {
  namespaced: true,
  state: {
    requestToken: '',
    refreshToken: '',
    tokenValido: false,
    requesting: false,
  },
  getters: {
    isLogged: (state: LoginState) => !!(state.requestToken.length && state.refreshToken.length),
    isValid: (state: LoginState) => (state.tokenValido),
    requesting: (state: LoginState) => (state.requesting),
  },
  mutations: {
    SET_LOGIN_INFOS(state: LoginState, { requestToken, refreshToken }: LoginState) {
      state.requestToken = requestToken;
      state.refreshToken = refreshToken;
    },
    SET_LOGIN_VALIDITY(state: LoginState, { tokenValido }: LoginState) {
      state.tokenValido = tokenValido;
    },
    LOGOUT(state: LoginState) {
      state.requestToken = '';
      state.refreshToken = '';
      state.tokenValido = false;
    },
    SET_REQUESTING_STATE(state: LoginState, requesting: boolean) {
      state.requesting = requesting;
    },
  },
  actions: {
    async login({ commit, dispatch }, { email, senha }: LoginInfos): Promise<boolean> {
      try {
        const loginResponse = await LoginService.login(email, senha);
        const infos = loginResponse.data;

        commit(SET_LOGIN_INFOS, {
          requestToken: infos.requestToken,
          refreshToken: infos.refreshToken,
        });
        commit(SET_LOGIN_VALIDITY, {
          tokenValido: true,
        });

        return dispatch('user/getUserInfo', {}, { root: true })
          .then(() => Boolean(infos.refreshToken.length && infos.requestToken));
      } catch (error) {
        return dispatch('logout').finally(() => false);
      }
    },
    loginWithToken({ commit, dispatch }, token: string): Promise<boolean> {
      return new Promise((resolve, reject) => {
        LoginService.loginWithToken(token)
          .then(async (tokens: LoginResponse) => {
            const { refreshToken, requestToken, credenciaisValidas } = tokens;
            await commit(SET_LOGIN_INFOS, { refreshToken, requestToken });
            await commit(SET_LOGIN_VALIDITY, { tokenValido: credenciaisValidas });
            dispatch('user/getUserInfo', {}, { root: true })
              .then(() => resolve(credenciaisValidas));
          })
          .catch((err: AxiosError) => dispatch('logout').finally(() => reject(err)));
      });
    },
    setValidity({ commit }, expired: boolean) {
      commit(SET_LOGIN_VALIDITY, expired);
    },
    logout({ commit, dispatch }) {
      commit(SET_REQUESTING_STATE, true);
      return new Promise((resolve) => {
        LoginService.logout()
          .finally(async () => {
            await commit(LOGOUT);
            dispatch('user/resetUserData', {}, { root: true });
            commit(SET_REQUESTING_STATE, false);
            resolve(true);
          });
      });
    },
  },
};

export default login;
