import { Area } from '@/models';
import { QueryParamsModel } from '@/models/api';

import { context } from './shared';
import { QueryParamsUtils } from './QueryParams';

const { ADMIN_OBJECT_AREA } = Area;
const prefix = (area: Area) => context[area];

export const AdministratorEndpoints = {
  saveAssignmentObject: () => prefix(ADMIN_OBJECT_AREA),
  getAssignmentObjectById: (trabalhoId: number) => `${prefix(ADMIN_OBJECT_AREA)}/${trabalhoId}`,
  removeAssignmentObject: (trabId: number) => `${prefix(ADMIN_OBJECT_AREA)}/${trabId}`,
  getAssignmentObjects: (params?: QueryParamsModel) => {
    let endpoint = `${prefix(ADMIN_OBJECT_AREA)}/cursos`;
    if (params) endpoint += QueryParamsUtils.queryParamsBuilder(params);
    return endpoint;
  },
  reportDownload: (data: QueryParamsModel) => (
    `administrador/relatorio/gerenciar-objetos${QueryParamsUtils.queryParamsBuilder(data)}`
  ),
};
