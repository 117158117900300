
import { Component, Vue } from 'vue-property-decorator';
import ModalContainer from '@/components/Modal/ModalContainer.vue';
import SidebarContainer from '@/components/Sidebar/SidebarContainer.vue';

@Component({
  name: 'App',
  components: { ModalContainer, SidebarContainer },
})
export default class App extends Vue {}
