import { Comentario, EntregaComentario } from './api';
import { CommentModel } from './comment';

export class DeliveryComment {
  current_page: number;
  data: CommentModel[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: string;
  path: string;
  per_page: number;
  prev_page_url?: string;
  to: number;
  total: number;

  constructor(entregaComentario?: EntregaComentario) {
    this.current_page = entregaComentario?.current_page || 0;
    this.first_page_url = entregaComentario?.first_page_url || '';
    this.from = entregaComentario?.from || 0;
    this.last_page = entregaComentario?.last_page || 0;
    this.last_page_url = entregaComentario?.last_page_url || '';
    this.next_page_url = entregaComentario?.next_page_url || '';
    this.path = entregaComentario?.path || '';
    this.per_page = entregaComentario?.per_page || 0;
    this.prev_page_url = entregaComentario?.prev_page_url || '';
    this.to = entregaComentario?.to || 0;
    this.total = entregaComentario?.total || 0;
    this.data = entregaComentario?.data
      .map((comentario: Comentario) => new CommentModel(comentario)) || [];
  }
}

export interface DeliveryComments {
  deliveryComment: CommentModel;
}
