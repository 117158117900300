export enum FloatingMenuContent {
  SIMPLE_TYPE = 'SimpleType',
  LINK_TYPE = 'LinkType',
  CHECKBOX_TYPE = 'CheckboxType',
  SELECT_TYPE = 'SelectType',
  INPUT_TYPE = 'InputType',
  BUTTON_TYPE = 'ButtonType',
  WRAPPER_TYPE = 'WrapperType',
  DIVIDER_TYPE = 'DividerType',
  SPINNER_TYPE = 'SpinnerType',
  TEXT_TYPE = 'TextType',
}
