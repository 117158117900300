import { AxiosError } from 'axios';
import { ModalDataTransfer, ModalTipos } from '@/models';
import { BuscaTermosPrivacidade } from '@/models/api/busca-termos-privacidade';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { ApiService } from './ApiService';
import { ModalService } from './ModalService';

const httpClient = ApiService;

const { TEXT } = ModalTipos;

export const PrivacyTermsService = {
  getPrivacyTerms(): Promise<BuscaTermosPrivacidade> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.getPrivacyTerms());

      httpClient.get(url)
        .then((termo) => resolve(termo.data))
        .catch((err: AxiosError) => reject(err));
    });
  },

  openModal(terms: BuscaTermosPrivacidade, dark: boolean, fechavel = true) {
    const { conteudo, nome } = terms;
    const props = { dark, title: nome, text: conteudo };

    const modalData: ModalDataTransfer = {
      fechavel,
      dark,
      component: TEXT,
      props,
    };

    ModalService.abrirModal(modalData);
  },
};
