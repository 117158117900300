import { Disciplina } from './disciplina';
import { Curso } from './curso';

export class CaminhoEntrega {
  curso: Curso;
  disciplina: Disciplina;
  turma: string;
  titulo: string;

  constructor() {
    this.curso = new Curso();
    this.disciplina = new Disciplina();
    this.turma = '';
    this.titulo = '';
  }
}
