import { ClassesGetParams, SearchDisciplineParams, QueryParamUrl } from '@/models';
import env from '../../configs/env';
import { AdministratorEndpoints } from './Administrator';
import { StudentEndpoints } from './Student';
import { KeywordEndpoints } from './Keyword';
import { Authentication } from './Authentication';
import { DeliveryEndpoints } from './Delivery';
import { UserEndpoints } from './User';
import { FilterEndpoints } from './Filter';

const version = 'v1';

export const ApiUrls = {
  ...StudentEndpoints,
  ...AdministratorEndpoints,
  ...Authentication,
  ...KeywordEndpoints,
  ...DeliveryEndpoints,
  ...UserEndpoints,
  ...FilterEndpoints,
  getCourses: (searchKey: string) => `produtos/${searchKey}`,
  getDisciplines: ({ courseId, searchKey }: SearchDisciplineParams) => (
    `disciplinas/${courseId}/${searchKey}`
  ),
  getClasses: ({ courseId, disciplineId }: ClassesGetParams) => (
    `cursos/${courseId}/${disciplineId}`
  ),
};

const buildQueryParams = (queryParams: QueryParamUrl[]) => queryParams.reduce((acc, cur, index) => {
  const key = Object.keys(cur).join();
  const value = Object.values(cur).join();

  const isLastParam = (queryParams.length - 1) === index;
  const hasAmpersand = isLastParam ? '' : '&';

  /* eslint-disable  no-param-reassign */
  return acc += `${key}=${value}${hasAmpersand}`;
}, '?');

export const buildApiUrl = (ApiUrl: string, queryParams?: QueryParamUrl[]) => (
  `${env.apiURL}/${version}/${ApiUrl}${queryParams ? buildQueryParams(queryParams) : ''}`
);
