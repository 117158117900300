import { Area } from '@/models';
import { context } from './shared';

const { AUTHENTICATION } = Area;
const prefix = (area: Area) => context[area];

export const Authentication = {
  login: () => `${prefix(AUTHENTICATION)}/login`,
  renovaToken: (refreshToken: string) => `${prefix(AUTHENTICATION)}/refreshToken/${refreshToken}`,
  loginToken: (token: string) => `${prefix(AUTHENTICATION)}/loginComTokenAcesso/${token}`,
  logout: () => `${prefix(AUTHENTICATION)}/logout`,
};
