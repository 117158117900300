import Vue from 'vue';
import VueRouter from 'vue-router';
import { AuthGuard } from '@/router/guards';
import { routes } from './routes';
import { scrollBehavior } from './scroll-behavior';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

router.beforeEach(AuthGuard);

export default router;
