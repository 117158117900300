import { Module } from 'vuex';
import { AxiosError } from 'axios';
import {
  CommentsState,
  CommentsCommits,
  CommentInProgress,
  RootState,
} from '@/models/store';
import { EntregaComentario } from '@/models/api';
import { DeliveryService } from '@/services';
import { EventBus } from '@/utils';
import { CommentModel, CommentPublishEvents, DeliveryComment } from '@/models';

const {
  SET_DELIVERY_COMMENTS,
  SET_COMMENT_LIST_PROGRESS,
  DELETE_COMMENT_LIST_PROGRESS,
  RESET_COMMENTS,
} = CommentsCommits;

const comments: Module<CommentsState, RootState> = {
  namespaced: true,
  state: {
    deliveryComments: null,
    commentListProgress: [],
  },
  getters: {
    comments: (state: CommentsState) => state.deliveryComments,
  },
  mutations: {
    SET_DELIVERY_COMMENTS(state: CommentsState, deliveryComments: EntregaComentario) {
      state.deliveryComments = deliveryComments;
    },
    SET_COMMENT_LIST_PROGRESS(state: CommentsState, comment: CommentInProgress) {
      state.commentListProgress = state.commentListProgress
        .filter((c) => c.id !== comment.id)
        .concat(comment);
    },
    DELETE_COMMENT_LIST_PROGRESS(state: CommentsState, comment: CommentInProgress) {
      state.commentListProgress = state.commentListProgress.filter((c) => c.id !== comment.id);
    },
    RESET_COMMENTS(state: CommentsState) {
      state.deliveryComments = null;
      state.commentListProgress = [];
    },
  },
  actions: {
    replyLoading: ({ state }, id: number) => state.commentListProgress
      .filter((c) => c.loading)
      .map((c) => c.id)
      .includes(id),
    replyError: ({ state }, id: number) => state.commentListProgress
      .filter((c) => c.error)
      .map((c) => c.id)
      .includes(id),
    deleteCommentInProgress({ commit }, id: number) {
      commit(DELETE_COMMENT_LIST_PROGRESS, { id, loading: false, error: false });
      EventBus.$emit(CommentPublishEvents.LOADING + id, false);
      EventBus.$emit(CommentPublishEvents.ERROR + id, false);
    },
    setCommentInProgressError({ commit }, id: number) {
      commit(SET_COMMENT_LIST_PROGRESS, { id, loading: false, error: true });
      EventBus.$emit(CommentPublishEvents.LOADING + id, false);
      EventBus.$emit(CommentPublishEvents.ERROR + id, true);
    },
    setCommentInProgress({ commit }, id: number) {
      commit(SET_COMMENT_LIST_PROGRESS, { id, loading: true, error: false });
      EventBus.$emit(CommentPublishEvents.LOADING + id, true);
    },
    setDeliveryComments({ commit }, deliveryComments: EntregaComentario) {
      commit(SET_DELIVERY_COMMENTS, deliveryComments);
    },
    resetCommentsDelivery({ commit }) {
      commit(RESET_COMMENTS);
    },
    getDeliveryComments({ commit, rootState }) {
      commit(RESET_COMMENTS);
      const { deliveryId } = rootState.publishedAssignment;
      return new Promise((resolve, reject) => {
        DeliveryService.getDeliveryComments(deliveryId)
          .then(
            (deliveryComments: DeliveryComment) => {
              commit(SET_DELIVERY_COMMENTS, deliveryComments);
              resolve(deliveryComments);
            },
          )
          .catch((err: AxiosError) => reject(new Error(err.message)));
      });
    },
    postCommentDelivery(
      { dispatch, rootState },
      [message, entregaComentarioId]: [string, number, number],
    ) {
      const { deliveryId } = rootState.publishedAssignment;
      const commentReply = DeliveryService.buildCommentReply(
        message,
        deliveryId,
        entregaComentarioId,
      );

      return new Promise((resolve, reject) => {
        DeliveryService.postDeliveryComment(commentReply)
          .then((data: CommentModel) => {
            resolve(data);
            if (entregaComentarioId) {
              dispatch('deleteCommentInProgress', entregaComentarioId);
            }
          })
          .catch((err: AxiosError) => {
            reject(new Error(err.message));
            if (entregaComentarioId) {
              dispatch('setCommentInProgressError', entregaComentarioId);
            }
          });
      });
    },
  },
};

export default comments;
