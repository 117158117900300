import { Area } from '../../models/area';
import { context } from './shared';

const { ADMIN_OBJECT_AREA } = Area;

const prefix = (area: Area) => context[area];

export const FilterEndpoints = {
  getFilters: (
    tId: number,
    cId: number,
    filter: string,
  ) => `${prefix(ADMIN_OBJECT_AREA)}/${tId}/cursos/${cId}/entregas/filtro?filtros=${filter}`,
};
