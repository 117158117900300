export class UploadDeArquivo extends File {
  id?: number;
  networking_trabalho_id?: number;
  nomeArquivo?: string;
  nome_arquivo?: string;
  src?: string;

  constructor(
    fileBits: BlobPart[],
    fileName: string,
    options?: FilePropertyBag | undefined,
    id?: number,
    src?: string,
  ) {
    super(fileBits, fileName, options);
    if (id) this.id = id;
    if (src) this.src = src;

    this.nomeArquivo = fileName;
  }
}
