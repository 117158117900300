// TODO: talvez pode ser apagado o arquivo, verificar

import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';
import { RouteName } from '@/models';

const { LOGIN } = RouteName;

export const Logout = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  store.dispatch('login/logout')
    .finally(() => next({ name: LOGIN }));
};
