export enum PillColor {
  BLUE = 'blue',
  DARK_BLUE = 'dark-blue',
  DARK_GRAY = 'dark-gray',
  DARK_GREEN = 'dark-green',
  DARK_RED = 'dark-red',
  DARK_YELLOW = 'dark-yellow',
  GRAY = 'gray',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}
