
import {
  Component,
  Vue,
  Prop,
  Model,
} from 'vue-property-decorator';

import { ButtonDefaultColors } from '@/models';

const { BLACK, BLUE, WHITE } = ButtonDefaultColors;

@Component({
  name: 'ButtonDefault',
})
export default class ButtonDefault extends Vue {
  @Prop({}) public msg!: string;
  @Prop({}) public preenchido!: boolean;
  @Prop({ default: WHITE }) color: ButtonDefaultColors;
  @Prop({ default: false }) public light!: boolean;
  @Model('change', { type: Boolean }) loading!: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '15px 30px' }) public padding!: number;
  @Prop({ default: false }) isLikeButton: boolean;

  public BLACK = BLACK;
  public BLUE = BLUE;
  public WHITE = WHITE;
}
