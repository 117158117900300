import { Module } from 'vuex';
import { Breakpoint } from '@/models';
import { HeaderDisplayCommits, HeaderDisplayState, RootState } from '@/models/store';

const { SET_HEADER_DISPLAY } = HeaderDisplayCommits;

const headerDisplay: Module<HeaderDisplayState, RootState> = {
  namespaced: true,
  state: {
    mobileViewPort: false,
  },
  getters: {
    mediumView: (state: HeaderDisplayState) => state.mobileViewPort,
    showFloatingHeaderItens: (state: HeaderDisplayState) => state.mobileViewPort,
    showActionHeaderItens: (state: HeaderDisplayState) => !state.mobileViewPort,
    smallView: (state: HeaderDisplayState) => state.smallView,
  },
  mutations: {
    SET_HEADER_DISPLAY(
      state: HeaderDisplayState,
      { mobileViewPort, smallView }: HeaderDisplayState,
    ) {
      state.mobileViewPort = mobileViewPort;
      state.smallView = smallView;
    },
  },
  actions: {
    checkWidthSize({ commit }) {
      commit(SET_HEADER_DISPLAY, {
        mobileViewPort: Boolean(window.innerWidth <= Breakpoint.MD),
        smallView: Boolean(window.innerWidth <= Breakpoint.SM),
      });
    },
  },
};

export default headerDisplay;
