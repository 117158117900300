import { AxiosResponse } from 'axios';

export const DownloadUtils = {
  filename(response: AxiosResponse<ArrayBuffer>) {
    const contentDisposition = response.headers['content-disposition'];
    const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
    return match[1];
  },
  download([file, filename]: [ArrayBuffer, string]) {
    const url = URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};
