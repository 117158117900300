import { EventBus } from '@/utils/EventBus';
import { ModalEventos, ModalDataTransfer, ModalTipos } from '@/models';

export const ModalService = {
  abrirModal({
    component,
    fechavel = true,
    props,
    dark = false,
    overlayDark = false,
  }: ModalDataTransfer) {
    EventBus.$emit(ModalEventos.ABRIR, {
      component,
      fechavel,
      props,
      dark,
      overlayDark,
    });
  },

  fecharModal() {
    EventBus.$emit(ModalEventos.FECHAR);
  },

  respostaModal<T = boolean>(tipo: ModalTipos, resposta: T, podeFechar = true) {
    EventBus.$emit(tipo, resposta);
    if (podeFechar) ModalService.fecharModal();
  },
};
