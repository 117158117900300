export class QuestaoResposta {
  trabalhoQuestaoId: number;
  id: number;
  resposta: string;

  constructor() {
    this.id = 0;
    this.resposta = '';
    this.trabalhoQuestaoId = 0;
  }
}
