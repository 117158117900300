import { MeusAssignments } from './api';
import { MyWork } from './my-work';

export class MyAssignments {
  courseId: number;
  courseName: string;
  assignments: MyWork[];

  constructor(meusAssignments: MeusAssignments) {
    this.courseId = meusAssignments.id;
    this.courseName = meusAssignments.nome_curso;
    this.assignments = meusAssignments.trabalhos.map((t) => new MyWork(t));
  }
}
