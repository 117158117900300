
import { Component, Prop, Vue } from 'vue-property-decorator';

import ButtonDefault from '@/components/shared/ButtonDefault.vue';

import { DataConfirmation, ModalTipos } from '@/models';

import { ModalService } from '@/services';

@Component({
  name: 'PublicationConfirm',
  components: {
    ButtonDefault,
  },
})
export default class ConfirmaPublicacao extends Vue {
  @Prop({}) public data: DataConfirmation;

  public respostaPublicacao(resposta: boolean) {
    const canClose = (this.data?.confirmEvent
      && this.data?.confirmEvent !== ModalTipos.CONFIRMACAO)
      ?? !resposta;

    ModalService.respostaModal(
      this.data.confirmEvent ?? ModalTipos.CONFIRMACAO,
      resposta,
      canClose,
    );
  }
}
