import { RouteConfig } from 'vue-router';
import { RouteName } from '@/models';
import { Logout } from '@/router/RouterActions';

const {
  LOGIN,
  LOADING,
  NOT_FOUND,
  FORBIDDEN,
  LOGOUT,
} = RouteName;

export const GeneralRoutes: RouteConfig[] = [
  { path: '/login', name: LOGIN, component: () => import('@/views/Login.vue') },
  // TODO: talvez pode ser apagado o LOGOUT, verifica se ainda está sendo utilizado
  { path: '/logout', name: LOGOUT, beforeEnter: Logout },
  { path: '/loading/:routename?', name: LOADING, component: () => import('@/views/Loading.vue') },
  { path: '/forbidden', name: FORBIDDEN, component: () => import('@/views/Forbidden.vue') },
  { path: '*', name: NOT_FOUND, component: () => import('@/views/NotFound.vue') },
];
