import { StatementData } from '@/models';
import { AssignmentMaterial } from '../assignment-material';
import { AssignmentObjectModel } from '../assignment-object';
import { DraftDelivery } from '../draft-delivery';
import { KeywordModel } from '../keyword';
import { QuestionModel } from '../question';
import { CommentModel } from '../comment';

export interface AssignmentState {
  workId: number;
  deliveryId: number;
  statement: StatementData;
  workToFill: AssignmentObjectModel;
  cover: string;
  coverDownload: string;
  title: string;
  answers: QuestionModel[];
  assignmentMaterials: AssignmentMaterial;
  agreeTerms: boolean;
  keywords: KeywordModel[];
  draft: DraftDelivery;
  videoIsRequired: boolean;
  attachmentIsRequired: boolean;
  addMaterialsOpen: boolean;
  saving: boolean;
  professorComments: CommentModel[];
  composingGrade: boolean;
}

export enum AssignmentCommits {
  SET_DOWNLOAD_COVER = 'SET_DOWNLOAD_COVER',
  SET_WORK_ID = 'SET_WORK_ID',
  SET_DELIVERY_ID = 'SET_DELIVERY_ID',
  SET_STATEMENT = 'SET_STATEMENT',
  SET_WORK_TO_FILL = 'SET_WORK_TO_FILL',
  SET_COVER = 'SET_COVER',
  SET_TITLE = 'SET_TITLE',
  SET_ANSWERS = 'SET_ANSWERS',
  SET_ASSIGNMENT_MATERIALS = 'SET_ASSIGNMENT_MATERIALS',
  SET_AGREE_TERMS = 'SET_AGREE_TERMS',
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_DRAFT = 'SET_DRAFT',
  SET_ADD_MATERIALS_OPEN = 'SET_ADD_MATERIALS_OPEN',
  SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES',
  SET_SAVING = 'SET_SAVING',
  SET_VIDEO_REQUIREMENT = 'SET_VIDEO_REQUIREMENT',
  SET_ATTACHMENT_REQUIREMENT = 'SET_ATTACHMENT_REQUIREMENT',
  SET_PROFESSOR_COMMENTS = 'SET_PROFESSOR_COMMENTS',
  SET_COMPOSING_GRADE = 'SET_COMPOSING_GRADE',

  RESET_DELIVERY_ID = 'RESET_DELIVERY_ID',
  RESET_AGREED_TERMS = 'RESET_AGREED_TERMS',
  RESET_VIDEO_REQUIREMENT = 'RESET_VIDEO_REQUIREMENT',
  RESET_ATTACHMENT_REQUIREMENT = 'RESET_ATTACHMENT_REQUIREMENT',
  RESET_STATEMENT = 'RESET_STATEMENT',
  RESET_ANSWERS = 'RESET_ANSWERS',
  RESET_ASSIGNMENT_MATERIALS = 'RESET_ASSIGNMENT_MATERIALS',
  RESET_KEYWORDS = 'RESET_KEYWORDS',
  RESET_WORK_TO_FILL = 'RESET_WORK_TO_FILL',
  RESET_DRAFT = 'RESET_DRAFT',
  RESET_DOWNLOAD_COVER = 'RESET_DOWNLOAD_COVER',
  RESET_COVER = 'RESET_COVER',
  RESET_TITLE = 'RESET_TITLE',
  RESET_PROFESSOR_COMMENTS = 'RESET_PROFESSOR_COMMENTS',
  RESET_COMPOSING_GRADE = 'RESET_COMPOSING_GRADE',
}
