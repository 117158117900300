import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';

export const DeliveriesRouterAction = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
) => {
  if (to.params) {
    const objectId = to.params.trabalhoId;
    const classId = to.params.turmaId;
    const searchKey = to.query ? to.query.search : '';
    const page = to.query ? to.query.page : null;
    const filter = to.query ? to.query.filter : null;

    await store.dispatch('assignmentObject/getAssignmentObject', objectId)
      .then(() => store.dispatch('deliveries/searchDeliveryList', {
        objectId, classId, searchKey, page, filter,
      }));
  }
  next();
};
