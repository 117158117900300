import { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import {
  AssignmentObjectModel,
  Delivery,
  MyAssignments,
} from '@/models';
import {
  MeusAssignments,
  UploadDeArquivo,
  ObjetoDeAssignment,
} from '@/models/api';

import { ApiService } from './ApiService';

const httpClient = ApiService;

export const AssignmentService = {
  getAssignmentToFill(id: number): Promise<AssignmentObjectModel> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.getAssignment(id));

      httpClient
        .get(url)
        .then((resp: AxiosResponse<ObjetoDeAssignment>) => resolve(
          new AssignmentObjectModel(resp.data),
        ))
        .catch((err: AxiosError) => reject(err));
    });
  },

  getDraftAssignment(id: number) {
    const url = buildApiUrl(ApiUrls.getAssignmentDraft(id));
    return httpClient.get(url);
  },

  publish(delivery: Delivery): Promise<AssignmentObjectModel> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.saveAssignment());
      const payload = this.buildSaveAssignmentPayload(delivery);

      httpClient
        .post(url, payload)
        .then((resp: AxiosResponse<ObjetoDeAssignment>) => resolve(
          new AssignmentObjectModel(resp.data),
        ))
        .catch((err: AxiosError) => reject(err));
    });
  },

  buildSaveAssignmentPayload(delivery: Delivery): FormData {
    const payload = new FormData();

    const id = delivery.id ?? 0;
    payload.append('id', id.toString());

    payload.append('trabalhoId', delivery.assignmentObjectId?.toString() || '');
    payload.append('publicar', delivery.publish.toString());
    payload.append('titulo', delivery.title);
    payload.append('respostas', JSON.stringify(delivery.answers));
    payload.append('palavras_chave', JSON.stringify(delivery.keywords));

    const capa = delivery.cover ?? '';
    payload.append('capa', capa);

    if (delivery.materials?.video) payload.append('video', delivery.materials?.video);

    const arquivos = delivery.materials?.files ?? [];
    payload.append('arquivos', JSON.stringify(arquivos));

    const arquivosNovos = delivery?.materials?.newFiles ?? [];
    arquivosNovos.forEach((file: UploadDeArquivo) => {
      payload.append('arquivosNovos[]', file);
    });

    return payload;
  },

  getMyAssignments(): Promise<MyAssignments[]> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.getMyAssignments());

      httpClient
        .get(url)
        .then((resp: AxiosResponse<MeusAssignments[]>) => resolve(
          resp.data.map((v) => new MyAssignments(v)),
        ))
        .catch((err: AxiosError) => reject(err));
    });
  },
};
