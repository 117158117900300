
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import Search from '@/assets/icons/Search.svg';
import Close from '@/assets/icons/Close.svg';

import Suggestor from '@/components/shared/Suggestor.vue';

import { SuggestorType } from '@/models';

@Component({
  name: 'SearchInput',
  components: {
    Search,
    Close,
    Suggestor,
  },
})
export default class SearchInput extends Vue {
  @Prop({ default: '' }) placeholder: string;
  @Prop() datalist: SuggestorType[];
  @Prop({ default: true }) hasRadius: boolean;
  @Prop({ default: false }) darkMode: boolean;
  @Prop({ default: false }) hasFocus: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop() data: SuggestorType;
  @Prop({ default: false }) noSearchIcon: boolean;
  @Prop({ default: false }) noClearIcon: boolean;
  @Prop({ default: false }) noSuggestor: boolean;
  @Prop({ default: false }) withId: boolean

  public inFocus = false;
  public showClearButton = Boolean(this.$router.currentRoute.query.search);
  public searchData = this.$router.currentRoute.query.search as string ?? '';

  mounted() {
    if (this.data?.id) this.updateValue(this.data);
  }

  @Watch('data')
  private updateValue(value: SuggestorType) {
    this.searchData = value.name ?? this.data?.name;

    if (this.searchData.length > 0) this.showClearButton = true;
  }

  get inputData(): string {
    return this.withId && this.showClearButton && this.data.id
      ? `${this.searchData.split(' — ')[0]} — ${this.data.id}`
      : this.searchData;
  }

  set inputData(data: string) {
    const [name] = data.split(' — ');
    this.searchData = name;
  }

  public handleInputBehavior() {
    this.showClearButton = Boolean(this.searchData.length > 0);
    this.emitSearchData(this.searchData);
  }

  public keyUpEnter() {
    this.$emit('search', this.searchData);
  }

  private emitSearchData(data: string | undefined) {
    this.$emit('change', data);
  }

  public clearInput() {
    this.showClearButton = false;
    this.updateValue(new SuggestorType());
    this.$emit('selected', {});
    this.$emit('change', '');
    this.$emit('search', '');
  }

  public populateInput(selection: SuggestorType, emit = false) {
    this.updateValue(selection);
    this.showClearButton = true;
    if (emit) this.$emit('selected', selection);
  }
}
