import { EntregaComentario } from '../api';

export interface CommentsState {
  deliveryComments: EntregaComentario | null;
  commentListProgress: CommentInProgress[];
}

export enum CommentsCommits {
  SET_DELIVERY_COMMENTS = 'SET_DELIVERY_COMMENTS',
  SET_COMMENT_LIST_PROGRESS = 'SET_COMMENT_LIST_PROGRESS',
  DELETE_COMMENT_LIST_PROGRESS = 'DELETE_COMMENT_LIST_PROGRESS',
  RESET_COMMENTS = 'RESET_COMMENTS',
}

export interface CommentInProgress {
  id: number;
  loading: boolean;
  error: boolean;
}
