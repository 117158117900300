
import { Component, Prop, Vue } from 'vue-property-decorator';

import { SuggestorType } from '@/models';

@Component({
  name: 'Suggestor',
})
export default class Suggestor extends Vue {
  @Prop() data!: SuggestorType[];

  public output(item: SuggestorType) {
    this.$emit('change', item);
  }
}
