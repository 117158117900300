import { QueryParamsModel } from '@/models/api';

import { Area } from '../../models/area';
import { context } from './shared';
import { QueryParamsUtils } from './QueryParams';

const { queryParamsBuilder } = QueryParamsUtils;
const { ADMIN_OBJECT_AREA, STUDENT_DELIVERY_AREA } = Area;

const prefix = (area: Area) => context[area];

export const DeliveryEndpoints = {
  getDeliveries: (
    tId: number,
    cId: number,
    termo?: string,
    page = 1,
    filtros?: string,
    // eslint-disable-next-line max-len
  ) => `${prefix(ADMIN_OBJECT_AREA)}/${tId}/cursos/${cId}/entregas${queryParamsBuilder({ page, termo, filtros } as QueryParamsModel)}`,
  saveDeliveryGrade: () => `${prefix(ADMIN_OBJECT_AREA)}/cursos/entregas`,
  editDeliveryGrade: (id: number) => `${prefix(ADMIN_OBJECT_AREA)}/entrega/comentario/${id}`,
  workDeliveryById: (id: number) => `aluno/trabalhoEntrega/${id}`,
  deliveryByDiscipline: (
    id: number,
    page = 1,
  ) => `${prefix(STUDENT_DELIVERY_AREA)}/porDisciplina/${id}/${page}`,
  likeDislike: (id: number) => `${prefix(STUDENT_DELIVERY_AREA)}/reacoes/${id}`,
  deliveryComments: (id: number) => `${prefix(STUDENT_DELIVERY_AREA)}/comentarios/${id}`,
  deleteDelivery: (id: number) => `${prefix(ADMIN_OBJECT_AREA)}/entrega/${id}`,
};
