export enum FloatingMenuEvents {
  DELETE = '@floatingMenu/DELETE',
  // TODO: evento não utilizado, possível deletar futuramente
  EDIT = '@floatingMenu/EDIT',
  EDIT_PROFILE = '@floatingMenu/EDIT_PROFILE',
  FLOATING_MENU_LOGOUT = '@floatingMenu/LOGOUT',
  CHECK = '@floatingMenu/CHECK',
  INPUT = '@floatingMenu/INPUT',
  SELECT = '@floatingMenu/SELECT',
  BUTTON_CLICKED = '@floatingMenu/BUTTON_CLICKED',
  OPEN = '@floatingMenu/OPEN',
  APPLY = '@floatingMenu/APPLY',
}
