import { Disciplina } from './api';

export class Discipline {
  id: number;
  name: string;

  constructor(disciplina?: Disciplina) {
    this.id = disciplina?.id ?? 0;
    this.name = disciplina?.nome || disciplina?.disciplina || '';
  }
}
