import { ProfileFormModelItem } from './profile-form-model-item';
import { UserModel } from './user';

type ProfileFormModelType = {
  [index in ProfileFormModelKey]: ProfileFormModelItem;
}

export class ProfileFormModel implements ProfileFormModelType {
  name: ProfileFormModelItem;
  nick: ProfileFormModelItem;
  work: ProfileFormModelItem;
  linkedin: ProfileFormModelItem;
  biography: ProfileFormModelItem;
  terms: ProfileFormModelItem;
  user_id: ProfileFormModelItem;

  constructor(user?: UserModel) {
    const user_id = user?.id ?? 0;
    this.user_id = new ProfileFormModelItem(true, user_id);

    const name = user?.name ?? '';
    this.name = new ProfileFormModelItem(true, name, !name);

    const nick = user?.nickname ?? '';
    this.nick = new ProfileFormModelItem(true, nick, !nick);

    const work = user?.company ?? '';
    this.work = new ProfileFormModelItem(true, work, !work);

    const linkedin = user?.linkedIn ?? '';
    this.linkedin = new ProfileFormModelItem(false, linkedin, !linkedin);

    const biography = user?.biography ?? '';
    this.biography = new ProfileFormModelItem(true, biography, !biography);

    const terms = user?.termsAccepted ?? false;
    this.terms = new ProfileFormModelItem(true, terms, !terms);
  }
}

export enum ProfileFormModelKey {
  name = 'name',
  nick = 'nick',
  work = 'work',
  linkedin = 'linkedin',
  biography = 'biography',
  terms = 'terms',
}
