import { Permissoes, TipoDeAcesso } from './api';
import { UserPermissions } from './user-permissions';

const { STUDENT } = TipoDeAcesso;

export class PermissionMeta {
  accessType: TipoDeAcesso;
  permissions: (UserPermissions | Permissoes)[];

  constructor(
    acessoTipo: TipoDeAcesso = STUDENT,
    permissioes: (UserPermissions | Permissoes)[] = [],
  ) {
    this.accessType = acessoTipo;
    this.permissions = permissioes;
  }
}
