export enum ModalTipos {
    CONFIRMACAO = 'CONFIRMACAO',
    CONFIRM_GRADE = 'CONFIRM_GRADE',
    LOADING = 'LOADING',
    OVERLAY = 'OVERLAY',
    INFORMATIVE = 'INFORMATIVE',
    TEXT = 'TEXT',
    SAVE_GRADE = 'SAVE_GRADE',
    FILTER_REPORT = 'FILTER_REPORT',
}
