import { DateUtils, EnumUtils } from '@/utils';

import { EntregaStatus, MinhaEntrega, Comentario } from './api';
import { DeliveryStatus } from './delivery-status';
import { CommentModel } from './comment';

const { NOT_STARTED } = DeliveryStatus;

export class MyDelivery {
  id: number | null;
  status: DeliveryStatus;
  grade: string | null;
  recoveryGrade: string | null;
  aditionalGrade: string | null;
  isInRecovery: boolean;
  publicationDate: string | null;
  deliveryDate: string | null;
  recoveryDate: string | null;
  aditionalDate: string | null;
  professorComments: CommentModel[];
  title: string | null;
  aditionalDueDate: string | null;

  constructor(minhaEntrega?: MinhaEntrega) {
    this.status = EnumUtils.fromFirstEnumToSecondEnum<typeof EntregaStatus, typeof DeliveryStatus>(
      minhaEntrega?.status as string,
      EntregaStatus,
      DeliveryStatus,
    ) as DeliveryStatus ?? NOT_STARTED;
    this.id = minhaEntrega?.id ?? null;
    this.title = minhaEntrega?.titulo ?? null;
    this.grade = minhaEntrega?.nota ?? null;
    this.recoveryGrade = minhaEntrega?.nota_recuperacao ?? null;
    this.aditionalGrade = minhaEntrega?.nota_adicional ?? null;
    this.publicationDate = minhaEntrega?.data_publicacao ?? null;
    this.deliveryDate = minhaEntrega?.data_entrega ?? null;
    this.recoveryDate = minhaEntrega?.data_entrega_recuperacao ?? null;
    this.aditionalDate = minhaEntrega?.data_entrega_adicional ?? null;
    this.professorComments = minhaEntrega?.comentario_do_professor.map((comentario: Comentario) => (
      new CommentModel(comentario)
    )) ?? [];
    this.isInRecovery = minhaEntrega?.recuperacao ?? false;
    this.aditionalDueDate = minhaEntrega?.data_adicional
      ? DateUtils.toDateWithHour(minhaEntrega?.data_adicional, '23:59:59')
      : null;
  }
}
