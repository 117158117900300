import { DateUtils } from '@/utils';
import { MeuTrabalho } from './api';
import { MyDelivery } from './my-delivery';

export class MyWork {
  id: number;
  disciplineName: string;
  dueDate: string;
  recoveryDueDate: string;
  statement: string;
  questions: string[];
  delivery: MyDelivery | null;
  compositionGrade: boolean;

  constructor(trabalho?: MeuTrabalho) {
    this.id = trabalho?.id || 0;
    this.disciplineName = trabalho?.nome_disciplina || '';
    this.dueDate = trabalho?.data_limite_entrega
      ? DateUtils.toDateWithHour(trabalho.data_limite_entrega, '23:59:59')
      : '';
    this.recoveryDueDate = trabalho?.data_limite_recuperacao
      ? DateUtils.toDateWithHour(trabalho.data_limite_recuperacao, '23:59:59')
      : '';
    this.statement = trabalho?.enunciado || '';
    this.questions = trabalho?.questoes || [];
    this.delivery = new MyDelivery(trabalho?.entrega ?? undefined);
    this.compositionGrade = trabalho?.composicao_nota ?? false;
  }
}
