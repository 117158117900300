import { RouteConfig } from 'vue-router';
import { RouteName } from '@/models';
import { AccessPermissions } from '../Metas';

const { IMPLEMENTACOES } = RouteName;

export const HiddenRoutes: RouteConfig[] = [
  {
    path: '/implementacoes',
    name: IMPLEMENTACOES,
    component: () => import('@/views/Implementacoes.vue'),
    meta: AccessPermissions.OnlyDev,
  },
];
