export const PathToValue = {
  getValue(path: string, data: object): string | number | boolean {
    const pathKeys = path.split('.');
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    let valueData: any = data;

    while (pathKeys.length) {
      valueData = valueData[pathKeys.shift() as keyof typeof valueData];
    }

    return valueData;
  },
};
