import { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { Turma } from '@/models/api';
import { Class, ClassesGetParams } from '@/models';
import { ApiService } from './ApiService';

const httpClient = ApiService;
export const ClassService = {
  getClasses(params: ClassesGetParams): Promise<Class[]> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.getClasses(params));

      httpClient
        .get(endpoint)
        .then((response: AxiosResponse<Turma[]>) => {
          if (!response || (response && !response.data)) reject(new Error('Ooops'));

          const classes = response.data.map((turma: Turma) => new Class(turma));
          resolve(classes);
        })
        .catch((err: AxiosError) => reject(err));
    });
  },
};
