export class DynamicTextareaItemModel {
  id: number;
  value: string;
  required: boolean;

  constructor(id = 0, value = '', required = true) {
    this.id = id;
    this.value = value;
    this.required = required;
  }
}
