import { RouteConfig } from 'vue-router';
import { RouteName } from '@/models';
import {
  FillAssignment,
  MyAssignmentsPage,
  PublishedAssignment,
  ProfilePage,
} from '@/router/RouterActions';
import { AccessPermissions, publishedAssignmentMeta } from '../Metas';

const {
  INICIO,
  PUBLISHED_ASSIGNMENT,
  ASSIGNMENT_DISCIPLINE_LIST,
  ASSIGNMENT_FILL,
  PENDING_ASSIGNMENTS,
  RESCTRICTED_ACCESS,
  COURSES,
  PROFILE,
} = RouteName;

export const StudentRoutes: RouteConfig[] = [
  {
    path: '/course/:cursoId',
    name: INICIO,
    component: () => import('@/views/AssignmentListByCourse.vue'),
    meta: AccessPermissions.StudentCourseSet,
  },
  {
    path: '/discipline/:disciplinaId',
    name: ASSIGNMENT_DISCIPLINE_LIST,
    component: () => import('@/views/AssignmentListByDiscipline.vue'),
    meta: AccessPermissions.StudentCourseSet,
  },
  {
    path: '/assignment/:entregaId',
    name: PUBLISHED_ASSIGNMENT,
    component: () => import('@/views/PublishedAssignment.vue'),
    beforeEnter: PublishedAssignment,
    meta: {
      ...publishedAssignmentMeta,
      ...AccessPermissions.Student,
    },
  },
  {
    path: '/assignment/:trabalhoId/fill',
    name: ASSIGNMENT_FILL,
    component: () => import('@/views/Assignment.vue'),
    beforeEnter: FillAssignment,
    meta: {
      ...AccessPermissions.StudentWithAssignment,
    },
  },
  {
    path: '/my-assignments',
    name: PENDING_ASSIGNMENTS,
    beforeEnter: MyAssignmentsPage,
    component: () => import('@/views/MyAssignmentsPage.vue'),
    meta: AccessPermissions.StudentNoPublished,
  },
  {
    path: '/profile',
    name: PROFILE,
    component: () => import('@/views/ProfilePage.vue'),
    beforeEnter: ProfilePage,
    meta: AccessPermissions.StudentNoPublished,
  },
  {
    path: '/restricted-access',
    name: RESCTRICTED_ACCESS,
    component: () => import('@/views/RestrictedAccess.vue'),
    meta: AccessPermissions.StudentNoPublished,
  },
  {
    path: '/courses',
    name: COURSES,
    component: () => import('@/views/Courses.vue'),
    meta: AccessPermissions.StudentMultipleCoursesNoPublished,
  },
];
