export class Disciplina {
  id: number;
  disciplina?: string;
  nome?: string;

  constructor(id?: number, nomeDisciplina?: string) {
    this.id = id ?? 0;
    this.disciplina = nomeDisciplina ?? '';
    this.nome = nomeDisciplina ?? '';
  }
}
