
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import ConfirmaPublicacao from '@/components/Modal/ModalTypes/PublicationConfirm.vue';
import LoadingModal from '@/components/Modal/ModalTypes/LoadingModal.vue';
import InformativeModal from '@/components/Modal/ModalTypes/Informative.vue';
import TextModal from '@/components/Modal/ModalTypes/Text.vue';

import { ModalTipos } from '@/models';

import LoadingOverlay from './ModalTypes/LoadingOverlay.vue';

const {
  CONFIRMACAO,
  LOADING,
  INFORMATIVE,
  TEXT,
  OVERLAY,
  CONFIRM_GRADE,
  FILTER_REPORT,
} = ModalTipos;

const modais: {
  [index: string]: VueConstructor;
} = {
  [CONFIRMACAO]: ConfirmaPublicacao,
  [CONFIRM_GRADE]: ConfirmaPublicacao,
  [LOADING]: LoadingModal,
  [OVERLAY]: LoadingOverlay,
  [INFORMATIVE]: InformativeModal,
  [TEXT]: TextModal,
  [FILTER_REPORT]: ConfirmaPublicacao,
};

@Component({
  name: 'ModalFactory',
})
export default class ModalFactory extends Vue {
  public static construirModal(tipo: ModalTipos): VueConstructor {
    return modais[tipo];
  }
}
