import { AxiosResponse } from 'axios';
import { QueryParamUrl, CoverList } from '@/models';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const CoverListService = {
  getCoverList(term?: string, page?: number): Promise<AxiosResponse<CoverList[]>> {
    const queryParams: QueryParamUrl[] = [
      ...(page ? [{ pagina: page }] : []),
      ...(term ? [{ termo: term }] : []),
    ];

    const url = buildApiUrl(ApiUrls.getCovers(), queryParams);
    return httpClient.get(url);
  },
};
