import { PillColor } from './pill-color';

export enum DeliveryStatusColor {
  AWAITING_EVALUATION = PillColor.DARK_GRAY,
  PENDING_STUDENT_CORRECTION = PillColor.DARK_YELLOW,
  IN_RECOVERY = PillColor.RED,
  EXPIRED = PillColor.DARK_GRAY,
  NOT_STARTED = PillColor.DARK_GRAY,
  PUBLISHED = PillColor.BLUE,
  DRAFT = PillColor.GREEN,
}
