export class ProfileFormModelItem {
  value: string | boolean | number;
  required: boolean;
  notFilled?: boolean;

  constructor(required: boolean, value?: string | boolean | number, notFilled?: boolean) {
    this.required = required;
    this.value = value ?? '';
    this.notFilled = notFilled ?? false;
  }
}
