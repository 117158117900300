import { Comentario } from './api';
import { UserModel } from './user';

export class CommentModel {
  id: number;
  deliveryId: number;
  userId: number;
  comment: string;
  anonimous: number;
  deliveryCommentId?: number;
  moderated: number;
  createdAt: string;
  updatedAt: string;
  user: UserModel;
  answers?: CommentModel[];
  publicationDate?: Date;
  updatedDate?: Date;
  edited?: boolean;

  constructor(comentario?: Comentario) {
    this.id = comentario?.id || 0;
    this.deliveryId = comentario?.networking_entrega_id || 0;
    this.userId = comentario?.usuario_id || 0;
    this.comment = comentario?.comentario || '';
    this.anonimous = comentario?.anonimo || 0;
    this.deliveryCommentId = comentario?.networking_entregas_comentarios_id || 0;
    this.moderated = comentario?.moderado || 0;
    this.createdAt = comentario?.created_at || '';
    this.updatedAt = comentario?.updated_at || '';
    this.user = new UserModel(comentario?.perfil, comentario?.usuario) || new UserModel();
    this.answers = comentario?.respostas
      ?.map((resposta: Comentario) => new CommentModel(resposta)) || [];
    this.publicationDate = new Date(comentario?.data_publicacao || 0);
    this.updatedDate = new Date(comentario?.data_atualizacao || 0);
    this.edited = Boolean(comentario?.editado);
  }
}
