import { PalavraChave } from './api';
import { ChipType } from './chip-type';

export class KeywordModel {
  id?: number;
  assignmentObjectId?: number;
  deliveryId?: number;
  keyword: string;

  constructor(palavraChave?: PalavraChave | ChipType) {
    this.id = palavraChave?.id as number || 0;
    this.assignmentObjectId = (palavraChave as PalavraChave)?.trabalhoId || 0;
    this.deliveryId = (palavraChave as PalavraChave)?.entregaId || 0;
    this.keyword = (palavraChave as PalavraChave)?.palavra_chave
      || (palavraChave as ChipType)?.name
      || '';
  }
}
