import { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { Disciplina } from '@/models/api';
import { Discipline } from '@/models/discipline';
import { SearchDisciplineParams } from '@/models';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const DisciplineService = {
  searchDiscipline(params: SearchDisciplineParams): Promise<Discipline[]> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.getDisciplines(params));

      httpClient
        .get(endpoint)
        .then((response: AxiosResponse<Disciplina[]>) => {
          if (!response.data) reject(new Error('Ooops'));

          const disciplines = response.data
            .map((disciplina: Disciplina) => new Discipline(disciplina));
          resolve(disciplines);
        })
        .catch((err: AxiosError) => reject(err));
    });
  },
};
