import { Course } from '../course';
import { UserPermissions } from '../user-permissions';
import { Permissoes, TipoDeAcesso } from '../api';

export interface UserState {
  id: number;
  name: string;
  firstName: string;
  nickname: string;
  company: string;
  linkedIn: string;
  biography: string;
  profilePhoto: string;
  firstAccess: boolean;
  accessType: TipoDeAcesso;
  permissions: (UserPermissions | Permissoes)[];
  courses: Course[];
  courseId: number;
  classId: number | undefined;
  disciplineId: number;
  updating?: boolean;
  termsAccepted: boolean;
}

export enum UserCommits {
  RESET_USER_DATA = 'RESET_USER_DATA',
  SET_ACCESS_TYPE = 'SET_ACCESS_TYPE',
  SET_COURSE = 'SET_COURSE',
  SET_CLASS = 'SET_CLASS',
  SET_DISCIPLINE = 'SET_DISCIPLINE',
  SET_FIRST_ACCESS = 'SET_FIRST_ACCESS',
  SET_UPDATE = 'SET_UPDATE',
  SET_USER = 'SET_USER',
}
