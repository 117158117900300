import { AxiosResponse } from 'axios';
import { VideoUrls } from '@/utils/endpoints';
import { ignoreToken } from '@/utils';
import { YoutubeVideoDetails, VimeoVideoDetails } from '@/models/api';
import { VideoSource, VideoUrlValidate } from '@/models';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const VideoService = {
  getVideoDetails(urlApiValidated: string):
    Promise<AxiosResponse<YoutubeVideoDetails | VimeoVideoDetails>> {
    return httpClient.get(urlApiValidated, ignoreToken);
  },

  getUrlIframe(infos: VideoUrlValidate): string {
    const id = this.extractIdFromUrl(infos);
    const iframe = {
      [VideoSource.VIMEO]: VideoUrls.vimeoIframe,
      [VideoSource.YOUTUBE]: VideoUrls.youtubeIframe,
    };

    if (infos.source !== VideoSource.INVALID && infos.url) {
      return iframe[infos.source](id);
    }

    return VideoSource.INVALID;
  },

  extractIdFromUrl(infos: VideoUrlValidate): string {
    const extract = {
      [VideoSource.VIMEO]: this.extractVimeoId,
      [VideoSource.YOUTUBE]: this.extractYoutubeId,
    };

    if (infos.source !== VideoSource.INVALID && infos.url) {
      return extract[infos.source](infos.url);
    }

    return VideoSource.INVALID;
  },

  extractVimeoId(url: string): string {
    const [, videoId] = url.split(/.com\/|\?|&/);
    return videoId;
  },

  extractYoutubeId(url: string): string {
    const [, videoId] = url.split(/\?v=|.be\/|\?|&/);
    return videoId;
  },

  youtubeUrlInfos(url: string, onlyInfo = false) {
    // eslint-disable-next-line max-len
    const regExp = /^((https?:)?(?:(?:\/\/((?:www\.)?youtube\.com|youtu\.be)\/.*?(?:watch\?v=|embed\/)?)))?([\w-]{11})/;
    if (onlyInfo) return url.match(regExp);
    return (url.match(regExp)) ? RegExp.$4 : false;
  },

  validateVideoUrl(url: string): VideoUrlValidate {
    if (!url) return { source: VideoSource.INVALID };

    const youtubeUrl = url.includes('youtu');
    const vimeoUrl = url.includes('vimeo');

    let urlApi = null;

    let source = VideoSource.INVALID;

    if (youtubeUrl && !vimeoUrl) {
      urlApi = VideoUrls.youtube(url);
      source = VideoSource.YOUTUBE;
    }

    if (vimeoUrl && !youtubeUrl) {
      urlApi = VideoUrls.vimeo(url);
      source = VideoSource.VIMEO;
    }

    return {
      source,
      ...urlApi && { urlApi },
      ...(youtubeUrl || vimeoUrl) && { url },
    };
  },
};
