interface DateData {
  day: number;
  month: number;
  year: number;
  daysOffset: number;
}

export const DateUtils = {
  formatDate(rawData: string, offset: number, check = true): string {
    const cleanRawData = rawData.replace(/\//g, '');

    const rawDay = Number(cleanRawData.substring(0, 2));
    const rawMonth = Number(cleanRawData.substring(2, 4));
    const rawYear = Number(cleanRawData.substring(4));

    const dateData: DateData = {
      day: rawDay,
      month: rawMonth,
      year: rawYear,
      daysOffset: offset,
    };

    const { day, month, year } = check ? this.checkValidDate(dateData) : dateData;

    const fullDate = (`0${day}`).slice(-2) + (`0${month}`).slice(-2) + year;

    return fullDate;
  },

  checkValidDate({
    day,
    month,
    year,
    daysOffset,
  }: DateData): DateData {
    const minDate = this.setMinDate(daysOffset);
    const minDay = minDate.getDate();
    const minMonth = minDate.getMonth() + 1;
    const minYear = minDate.getFullYear();
    let uncheckedDay = day;
    let uncheckedMonth = month;
    let uncheckedYear = year;

    if (uncheckedYear < minYear) uncheckedYear = minYear;
    if (uncheckedMonth === 0) uncheckedMonth = 1;
    if (uncheckedMonth > 12) uncheckedMonth = 12;
    if (uncheckedDay === 0) uncheckedDay = 1;

    if (uncheckedDay > 30) {
      if (uncheckedMonth > 7) {
        uncheckedDay = uncheckedMonth % 2 ? 30 : 31;
      } else {
        uncheckedDay = uncheckedMonth % 2 ? 31 : 30;
      }
    }

    if (uncheckedMonth === 2 && uncheckedDay > 28) {
      uncheckedDay = year % 4 ? 28 : 29;
    }

    if (year === minYear) {
      if (uncheckedMonth < minMonth) {
        uncheckedMonth = minMonth;
        uncheckedDay = minDay;
      }

      if (uncheckedMonth === minMonth && uncheckedDay < minDay) uncheckedDay = minDay;
    }

    const checkedDate: DateData = {
      day: uncheckedDay,
      month: uncheckedMonth,
      year: uncheckedYear,
      daysOffset,
    };

    return checkedDate;
  },

  setMinDate(daysOffset: number): Date {
    const today = new Date(Date.now());

    return new Date(today.setDate(today.getDate() + daysOffset));
  },

  checkExpiredDate(dateToCheck: string | Date | undefined | null, targetDate?: Date): boolean {
    if (!dateToCheck) return false;
    const check = typeof dateToCheck === 'string'
      && Number.isNaN(new Date(dateToCheck).valueOf())
      ? dateToCheck
      : DateUtils.toString(dateToCheck);

    const today = new Date(Date.now());

    const formated = check.length > 8;

    const arrayDate = check.split(/\//);
    const day = formated ? arrayDate[0] : check.substring(0, 2);
    const month = formated ? arrayDate[1] : check.substring(2, 4);
    const year = formated ? arrayDate[2] : check.substring(4);

    const formatedForTest = `${month}/${day}/${year} 23:59:59`;

    const target = targetDate && targetDate > today ? targetDate : today;

    return new Date(formatedForTest) < target;
  },

  elapsedTime(date: Date): string {
    if (date > new Date(Date.now())) return '';

    const today = new Date(Date.now());
    const difference = new Date(today.getTime() - date.getTime());
    const years = difference.getUTCFullYear() - 1970;
    let months = difference.getUTCMonth();
    const days = difference.getUTCDate() - 1;
    const weeks = Math.floor(days / 7);
    const hours = difference.getUTCHours();
    const minutes = difference.getUTCMinutes();
    let elapsed = ' ';

    switch (date.getMonth() + 1) {
      case 2:
        if ((date.getFullYear() % 4) === 0 && days >= 29) months = 1;
        else if (days >= 28) months = 1;
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        if (days >= 30) months = 1;
        break;
      default:
        if (days >= 31) months = 1;
        break;
    }

    if (years) elapsed += years > 1 ? `${years} anos` : `${years} ano`;
    else if (months) elapsed += months > 1 ? `${months} meses` : `${months} mês`;
    else if (weeks) elapsed += weeks > 1 ? `${weeks} semanas` : `${weeks} semana`;
    else if (days) elapsed += days > 1 ? `${days} dias` : `${days} dia`;
    else if (hours) elapsed += hours > 1 ? `${hours} horas` : `${hours} hora`;
    else elapsed += minutes > 1 ? `${minutes} minutos` : `${minutes} minuto`;

    elapsed += ' atrás';
    return elapsed;
  },

  toString(date: string | Date): string {
    return new Date(date).toLocaleDateString('pt-BR');
    // return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    // return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  },

  toAPIDateString(date?: Date): string {
    if (!date) return '';

    const ptString = this.toString(date);
    const toISO = this.toISODateString(ptString, false);
    const isoDate = toISO.split('T').shift() as string;

    return isoDate;
  },

  stringElapsed(date: Date): string {
    return `${this.toString(date)} ${this.elapsedTime(date)}`;
  },

  toDateWithHour(date: string, hour = '00:00:00'): string {
    return `${date.split(/T| /).shift()} ${hour}`;
  },

  toISODateString(date: string, format = true): string {
    const cleanDate = this.formatDate(date, 0, format);
    const day = cleanDate.substring(0, 2);
    const month = cleanDate.substring(2, 4);
    const year = cleanDate.substring(4);

    return `${year}-${month}-${day}T00:00`;
  },
};
