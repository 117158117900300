import { Module } from 'vuex';
import {
  PublishedAssignmentCommits,
  PublishedAssignmentState,
  RootState,
} from '@/models/store';
import { DeliveryService } from '@/services';
import { EntregaPublicada, Questao, TrabalhoComEntregaPublicada } from '@/models/api';
import {
  BreadcrumbModel,
  CoverDataList,
  Reaction,
  Student,
} from '@/models';

const {
  SET_BREADCRUMBS,
  SET_DELIVERY_ID,
  SET_CAROUSEL,
  SET_DELIVERY,
  SET_WORK_QUESTIONS,
  SET_LIKE_REQUEST,
  SET_PROFILE_DATA,
  SET_REACTION,
  RESET_ASSIGNMENT_TO_VIEW,
} = PublishedAssignmentCommits;

let accessCountDebounce = 0;

const publishedAssignment: Module<PublishedAssignmentState, RootState> = {
  namespaced: true,
  state: {
    breadcrumbs: new BreadcrumbModel(),
    deliveryId: 0,
    delivery: new EntregaPublicada(),
    likeRequesting: false,
    coversList: new CoverDataList(),
    profileData: new Student(),
    workQuestions: [],
    reaction: new Reaction(),
  },
  getters: {
    breadcrumbs: ({ breadcrumbs }: PublishedAssignmentState) => breadcrumbs,
    deliveryId: (state: PublishedAssignmentState) => state.deliveryId,
    delivery: (state: PublishedAssignmentState) => state.delivery,
    questions: (state: PublishedAssignmentState) => state.workQuestions,
    reaction: (state: PublishedAssignmentState) => state.reaction,
    profileData: (state: PublishedAssignmentState) => state.profileData,
    coversList: (state: PublishedAssignmentState) => state.coversList,
    liked: (state: PublishedAssignmentState) => state.reaction.liked,
    requesting: (state: PublishedAssignmentState) => state.likeRequesting,
  },
  mutations: {
    SET_BREADCRUMBS(state: PublishedAssignmentState, breadcrumb: BreadcrumbModel) {
      state.breadcrumbs = breadcrumb;
    },
    SET_DELIVERY_ID(state: PublishedAssignmentState, id: number) {
      state.deliveryId = id;
      state.delivery.id = id;
    },
    SET_DELIVERY(state: PublishedAssignmentState, delivery: EntregaPublicada) {
      state.delivery = delivery;
      state.deliveryId = delivery.id;
    },
    SET_CAROUSEL(state: PublishedAssignmentState, coversList: CoverDataList) {
      state.coversList = coversList;
    },
    SET_LIKE(state: PublishedAssignmentState, like: boolean) {
      state.reaction.liked = like;
    },
    SET_WORK_QUESTIONS(state: PublishedAssignmentState, questions: Questao[]) {
      state.workQuestions = questions;
    },
    SET_LIKE_REQUEST(state: PublishedAssignmentState, likeRequest: boolean) {
      state.likeRequesting = likeRequest;
    },
    SET_PROFILE_DATA(state: PublishedAssignmentState, profileData: Student) {
      state.profileData = profileData;
    },
    SET_REACTION(state: PublishedAssignmentState, reaction: Reaction) {
      state.reaction = reaction;
    },
    RESET_ASSIGNMENT_TO_VIEW(state: PublishedAssignmentState) {
      state.deliveryId = 0;
      state.delivery = new EntregaPublicada();
      state.likeRequesting = false;
      state.coversList = new CoverDataList();
      state.profileData = new Student();
      state.workQuestions = [];
      state.reaction = new Reaction();
    },
  },
  actions: {
    getAssignmentToView({
      commit,
      dispatch,
      rootState,
      rootGetters,
    }, deliveryId: number) {
      commit(RESET_ASSIGNMENT_TO_VIEW);
      commit(SET_DELIVERY_ID, deliveryId);
      dispatch('comments/getDeliveryComments', null, { root: true });

      DeliveryService.getWorkDeliveryById(deliveryId)
        .then((response: TrabalhoComEntregaPublicada) => {
          const { entrega } = response;

          commit(SET_BREADCRUMBS, new BreadcrumbModel(entrega.caminhoEntrega));
          commit(SET_DELIVERY, entrega);
          commit(SET_PROFILE_DATA, new Student(entrega.usuario));
          commit(SET_REACTION, new Reaction(entrega.reacoes));
          commit(SET_WORK_QUESTIONS, response.questoes);

          const disciplineId = entrega.caminhoEntrega.disciplina.id;
          const userId = rootState.user.id;
          const courseId = entrega.caminhoEntrega?.curso?.id;

          const hasCourseSet = rootGetters['user/hasCourseSet'];
          if (
            !hasCourseSet
            && Boolean(courseId)
          ) dispatch('user/setCourseId', courseId, { root: true });

          dispatch('getDeliveriesByDiscipline', disciplineId);
          dispatch('countAccess', [response.entrega.id, userId]);
        });
    },
    countAccess(ActionContext, [deliveryId, userId]: [number, number]) {
      clearTimeout(accessCountDebounce);

      accessCountDebounce = window.setTimeout(() => {
        DeliveryService.countAccess(deliveryId, userId);
      }, 10000);
    },
    getDeliveriesByDiscipline({ commit, state }, disciplineId: number) {
      const { deliveryId } = state;

      DeliveryService.getDeliveryByDiscipline(disciplineId)
        .then((response: CoverDataList) => {
          const carousel: CoverDataList = {
            ...response,
            deliveries: response.deliveries.filter((c) => c.id !== deliveryId),
          };

          commit(SET_CAROUSEL, carousel);
        });
    },
    likeOrUnlike({ state, commit }) {
      if (!state.deliveryId) return;

      commit(SET_LIKE_REQUEST, true);

      DeliveryService.likeOrUnlikeDelivery(state.deliveryId, state.reaction)
        .then((response: Reaction) => {
          commit(SET_REACTION, response);
        })
        .finally(() => {
          commit(SET_LIKE_REQUEST, false);
        });
    },
  },
};

export default publishedAssignment;
