import { AxiosError, AxiosResponse } from 'axios';
import {
  CoverDataList,
  CommentReply,
  DeliveryComment,
  CommentModel,
  Reaction,
  Delivery,
} from '@/models';
import {
  EntregaComentario,
  EntregaComentarios,
  TrabalhoComEntregaPublicada,
  RespostaGenerica,
  EntregaPorDisciplina,
  ReacaoPost,
  Reacao,
  Entrega,
  Comentario,
  Pagination,
} from '@/models/api';
import { DeliveryState } from '@/models/store';
import { DateUtils, FilterUtils } from '@/utils';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';

import { ApiService } from './ApiService';

const httpClient = ApiService;

const buildPayload = ({
  id,
  profile,
  ...delivery
}: DeliveryState): Entrega => {
  const entrega: Entrega = {
    id,
    nota: delivery.grade,
    nota_recuperacao: delivery.recoveryGrade,
    nota_adicional: delivery.aditionalGrade,
    data_adicional: DateUtils.toAPIDateString(delivery.aditionalDueDate as Date),
    comentario: delivery.comment,
    observacao: delivery.observation,
    usuario_id: profile?.id,
    trabalho_id: delivery.assignmentObjectId,
  };

  return entrega;
};

export const DeliveryService = {
  getDeliveriesByObjectCourse(
    trabId: number,
    courseId: number,
    searchString?: string,
    page?: number,
    filter?: string,
  ): Promise<Pagination<Delivery>> {
    return new Promise((resolve, reject) => {
      const deliveryListEndpoint = buildApiUrl(
        ApiUrls.getDeliveries(
          trabId,
          courseId,
          searchString,
          page,
          filter?.length ? encodeURIComponent(FilterUtils.networkingToRequest(filter)) : undefined),
      );

      httpClient
        .get(deliveryListEndpoint)
        .then((resp: AxiosResponse<Pagination<Entrega>>) => {
          const paginatedDeliveries: Pagination<Delivery> = {
            ...resp.data,
            data: resp.data.data?.map((entrega: Entrega) => new Delivery(entrega)),
            filtros: resp.data.filtros?.length
              ? FilterUtils.requestToNetworking(resp.data.filtros)
              : undefined,
          };
          resolve(paginatedDeliveries);
        })
        .catch((err: AxiosError) => reject(err));
    });
  },

  editDeliveryGrade(data: DeliveryState): Promise<CommentModel> {
    return new Promise((resolve, reject) => {
      if (!(data && data.commentIdToEdit)) return;
      const payload = buildPayload(data);
      const endpoint = buildApiUrl(ApiUrls.editDeliveryGrade(data.commentIdToEdit));

      httpClient
        .put(endpoint, payload)
        .then((resp: AxiosResponse<Comentario>) => resolve(new CommentModel(resp.data)))
        .catch((err: AxiosError) => reject(new Error(err.message)));
    });
  },

  setDeliveryGrade(data: DeliveryState): Promise<Delivery> {
    return new Promise((resolve, reject) => {
      const payload = buildPayload(data);
      const endpoint = buildApiUrl(ApiUrls.saveDeliveryGrade());

      httpClient
        .post(endpoint, payload)
        .then((resp: AxiosResponse<Entrega>) => resolve(new Delivery(resp.data)))
        .catch((err: AxiosError) => reject(err));
    });
  },

  getWorkDeliveryById(deliveryId: number): Promise<TrabalhoComEntregaPublicada> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.workDeliveryById(deliveryId));

      httpClient.get(endpoint)
        .then((res: AxiosResponse<TrabalhoComEntregaPublicada>) => resolve(res.data))
        .catch((err: AxiosError) => reject(err));
    });
  },

  getDeliveryByDiscipline(id: number, page?: number): Promise<CoverDataList> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.deliveryByDiscipline(id, page));

      httpClient.get(endpoint)
        .then(({ data }: AxiosResponse<EntregaPorDisciplina>) => {
          resolve(new CoverDataList(data));
        })
        .catch((err: AxiosError) => reject(err));
    });
  },

  countAccess(id: number, userId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.countAccess(id, userId));

      httpClient.get(endpoint)
        .then((res: AxiosResponse<RespostaGenerica>) => resolve(res?.data?.status))
        .catch((err: AxiosError) => reject(err));
    });
  },

  likeOrUnlikeDelivery(id: number, reaction: Reaction): Promise<Reaction> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.likeDislike(id));

      const payload = new ReacaoPost(reaction);

      httpClient.post(endpoint, payload)
        .then((resp: AxiosResponse<Reacao>) => resolve(new Reaction(resp.data)))
        .catch((err: AxiosError) => reject(err));
    });
  },

  getDeliveryComments(deliveryId: number): Promise<DeliveryComment> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.deliveryComments(deliveryId));

      httpClient.get(url)
        .then((response: AxiosResponse<EntregaComentario>) => (
          resolve(new DeliveryComment(response.data))
        ))
        .catch((err: AxiosError) => reject(new Error(err.message)));
    });
  },

  postDeliveryComment(commentReply: CommentReply): Promise<CommentModel> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.deliveryComments(commentReply.entregaId));

      httpClient.post(url, commentReply)
        .then((resp: AxiosResponse<EntregaComentarios>) => resolve(
          new CommentModel(resp.data.entregaComentario),
        ))
        .catch((err: AxiosError) => reject(err));
    });
  },

  buildCommentReply(message: string, deliveryId: number, entregaComentarioId?: number) {
    return {
      entregaId: deliveryId,
      comentario: message,
      ...(entregaComentarioId) && { entregaComentarioId },
      anonimo: 0,
      moderado: 0,
    };
  },

  deleteDelivery(id: number): Promise<DeliveryState> {
    return new Promise((resolve, reject) => {
      const url = buildApiUrl(ApiUrls.deleteDelivery(id as number));

      httpClient.delete(url)
        .then((resp: AxiosResponse<Entrega>) => {
          const newDelivery = new DeliveryState(resp.data);
          resolve(newDelivery);
        })
        .catch((err: AxiosError) => reject(err));
    });
  },
};
