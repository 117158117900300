import { DateUtils, EnumUtils, GradeUtils } from '@/utils';

import {
  Comentario,
  Entrega,
  EntregaStatus,
  PalavraChave,
  QuestaoResposta,
  UploadDeArquivo,
} from './api';
import { AssignmentMaterial } from './assignment-material';
import { CommentModel } from './comment';
import { DeliveryStatus } from './delivery-status';
import { KeywordModel } from './keyword';
import { QuestionModel } from './question';
import { UserModel } from './user';

const { NAO_INICIADO, EXPIRADO } = EntregaStatus;

export class Delivery {
  id?: number;
  assignmentObjectId?: number;
  cover: string;
  title: string;
  answers?: QuestionModel[];
  keywords?: KeywordModel[];
  materials?: AssignmentMaterial;
  publish: boolean;
  status?: DeliveryStatus;
  previousStatus?: DeliveryStatus;
  profile?: UserModel;
  video?: string;
  grade?: number;
  recoveryGrade?: number;
  aditionalGrade?: number;
  conceptGrade?: string;
  conceptRecoveryGrade?: string;
  conceptAdictionalGrade?: string;
  minimumGrade?: number;
  dueDate?: Date;
  recoveryDueDate?: Date;
  aditionalDueDate?: Date;
  deliveryDate?: Date;
  recoveryDeliveryDate?: Date;
  aditionalDeliveryDate?: Date;
  comments?: CommentModel[];
  observation?: string;
  publicationDate?: Date;
  comment?: string;
  numberOfFeedbacks?: number;

  constructor(entrega?: Entrega) {
    this.id = entrega?.id;
    this.assignmentObjectId = entrega?.trabalhoId ?? entrega?.trabalho_id ?? 0;
    this.cover = entrega?.capa ?? '';
    this.title = entrega?.titulo ?? '';
    this.answers = (entrega && entrega.respostas)
      ? entrega.respostas.map((resposta: QuestaoResposta) => new QuestionModel(resposta))
      : [new QuestionModel()];
    this.keywords = entrega?.palavras_chaves?.map((palavra_chave: PalavraChave) => (
      new KeywordModel(palavra_chave)))
      ?? [new KeywordModel()];
    this.materials = new AssignmentMaterial((entrega?.materiais?.files as UploadDeArquivo[]));
    this.publish = entrega?.publicar ?? false;
    this.previousStatus = EnumUtils
      .fromFirstEnumToSecondEnum<typeof EntregaStatus, typeof DeliveryStatus>(
        entrega?.status_anterior || NAO_INICIADO,
        EntregaStatus,
        DeliveryStatus,
      ) as DeliveryStatus;
    this.profile = new UserModel(entrega?.perfil, entrega?.usuario);
    this.video = entrega?.video;
    this.grade = entrega?.nota;
    this.recoveryGrade = entrega?.nota_recuperacao;
    this.aditionalGrade = entrega?.nota_adicional;
    this.dueDate = entrega?.data_limite_entrega
      ? new Date(DateUtils.toDateWithHour(entrega?.data_limite_entrega, '23:59:59'))
      : undefined;
    this.recoveryDueDate = entrega?.data_limite_recuperacao
      ? new Date(DateUtils.toDateWithHour(entrega?.data_limite_recuperacao, '23:59:59'))
      : undefined;
    this.aditionalDueDate = entrega?.data_adicional
      ? new Date(DateUtils.toDateWithHour(entrega?.data_adicional, '23:59:59'))
      : undefined;
    this.deliveryDate = entrega?.data_entrega
      ? new Date(entrega?.data_entrega)
      : undefined;
    this.recoveryDeliveryDate = entrega?.data_entrega_recuperacao
      ? new Date(entrega?.data_entrega_recuperacao)
      : undefined;
    this.aditionalDeliveryDate = entrega?.data_entrega_adicional
      ? new Date(entrega?.data_entrega_adicional)
      : undefined;
    this.comments = entrega?.comentarios?.map((comentario: Comentario) => (
      new CommentModel(comentario)));
    this.observation = entrega?.observacao ?? '';
    this.publicationDate = entrega?.data_publicacao
      ? new Date(entrega?.data_publicacao as string)
      : undefined;
    this.comment = '';
    this.conceptGrade = GradeUtils.numberToConcept(this.grade as number);
    this.conceptRecoveryGrade = GradeUtils.numberToConcept(this.recoveryGrade as number);
    this.conceptAdictionalGrade = GradeUtils.numberToConcept(this.aditionalGrade as number);
    this.minimumGrade = entrega?.nota_minima;
    this.numberOfFeedbacks = entrega?.devolutivas;

    const expirado = DateUtils
      .checkExpiredDate(this.recoveryDueDate);
    const status = expirado ? EXPIRADO : NAO_INICIADO;

    this.status = EnumUtils
      .fromFirstEnumToSecondEnum<typeof EntregaStatus, typeof DeliveryStatus>(
        entrega?.status ?? status,
        EntregaStatus,
        DeliveryStatus,
      ) as DeliveryStatus;
  }
}
