import { Answer } from './answer';
import {
  Aluno,
  CaminhoEntrega,
  Comentario,
  EntregaPublicadaAnexo,
  Perfil,
  QuestaoResposta,
} from './api';
import { EntregaRascunho, RespostaRascunho } from './api/entrega-rascunho';
import { ChipType } from './chip-type';
import { CommentModel } from './comment';
import { Reaction } from './reaction';

export class DraftDelivery {
  id: number;
  user: Aluno;
  cover: string;
  title: string;
  questions: QuestaoResposta[];
  video: string;
  keywords: ChipType[];
  uploads: EntregaPublicadaAnexo[];
  grade: string;
  deliveryPath: CaminhoEntrega;
  comment: string;
  workId: number;
  publishmentDate?: string;
  status: string;
  profile?: Perfil;
  comments: CommentModel[];
  answers: Answer[];
  reactions: Reaction;
  professorComments: CommentModel[];

  constructor(entregaRascunho?: EntregaRascunho) {
    this.id = entregaRascunho?.id ?? 0;
    this.user = entregaRascunho?.usuario ?? new Aluno();
    this.cover = entregaRascunho?.capa ?? '';
    this.title = entregaRascunho?.titulo ?? '';
    this.questions = entregaRascunho?.questoes ?? [];
    this.video = entregaRascunho?.video ?? '';
    this.uploads = entregaRascunho?.uploads ?? [];
    this.grade = entregaRascunho?.nota ?? '';
    this.deliveryPath = entregaRascunho?.caminhoEntrega ?? new CaminhoEntrega();
    this.comment = entregaRascunho?.comentario ?? '';
    this.workId = entregaRascunho?.trabalho_id ?? 0;
    this.publishmentDate = entregaRascunho?.data_publicacao ?? '';
    this.status = entregaRascunho?.status ?? '';
    this.comments = entregaRascunho?.comentarios?.map((comentario: Comentario) => (
      new CommentModel(comentario))) ?? [];
    this.uploads = entregaRascunho?.uploads ?? [];

    this.answers = entregaRascunho?.respostas?.map((resposta: RespostaRascunho) => ({
      id: resposta.networking_trabalho_questao_id,
      answer: resposta.conteudo,
    })) ?? [];

    this.reactions = new Reaction(entregaRascunho?.reacoes) ?? new Reaction();

    this.keywords = entregaRascunho?.palavras_chave?.map(
      (word) => new ChipType(word.palavra_chave, word.id)
    ) ?? [];

    this.professorComments = entregaRascunho?.comentario_do_professor?.map(
      (comentario: Comentario) => (
        new CommentModel(comentario))) ?? [];

    if (entregaRascunho && entregaRascunho.perfil) this.profile = entregaRascunho.perfil;
  }
}
