import { Aluno } from './api';

export class Student {
  id: number;
  name: string;
  nickname?: string;
  company: string;
  linkedIn: string;
  biography: string;
  profilePhoto: string;

  constructor(aluno?: Aluno) {
    this.id = aluno?.id || 0;
    this.name = aluno?.nome || '';
    this.nickname = aluno?.apelido || '';
    this.company = aluno?.empresa || '';
    this.linkedIn = aluno?.linkedin || '';
    this.biography = aluno?.biografia || '';
    this.profilePhoto = aluno?.fotoPerfil || '';
  }
}
