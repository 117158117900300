import { Area } from '../../models/area';
import { context } from './shared';

const { STUDENT_DELIVERY_AREA, STUDENT_OBJECT_AREA, STUDENT_NOTIFICATION_AREA } = Area;

const prefix = (area: Area) => context[area];

export const StudentEndpoints = {
  saveAssignment: () => `${prefix(STUDENT_DELIVERY_AREA)}`,
  getAssignmentDraft: (id: number) => `${prefix(STUDENT_DELIVERY_AREA)}/rascunho/${id}`,
  getAssignment: (id: number) => `${prefix(STUDENT_OBJECT_AREA)}/${id}`,
  getDisciplinesByCourse: (cursoId: number) => `aluno/disciplinas/${cursoId}`,
  getAssignmentObjectByDiscipline: (disciplinaId: number) => (
    `${prefix(STUDENT_DELIVERY_AREA)}/porDisciplina/${disciplinaId}`
  ),
  profile: () => 'aluno/perfil',
  getPrivacyTerms: () => 'aluno/lgpd/termo',
  addDelivery: () => `${prefix(STUDENT_DELIVERY_AREA)}`,
  getMyAssignments: () => 'aluno/trabalho',
  getCovers: () => `${prefix(STUDENT_DELIVERY_AREA)}/capas/busca`,
  countAccess: (id: number, userId: number) => (
    `${prefix(STUDENT_DELIVERY_AREA)}/acesso/${userId}/${id}`
  ),
  notifications: () => `${prefix(STUDENT_NOTIFICATION_AREA)}`,
  markAsRead: (id: number) => `${prefix(STUDENT_NOTIFICATION_AREA)}/visualiza/${id}`,
};
