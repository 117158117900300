import { QueryParamsModel } from '@/models/api';

export const QueryParamsUtils = {
  queryParamsBuilder: (params: QueryParamsModel): string => {
    const keys = Object.keys(params);

    return keys.reduce((builded: string, key: string) => {
      let buildedParams = builded;
      const value = params[key as keyof QueryParamsModel];
      if (value) {
        if (!buildedParams.length) buildedParams = '?';
        else buildedParams += '&';
        buildedParams += `${key}=${value}`;
      }
      return buildedParams;
    }, '');
  },
};
