import { StorageKeys, TokenStructure } from '@/models';
import { LocalStorageService } from './LocalStorageService';

interface AuthorizationHeader {
  Authorization: string;
}

export const TokenService = {
  getBearer: (token: string) => ({ Authorization: `Bearer ${token}` }),
  getTokens: (): TokenStructure => LocalStorageService.getFromLocalStorage(StorageKeys.ROOT),
  getAuthorizationHeader: (): AuthorizationHeader => {
    const { requestToken } = TokenService.getTokens().login;
    return TokenService.getBearer(requestToken);
  },
  getRefreshToken: () => {
    const { refreshToken } = TokenService.getTokens().login;
    return TokenService.getBearer(refreshToken);
  },
  updateToken: () => {
    console.log('update');
  },
};
