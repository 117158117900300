import { UploadDeArquivo } from './api/upload-de-arquivo';

export class FileUpload {
  id?: number;
  name?: string;
  src?: string;

  constructor(file: UploadDeArquivo) {
    this.id = file.id;
    this.name = file.nomeArquivo || file.nomeArquivo || file.nome_arquivo;
    this.src = file.src;
  }
}
