import { EntregaPublicada } from './entrega-publicada';
import { Questao } from './questao';

export class TrabalhoComEntregaPublicada {
  id: number;
  enunciado: string;
  questoes: Questao[];
  entrega: EntregaPublicada;

  constructor() {
    this.id = 0;
    this.enunciado = '';
    this.questoes = [new Questao()];
  }
}
