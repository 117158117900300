import { Questao, QuestaoResposta } from './api';

export class QuestionModel {
  id: number;
  question: string;
  answer?: string;
  required: boolean;
  description?: string;

  constructor(questao?: Questao | QuestaoResposta) {
    this.id = questao?.id ?? 0;
    this.required = (questao as Questao)?.obrigatorio as boolean ?? true;
    this.question = (questao as Questao)?.questao ?? '';
    this.answer = questao?.resposta;
    this.description = this.question;
  }
}
