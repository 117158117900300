export const GradeUtils = {
  toGradeString: (grade: number): string => {
    if (!grade) return '—';

    const splittedGrade = grade.toString().split(/\./g);
    const decimal = (`${splittedGrade[1] ?? '00'}00`).slice(0, 2);
    const stringGrade = grade > 0 ? (`000${splittedGrade[0]},${decimal}`).slice(-5) : '—';

    return stringGrade;
  },

  toDecimalNumber(grade: string): number | undefined {
    const replaceComma = grade.replace(/,/, '.');
    let toNumber: number | undefined = Number(replaceComma);

    if (toNumber > 10) toNumber /= 100;

    if (Number.isNaN(toNumber)) toNumber = undefined;

    return toNumber;
  },

  numberToConcept(num: number): string {
    let concept = '';
    if (num >= 0 && num < 7) {
      concept = 'D';
    } else if (num >= 7 && num < 8) {
      concept = 'C';
    } else if (num >= 8 && num < 9) {
      concept = 'B';
    } else if (num >= 9 && num <= 10) {
      concept = 'A';
    }
    return concept;
  },
};
