import { Reacao } from './reacao';
import { Reaction } from '../reaction';

export class ReacaoPost implements Reacao {
  curtida: boolean;

  constructor({ liked }: Reaction) {
    this.curtida = liked;
  }
}
