import { EnumUtils } from '@/utils';
import { Answer } from './answer';
import { ObjetoDeAssignment } from './api/objeto-de-assignment';
import { ObjetoDeAssignmentStatus } from './api/objeto-de-assignment-status';
import { PalavraChave } from './api/palavra-chave';
import { Questao } from './api/questao';
import { Turma } from './api/turma';
import { UploadDeArquivo } from './api/upload-de-arquivo';
import { AssignmentObjectStatus } from './assignment-object-status';
import { Class } from './class';
import { Course } from './course';
import { Discipline } from './discipline';
import { DraftDelivery } from './draft-delivery';
import { FileUpload } from './file-upload';
import { KeywordModel } from './keyword';
import { QuestionModel } from './question';

const { RASCUNHO } = ObjetoDeAssignmentStatus;

export class AssignmentObjectModel {
  attachment?: boolean;
  files?: FileUpload[];
  newFiles?: File[];
  keywords?: KeywordModel[];
  classes: Class[];
  class?: Class;
  disciplineId?: number;
  discipline: Discipline;
  statement?: string;
  id: number;
  courseId?: number;
  course: Course;
  publish?: boolean;
  questions?: QuestionModel[];
  uploadVideo?: boolean;
  video?: string;
  status: AssignmentObjectStatus;
  professor?: string;
  deletable?: boolean;
  draft?: DraftDelivery;
  removedQuestions?: number[];
  compositionGrade?: boolean;
  createdAt?: Date;

  constructor(objetoDeAssignment?: ObjetoDeAssignment) {
    this.id = objetoDeAssignment?.id || 0;
    this.attachment = Boolean(objetoDeAssignment?.anexos) || false;
    this.classes = objetoDeAssignment?.turmas?.map((curso: Turma) => (
      new Class(curso, this.id)
    )) || [];
    this.courseId = objetoDeAssignment?.curso_id || 0;
    this.course = new Course(objetoDeAssignment?.curso);
    this.deletable = Boolean(objetoDeAssignment?.deletable);
    this.disciplineId = objetoDeAssignment?.disciplina_id || 0;
    this.discipline = new Discipline(objetoDeAssignment?.disciplina);
    this.keywords = objetoDeAssignment?.palavras_chave?.map((palavraChave: PalavraChave) => (
      new KeywordModel(palavraChave)
    ))
      || objetoDeAssignment?.palavras_chave?.map((palavraChave: PalavraChave) => (
        new KeywordModel(palavraChave)
      ))
      || [];
    this.publish = Boolean(objetoDeAssignment?.publicar);
    this.questions = objetoDeAssignment?.questoes?.length
      ? objetoDeAssignment?.questoes?.map((questao: Questao) => new QuestionModel(questao))
      : [new QuestionModel()];

    this.statement = objetoDeAssignment?.enunciado || '';
    this.status = EnumUtils
      .fromFirstEnumToSecondEnum<typeof ObjetoDeAssignmentStatus, typeof AssignmentObjectStatus>(
        objetoDeAssignment?.status || RASCUNHO,
        ObjetoDeAssignmentStatus,
        AssignmentObjectStatus,
      ) as AssignmentObjectStatus;
    this.uploadVideo = Boolean(objetoDeAssignment?.upload_video) || false;
    this.video = objetoDeAssignment?.video || '';

    const arquivos = objetoDeAssignment?.arquivos || [];
    this.files = arquivos?.map((arquivo: UploadDeArquivo) => new FileUpload(arquivo)) || [];
    this.newFiles = [];
    this.professor = objetoDeAssignment?.professor;

    if (objetoDeAssignment && objetoDeAssignment.entrega) {
      this.draft = new DraftDelivery(objetoDeAssignment.entrega);
      const questionResponses = this.draft.answers;

      this.questions = this.questions?.map((questao: QuestionModel) => {
        const responseData = questionResponses.find(
          (response: Answer) => questao.id === response.id,
        );

        return {
          ...questao,
          ...(responseData && { answer: responseData.answer }),
        };
      }) || [];
    }

    if (objetoDeAssignment?.created_at) this.createdAt = new Date(objetoDeAssignment.created_at);
  }
}
