import { StorageKeys } from '@/models';

export const LocalStorageService = {
  getFromLocalStorage: (key: StorageKeys) => {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
  },

  setInLocalStorage: (key: StorageKeys, value: {} | string | boolean | number) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
};
