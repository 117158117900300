import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';
import { RouteName } from '@/models';
import { PermissionGuard } from './PermissionGuard';

export const AuthGuard = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const isAuthenticated = store.getters['login/isLogged'];

  const validation = Boolean(
    to.name !== RouteName.LOGIN
    && to.name !== RouteName.LOADING
    && to.name !== RouteName.LANDING_PAGE,
  );

  if (validation && !isAuthenticated) next({ name: RouteName.LOGIN });
  else PermissionGuard(to, from, next);
};
