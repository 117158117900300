
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TextModal',
})
export default class Text extends Vue {
  @Prop({}) public title: string;
  @Prop({}) public text: string;
  @Prop({}) public dark: boolean;
}
