
import { Component, Vue, Prop } from 'vue-property-decorator';

import Close from '@/assets/icons/Close.svg';

import ButtonCircle from '@/components/shared/ButtonCircle.vue';

@Component({
  name: 'Sidebar',
  components: { ButtonCircle, Close },
})
export default class Sidebar extends Vue {
  @Prop({}) public isClosable!: boolean;
  public opened = false;

  mounted() {
    this.subscribeToEvents();
    setTimeout(() => this.opened = true);
  }

  public handleClose() {
    this.opened = false;
    setTimeout(() => this.$emit('close'), 1000);
  }

  handleEscKeyup(event: KeyboardEvent) {
    const isEscapeKey = event.keyCode === 27;

    if (isEscapeKey && this.isClosable) {
      this.handleClose();
    }
  }

  subscribeToEvents() {
    document.addEventListener('keyup', this.handleEscKeyup);
  }

  unsubscribeToEvents() {
    document.removeEventListener('keyup', this.handleEscKeyup);
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }
}
