var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"search"},[_c('div',{staticClass:"search-container",class:{
      'search-container--dark': _vm.darkMode,
      'search-container--no-radius': !_vm.hasRadius,
      'search-container--can-focus': _vm.hasFocus,
      'search-container--focus': _vm.hasFocus && _vm.inFocus,
      'search-container--disabled': _vm.disabled,
    }},[(!_vm.noSearchIcon)?_c('Search',{staticClass:"search__icon"}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputData),expression:"inputData"}],staticClass:"search__input",class:{
        'search__input--dark': _vm.darkMode,
      },attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(_vm.inputData)},on:{"focus":function($event){_vm.inFocus = true},"blur":function($event){_vm.inFocus = false},"input":[function($event){if($event.target.composing)return;_vm.inputData=$event.target.value},_vm.handleInputBehavior],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.keyUpEnter.apply(null, arguments)}}}),(_vm.showClearButton && !_vm.noClearIcon && !_vm.disabled)?_c('div',{staticClass:"search__clear-icon",on:{"click":_vm.clearInput}},[_c('Close')],1):_vm._e()],1),(!_vm.noSuggestor)?_c('Suggestor',{attrs:{"data":_vm.datalist},on:{"change":function($event){return _vm.populateInput($event, true)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }