
import { Component, Prop, Vue } from 'vue-property-decorator';

import Loader from '@/components/shared/Loader.vue';

@Component({
  name: 'LoadingOverlay',
  components: {
    Loader,
  },
})
export default class LoadingOverlay extends Vue {
  @Prop({}) public phrase: string;
}
