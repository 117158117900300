export enum RouteName {
  ASSIGNMENT = 'Assignment',
  ASSIGNMENT_DISCIPLINE_LIST = 'AssignmentListByDiscipline',
  ASSIGNMENT_EDIT = 'AssignmentEdit',
  ASSIGNMENT_FILL = 'AssignmentFill',
  ASSIGNMENT_OBJECT_ASSIGNMENT = 'AssignmentObjectAssignments',
  ASSIGNMENT_OBJECT_CREATE = 'AssignmentObjectCreate',
  ASSIGNMENT_OBJECT_EDIT = 'AssignmentObjectEdit',
  ASSIGNMENT_OBJECT_MANAGE = 'AssignmentObjectManage',
  FORBIDDEN = 'Forbidden',
  IMPLEMENTACOES = 'Implementacoes',
  INICIO = 'Início',
  LANDING_PAGE = 'LandingPage',
  LOADING = 'Loading',
  LOGIN = 'Login',
  NOT_FOUND = 'NotFound',
  PENDING_ASSIGNMENTS = 'PendingAssignments',
  PROFILE = 'Profile',
  PUBLISHED_ASSIGNMENT = 'PublishedAssignment',
  RESCTRICTED_ACCESS = 'RestrictedAccess',
  COURSES = 'Courses',
  LOGOUT = 'Logout',
}
