
import { Component, Vue } from 'vue-property-decorator';

import UnsplashLogo from '@/assets/icons/UnsplashLogo.svg';

import SearchInput from '@/components/shared/SearchInput.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';

import { SidebarTypes, CoverList } from '@/models';

import { CoverListService, SidebarService } from '@/services';

@Component({
  name: 'Unsplash',
  components: {
    ButtonDefault,
    UnsplashLogo,
    SearchInput,
  },
})
export default class Unsplash extends Vue {
  public coverList: CoverList[] = new Array(20);
  public term = '';
  public loading = false;
  public page = 1;

  mounted() {
    this.getImageList();
  }

  public selectCover(imageDetails: CoverList) {
    SidebarService.respostaSidebar(SidebarTypes.UNSPLASH, imageDetails, false);
  }

  public viewMore() {
    this.page++;
    this.getImageList(this.term, true);
    this.loading = true;
  }

  public async getImageList(term?: string, merge = false) {
    try {
      this.term = term ?? '';
      const { data } = await CoverListService.getCoverList(term, this.page);
      this.coverList = merge ? this.coverList.concat(data) : data;
    } catch (error) {
      // TRATAR
    } finally {
      this.loading = false;
    }
  }

  public respostaSidebar(resposta: boolean) {
    SidebarService.respostaSidebar(SidebarTypes.UNSPLASH, resposta, !resposta);
  }
}
