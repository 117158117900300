import { Discipline } from '../discipline';

export interface DisciplineState {
  disciplineList: Discipline[];
  searchingDisciplines: boolean;
}

export enum DisciplineCommits {
  CLEAR_DISCIPLINES_LIST = 'CLEAR_DISCIPLINES_LIST',
  SEARCHING_DISCIPLINES = 'SEARCHING_DISCIPLINES',
  SET_DISCIPLINES_LIST = 'SET_DISCIPLINES_LIST',
}
