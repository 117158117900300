import { Module } from 'vuex';
import { AxiosError } from 'axios';
import { AssignmentService } from '@/services';
import {
  MyAssignmentsPageCommits,
  MyAssignmentsPageState,
} from '@/models/store/my-assignments-page';
import { MyAssignments, MyWork, DeliveryStatus } from '@/models';
import { RootState } from '@/models/store';

const { SET_MY_ASSIGNMENTS, SET_LOADING } = MyAssignmentsPageCommits;
const { PUBLISHED, AWAITING_EVALUATION } = DeliveryStatus;

const myAssignmentsPage: Module<MyAssignmentsPageState, RootState> = {
  namespaced: true,
  state: {
    my: [],
    loading: false,
  },
  getters: {
    myAssignments: (state: MyAssignmentsPageState) => state.my,
    myDeliveries: (state: MyAssignmentsPageState) => state.my
      .map(({ assignments }: MyAssignments) => (
        assignments.reduce((acc: number[], { delivery }: MyWork) => {
          const accumulator = acc;
          if (delivery?.id && (
            delivery.status === PUBLISHED || delivery.status === AWAITING_EVALUATION
          )) accumulator.push(delivery.id);
          return accumulator;
        }, [])
      )).flat(),
    isRequestingPending: (state: MyAssignmentsPageState) => state.loading,
  },
  mutations: {
    SET_MY_ASSIGNMENTS(state: MyAssignmentsPageState, payload: MyAssignments[]) {
      state.my = payload;
    },
    SET_LOADING(state: MyAssignmentsPageState, payload: boolean) {
      state.loading = payload;
    },
  },
  actions: {
    getMyAssignments({ commit }) {
      commit(SET_LOADING, true);

      return new Promise((resolve, reject) => {
        AssignmentService.getMyAssignments()
          .then((response: MyAssignments[]) => {
            commit(SET_MY_ASSIGNMENTS, response);
            setTimeout(() => resolve(response));
          })
          .catch((error: AxiosError) => reject(error))
          .finally(() => commit(SET_LOADING, false));
      });
    },
  },
};

export default myAssignmentsPage;
