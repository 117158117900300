export const AccordionUtils = {
  toggleExpandableElement(el: HTMLElement, flag: boolean, delay: number) {
    const expandElement = el;
    const tableHeight = expandElement.scrollHeight;
    const timeout = flag ? delay : 0;

    expandElement.style.maxHeight = flag ? `${tableHeight}px` : '0px';

    setTimeout(() => {
      expandElement.style.overflow = flag ? 'visible' : 'hidden';
    }, timeout);
  },
};
