import { AxiosError, AxiosResponse } from 'axios';
import { KeywordPaginated } from '@/models';
import { PalavraChavePaginada } from '@/models/api';
import { ApiUrls, buildApiUrl } from '@/utils/endpoints';
import { ApiService } from './ApiService';

const httpClient = ApiService;

export const KeywordService = {
  getKeywords(text: string): Promise<KeywordPaginated> {
    return new Promise((resolve, reject) => {
      const endpoint = buildApiUrl(ApiUrls.getKeywords(text));

      httpClient
        .get(endpoint)
        .then((response: AxiosResponse<PalavraChavePaginada>) => {
          if (!response.data) reject(new Error('Ooops'));

          const keywords = new KeywordPaginated(response.data);
          resolve(keywords);
        })
        .catch((err: AxiosError) => reject(err.message));
    });
  },
};
