import { Module } from 'vuex';
import { AxiosError } from 'axios';

import {
  CommentModel,
  Delivery,
  DeliveryGradesFormHeader,
  DeliveryStatus,
  GradeFormModel,
} from '@/models';
import {
  DeliveriesState,
  DeliveriesCommits,
  RootState,
  DeliveryState,
} from '@/models/store';
import { Pagination } from '@/models/api';

import { DeliveryService } from '@/services';

import { DateUtils } from '@/utils';

const {
  EXPIRED,
  NOT_STARTED,
  IN_RECOVERY,
  PUBLISHED,
  AWAITING_EVALUATION,
} = DeliveryStatus;
const { RECOVERY, REGULAR, ADITIONAL } = DeliveryGradesFormHeader;

const {
  CLEAR_DATA,
  DELETE_DELIVERY,
  REQUESTING_DELIVERIES,
  RESET_DELIVERY,
  SET_DELIVERIES_LIST,
  SET_DELIVERY_GRADE,
  SET_INITIAL_LIST,
  SET_PAGINATION_DATA,
  UPDATE_DELIVERIES_LIST,
  UPDATE_INITIAL_DATA,
} = DeliveriesCommits;

const initialState = {
  deliveriesList: [],
  initialDeliveriesList: [],
  requesting: false,
  pagination: {
    current_page: 1,
  },
};

const deliveries: Module<DeliveriesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    getDeliveriesList: ({ deliveriesList }: DeliveriesState): DeliveryState[] => deliveriesList,
    getPagination: ({ pagination }: DeliveriesState): Pagination<DeliveryState> => (
      pagination as Pagination<DeliveryState>
    ),
    requesting: ({ requesting }: DeliveriesState): boolean => requesting,
  },
  mutations: {
    CLEAR_DATA(state: DeliveriesState): void {
      state.deliveriesList = initialState.deliveriesList;
      state.requesting = false;
    },
    DELETE_DELIVERY(
      state: DeliveriesState,
      { id, deletedDelivery }: {
        id: number;
        deletedDelivery: DeliveryState;
      },
    ): void {
      const deletedIndex = state.deliveriesList
        .findIndex((oldDelivery) => oldDelivery.id === id);

      state.deliveriesList.splice(deletedIndex, 1, deletedDelivery);
      state.initialDeliveriesList.splice(deletedIndex, 1, deletedDelivery);
    },
    REQUESTING_DELIVERIES(state: DeliveriesState, requesting: boolean): void {
      state.requesting = requesting;
    },
    RESET_DELIVERY(state: DeliveriesState, id: number): void {
      const deliveryIndex = state.deliveriesList.findIndex((d: DeliveryState) => d.id === id);
      const initialDelivery = state.initialDeliveriesList[deliveryIndex];
      state.deliveriesList.splice(deliveryIndex, 1, { ...initialDelivery, grades: [] });
    },
    SET_DELIVERIES_LIST(state: DeliveriesState, list: DeliveryState[]): void {
      state.deliveriesList = list.map((d: DeliveryState) => {
        const delivery = d;

        delivery.enableAditionalDate = (delivery.status === EXPIRED
          && DateUtils.checkExpiredDate(delivery.recoveryDueDate))
          || Boolean(delivery.aditionalDueDate);

        return delivery;
      });
    },
    SET_DELIVERY_GRADE(state: DeliveriesState): void {
      state.deliveriesList.forEach((delivery: DeliveryState, idx: number) => {
        const { grade, aditionalDueDate } = state.initialDeliveriesList[idx];

        const deliveryWithGrades = delivery;
        const minGrade = delivery.minimumGrade as number;
        const onRecovery = ((!delivery.deliveryDate
          && (delivery.dueDate as Date).getTime() < Date.now())
          || (((delivery.grade as number < minGrade
            && delivery.grade as number > 0)
            || !delivery.grade)
            && Boolean(delivery.recoveryDeliveryDate)));

        const enableAditional = (DateUtils
          .checkExpiredDate((delivery.recoveryDueDate as Date).toISOString())
          && delivery.status !== PUBLISHED
          && delivery.status !== AWAITING_EVALUATION)
          || Boolean(delivery.aditionalDueDate);

        const recovery = delivery.status === IN_RECOVERY;
        const expired = delivery.status === EXPIRED
          || (enableAditional && (!delivery.status || delivery.status === NOT_STARTED));

        deliveryWithGrades.grades = [
          new GradeFormModel(REGULAR, delivery, grade),
        ];

        if (onRecovery || expired || recovery || enableAditional) {
          deliveryWithGrades.grades.push(new GradeFormModel(RECOVERY, delivery));

          if (enableAditional) {
            deliveryWithGrades.grades
              .push(new GradeFormModel(ADITIONAL, delivery, grade, aditionalDueDate));
          }
        }

        return deliveryWithGrades;
      });
    },
    UPDATE_INITIAL_DATA(state: DeliveriesState, delivery: Delivery): void {
      const index = state.initialDeliveriesList
        .findIndex((initialData: Delivery) => (delivery.id
          ? initialData.id === delivery.id
          : initialData.profile?.id === delivery.profile?.id
        ));

      state.initialDeliveriesList.splice(index, 1, delivery);
    },
    SET_INITIAL_LIST(state: DeliveriesState, list: DeliveryState[]): void {
      state.initialDeliveriesList = list;
    },
    UPDATE_DELIVERIES_LIST(state: DeliveriesState, deliveryState: DeliveryState): void {
      const deliveryIndex = state.deliveriesList
        .findIndex((delivery: DeliveryState) => (delivery.id
          ? delivery.id === deliveryState.id
          : delivery.profile?.id === deliveryState.profile?.id
        ));

      const initialDelivery = state.initialDeliveriesList[deliveryIndex];
      const {
        grade,
        recoveryGrade,
        comment,
        observation,
        aditionalDueDate,
        aditionalGrade,
      } = initialDelivery;

      const hasChanges = Boolean(deliveryState.comment !== comment
        || deliveryState.observation !== observation
        || deliveryState.grade !== grade
        || (deliveryState.recoveryGrade
          && deliveryState.recoveryGrade !== recoveryGrade)
        || deliveryState.aditionalDueDate !== aditionalDueDate
        || (deliveryState.aditionalGrade
          && deliveryState.aditionalGrade !== aditionalGrade));

      const delivery = { ...deliveryState, hasChanges };

      state.deliveriesList.splice(deliveryIndex, 1, delivery);
    },
    SET_PAGINATION_DATA(state: DeliveriesState, paginatedDelivery: Pagination<Delivery>): void {
      state.pagination = { ...paginatedDelivery };
    },
  },
  actions: {
    clearDeliveriesList: ({ commit }): void => commit(CLEAR_DATA),
    deleteDelivery: ({ commit }, id: number): Promise<void> => (new Promise((
      resolve,
      reject,
    ) => {
      DeliveryService.deleteDelivery(id)
        .then((deletedDelivery: DeliveryState) => {
          commit(DELETE_DELIVERY, { id, deletedDelivery });
          commit(SET_DELIVERY_GRADE);
          resolve();
        }).catch((error: AxiosError) => reject(error));
    })),
    resetDelivery: async ({ commit }, id: number): Promise<void> => {
      await commit(RESET_DELIVERY, id);
      await commit(SET_DELIVERY_GRADE);
    },
    editDeliveryAvaliation: (
      { commit },
      deliveryState: DeliveryState,
    ): Promise<Delivery> => {
      commit(REQUESTING_DELIVERIES, true);
      return new Promise((resolve, reject) => {
        DeliveryService.editDeliveryGrade(deliveryState)
          .then(async (comment: CommentModel) => {
            const { commentIdToEdit, ...rest } = deliveryState;
            const delivery = {
              ...rest,
              comment: '',
              comments: deliveryState
                .comments?.map((c) => (c.id === commentIdToEdit ? comment : c)),
            };

            await commit(UPDATE_INITIAL_DATA, delivery);
            await commit(UPDATE_DELIVERIES_LIST, delivery);
            await commit(SET_DELIVERY_GRADE);
            resolve(delivery);
          })
          .catch((error: AxiosError) => reject(error))
          .finally(() => commit(REQUESTING_DELIVERIES, false));
      });
    },
    saveDeliveryAvaliation: ({ commit }, deliveryState: DeliveryState): Promise<Delivery> => {
      commit(REQUESTING_DELIVERIES, true);
      return new Promise((resolve, reject) => {
        DeliveryService.setDeliveryGrade(deliveryState)
          .then(async (delivery: Delivery) => {
            await commit(UPDATE_INITIAL_DATA, delivery);
            await commit(UPDATE_DELIVERIES_LIST, delivery);
            await commit(SET_DELIVERY_GRADE);
            resolve(delivery);
          })
          .catch(async (error: AxiosError) => {
            await commit(RESET_DELIVERY, deliveryState.id);
            await commit(SET_DELIVERY_GRADE);
            console.error(error);
            reject(error);
          })
          .finally(() => commit(REQUESTING_DELIVERIES, false));
      });
    },
    searchDeliveryList: (
      { commit },
      {
        objectId,
        classId,
        searchKey = '',
        page = 1,
        filter = '',
      }: {
        objectId: number;
        classId: number;
        searchKey: string;
        page: number;
        filter: string;
      },
    ): Promise<Pagination<Delivery>> => {
      commit(CLEAR_DATA);
      return new Promise((resolve, reject) => {
        commit(REQUESTING_DELIVERIES, true);
        DeliveryService.getDeliveriesByObjectCourse(objectId, classId, searchKey, page, filter)
          .then(async (deliveriesList: Pagination<Delivery>) => {
            await commit(SET_DELIVERIES_LIST, [...deliveriesList.data as Delivery[]]);
            await commit(SET_INITIAL_LIST, [...deliveriesList.data as Delivery[]]);
            await commit(SET_DELIVERY_GRADE);
            await commit(SET_PAGINATION_DATA, deliveriesList);
            setTimeout(() => resolve(deliveriesList));
          })
          .catch((err: AxiosError) => reject(err))
          .finally(() => commit(REQUESTING_DELIVERIES, false));
      });
    },
    updateDeliveriesList: async ({ commit }, delivery: DeliveryState): Promise<void> => {
      await commit(UPDATE_DELIVERIES_LIST, delivery);
      await commit(SET_DELIVERY_GRADE);
    },
  },
};

export default deliveries;
